import axios from "axios";
import { Authorisation, url } from "./Url";

const serverUrl = `${url}auth/`;

export const getOtpFromNumber = async (obj) => {
  // try {
  let res = await axios.post(`${serverUrl}login_otp`, obj);
  return res;

  // } catch (error) {
  //     console.error(error)
  //     throw (error)
  // }
};
export const registerUser = async (obj) => {
  let res = await axios.post(`${serverUrl}register`, obj);
  return res;
};

export const getUserDetail = async (id) => {
  let res = await axios.get(`${url}csmUser/myUser/${id}/detail`);
  return res;
};

export const updateUserProfile = async (obj) => {
  let token = localStorage.getItem("ADMIN_AUTH_TOKEN");
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios.post(`${serverUrl}profile-update`, obj, config);
};

export const userLoginV2 = async (obj) => {
  try {
    let res = await axios.post(`${url}auth/login_v2`, obj);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUserProfile = async () => {
  try {
    let token = localStorage.getItem("ADMIN_AUTH_TOKEN");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let res = await axios.get(`${url}auth/user-profile`, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCurrentProgress = async () => {
  try {
    let token = localStorage.getItem("ADMIN_AUTH_TOKEN");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let res = await axios.get(`${url}user-progress`, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getUserchart = async (Age) => {
  try {
    let token = localStorage.getItem("ADMIN_AUTH_TOKEN");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let res = await axios.get(`${url}chart?age=${Age}`, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUserchartByMonth = async (Month) => {
  try {
    let token = localStorage.getItem("ADMIN_AUTH_TOKEN");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let res = await axios.get(`${url}age-chart?month=${Month}`, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUserDetailfromAdmin = async (id) => {
  let res = await axios.get(`${url}myUser/${id}/detail`);
  return res;
};
export const getCsmUserfromAdmin = async (id) => {
  let res = await axios.get(`${url}csm-user/${id}`);
  return res;
};

export const getAge = async () => {
  return await axios.get(`${url}age`);
};

export const orderSuccessFull = async (orderId, obj) => {
  let res = await axios.post(`${url}order/${orderId}/update`, obj);
  return res;
};

export const handlePayment = async (obj) => {
  let FavcyToken = localStorage.getItem("FAVCY_AUTH_TOKEN");
  let config = {
    headers: {
      Accept: "*/*",
      "Auth-token": `Bearer ${FavcyToken}`,
    },
  };

  let res = await axios.post(`http://staging.favcy.com/payments/pay`, obj);
  return res;
};

export const handleOrderPlan = async (obj, token) => {
  // let token = localStorage.getItem("ADMIN_AUTH_TOKEN");

  let config = {
    headers: {
      Accept: "*/*",
      Authorization: `Bearer ${token}`,
    },
  };

  let res = await axios.post(`${url}create_order`, obj, config);
  return res;
};
export const userLogin = async (obj) => {
  try {
    let res = await axios.post(`${serverUrl}login`, obj);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const submitTestResult = async (obj) => {
  try {
    let token = localStorage.getItem("ADMIN_AUTH_TOKEN");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let res = await axios.post(
      `${url}site/submit-free-test-result`,
      obj,
      config
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const submitSelectedAge = async (obj) => {
  try {
    let token = localStorage.getItem("ADMIN_AUTH_TOKEN");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let res = await axios.post(`${url}user-age`, obj, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
