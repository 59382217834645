import axios from "axios";
import { Authorisation, url } from "./Url";

const serverUrl = `${url}site/`

export const getPackages = async () => {
    try {
        let res = await axios.get(`${serverUrl}packages`)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getFreePackages = async () => {
    try {
        let res = await axios.get(`${serverUrl}free_packages`)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

