import React, { useEffect, useState } from 'react'
import styles from './ChildDetails.module.css';
import back from "../../../assets/img/back.png";
import { useHistory, useParams } from 'react-router-dom';
import Adminhomepagefooter from '../AdminHomepageFooter/Adminhomepagefooter';

///////// pink section
import bear from "../../../assets/img/bear.png";
import abc from "../../../assets/img/abcd.png";
import helicopter from "../../../assets/img/a2c55372587e78209084471290349e64 1.png";

///////// blue section
import kite from "../../../assets/img/activityCenterTopLeft.png";
import blueSectionBottomBar from "../../../assets/img/blueSectionBottomBar.png";
import KidOnly from "../../../assets/img/KidOnly.png";
import { getCurrentProgressForAdminByUserId } from '../../../Services/Activity';

export default function ChildDetails() {
    let id = useParams()
    console.log(id)
    const history = useHistory();
    const [skillArr, setSkillArr] = useState([]);
    const handleBackNavigation = () => {
        // let path = `/CSM-Customers`;
        history.goBack();
    }


    const getProgress = async () => {
        try {
            const { data: res } = await getCurrentProgressForAdminByUserId(id.id)
            console.log(res)
            if (res) {
                let temp = res.data.filter(el => el.milestones.length > 0)
                setSkillArr([...temp])
                console.log(temp)
            }
        } catch (error) {
            console.error(error)
        }
    }


    useEffect(() => {
        getProgress()
    }, [])


    return (
        <div>
            <div className={`${styles.header}`}>
                <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div>
                <div className={`${styles.headerHeading}`}>
                    Current Progress
                </div>
            </div>
            {
                skillArr.map((el, i) => {
                    return (
                        <>
                            {i % 2 == 0 ?



                                <div key={el.id} className={`${styles.containerdiv} container-fluid ${styles.blueContainer}`}>
                                    <img src={kite} className={`${styles.blueContainerImage1}`} alt="" />
                                    <img src={blueSectionBottomBar} className={`${styles.blueContainerImage2}`} alt="" />
                                    <img src={KidOnly} className={`${styles.blueContainerImage3}`} alt="" />



                                    <div className={`row pt-5`} >
                                        <div className="mx-auto col-lg-8 col-11">
                                            <div className={`${styles.blueSectionContaienerCard}`}>
                                                <div className={`${styles.cardHeading}`}>
                                                    {el.name}
                                                </div>
                                                {/* ////////progress-Bar section//////// */}
                                                <div className={`${styles.blueSectionProgressBarBorder}`}><div className={`${styles.blueSectionProgressBarContainer}`}><div className={`${styles.blueSectionProgressBarInnerContainer}`} style={{ width: el.bar }} ></div></div></div>
                                                {/* ////////progress-Bar section//////// */}

                                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", width: "100%" }}>
                                                    <div>{Math.round(el.bar)}% Completed</div>
                                                </div>

                                                <div className={`${styles.cardHeading}`}>
                                                    Current Plan Details
                                                </div>
                                                <div className={`${styles.cardHeading}`}>
                                                    {el?.user_package?.package?.name}
                                                </div>

                                                <div className={`${styles.cardHeadingCenter}`}>
                                                    Current Level
                                                </div>
                                                <hr className={`${styles.bluehorizontalLine}`} />
                                                {el?.milestones.map(ele => {
                                                    return (
                                                        <>
                                                            <div className={`${styles.cardTextbold}`}>{ele?.s_no}: {ele?.name}</div>

                                                            <div className={`${styles.cardText}`}><b>{ele?.current_activity?.levels?.s_no}:{ele?.current_activity?.levels?.name}</b> </div>
                                                        </>

                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div key={el.id} className={`${styles.containerdiv} container-fluid ${styles.pinkContainer}`}>
                                    <img src={bear} className={`${styles.pinkContainerImage1}`} alt="" />
                                    <img src={helicopter} className={`${styles.pinkContainerImage2}`} alt="" />
                                    <img src={abc} className={`${styles.pinkContainerImage3}`} alt="" />

                                    <div className={`row pt-5`} >
                                        <div className="mx-auto pt-4 col-lg-8 col-11">
                                            <div className={`${styles.pinkSectionContaienerCard}`}>
                                                <div className={`${styles.cardHeading}`}>
                                                    {el?.name}
                                                </div>
                                                {/* ////////progress-Bar section//////// */}
                                                <div className={`${styles.pinkSectionProgressBarBorder}`}><div className={`${styles.pinkSectionProgressBarContainer}`}><div className={`${styles.pinkSectionProgressBarInnerContainer}`} style={{ width: el.bar }}></div></div></div>
                                                {/* ////////progress-Bar section//////// */}

                                                <div className={`${styles.cardHeading}`}>
                                                    Current Plan Details
                                                </div>
                                                <div className={`${styles.cardHeading}`}>
                                                    {el?.user_package?.package?.name}
                                                </div>

                                                <div className={`${styles.cardHeadingCenter}`}>
                                                    Current Level
                                                </div>
                                                <hr className={`${styles.pinkhorizontalLine}`} />
                                                {el?.milestones.map(ele => {
                                                    return (
                                                        <>
                                                            <div className={`${styles.cardTextbold}`}>{ele?.s_no}: {ele?.name}</div>

                                                            <div className={`${styles.cardText}`}><b>{ele?.current_activity?.levels?.s_no}:{ele?.current_activity?.levels?.name}</b> </div>
                                                        </>

                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </>
                    )
                })
            }

            <Adminhomepagefooter />
        </div>
    )
}
