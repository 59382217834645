import React, { useEffect, useState } from 'react'
import Adminhomepagefooter from '../AdminHomepageFooter/Adminhomepagefooter'
import styles from './AddCSM.module.css'
import back from "../../../assets/img/back.png";
import { useHistory } from 'react-router-dom';
import Loader from "react-loader-spinner";
import Swal from 'sweetalert2';
import { addCSM, getCSM } from '../../../Services/CSM';
export default function AddCSM() {
    const history = useHistory();
    const [name, setName] = useState('');
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [csmArr, setCsmArr] = useState([]);
    const [serialNumber, setSerialNumber] = useState(0);
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [employment_type, setEmployment_type] = useState("");
    const [no_of_children_assigned, setNo_of_children_assigned] = useState(0);
    const handleBackNavigation = () => {
        let path = `/Manage-CSM`;
        history.push(path);
    }


    const createCSM = async () => {
        try {
            setLoading(true)
            if (name == "") {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please enter Name`,
                });
                setLoading(false)

            }
            else if (email == "") {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please enter Email`,
                });
                setLoading(false)

            }
            else if (mobile.length != 10) {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please enter a valid Mobile Number`,
                });
                setLoading(false)
            }
            else if (employment_type == "") {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please enter Employment type`,
                });
                setLoading(false)
            }
            else if (no_of_children_assigned == "") {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please enter No of Children Assigned`,
                });
                setLoading(false)
            }
            else {
                let obj = {
                    name,
                    employment_type,
                    no_of_children_assigned: parseInt(no_of_children_assigned),
                    mobile,
                    email,
                }
                let res = await addCSM(obj)
                if (res.status == 200 || res.status == 304 || res.status == 202) {
                    Swal.fire({
                        title: 'Success',
                        type: 'success',
                        text: `${res?.data?.message}`,
                    });
                    setLoading(false)
                    history.goBack()
                }
            }
        }
        catch (err) {
            let errStr = Object.values(err?.response?.data).flatMap(el => el).reduce((acc, el) => acc + el + ' ', '')
            Swal.fire({
                title: 'Error',
                type: 'error',
                text: `${errStr}`,
            });
            setLoading(false)
        }
    }

    const SerialNumberAllocation = (data) => {
        let lastItem = data.filter((el, index) => index + 1 == data.length);

        // console.log(lastItem)
        if (lastItem[0]?.id) {
            setSerialNumber(lastItem[0]?.id)
        }
        else {
            setSerialNumber(0)
        }

    }
    const getAllCSM = async () => {
        try {
            let res = await getCSM()
            if (res.status == 200 || res.status == 304 || res.status == 202) {
                // console.log("activity", res.data)
                setCsmArr(res.data)
                SerialNumberAllocation(res.data)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
            }
        }
    }





    useEffect(() => {
        getAllCSM()
    }, [])





    return (
        <div>
            <div className={`${styles.header}`}>
                <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div>
                <div className={`${styles.headerHeading}`}>
                    Add CSM
                </div>
            </div>
            {
                loading ?
                    <>

                        <div className="container-fluid">
                            <div className="row mt-5 justify-content-center d-flex">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Loader
                                        visible={true}
                                        type="TailSpin"
                                        color="#FF6F96"
                                        height={100}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className={`${styles.containerdiv} container`}>
                        <div className="row">
                            <div className="mx-auto col-lg-5 col-11">
                                <div className={`${styles.label}`}>Serial Number (Automated)</div>
                                <input disabled value={serialNumber} className={`${styles.txtInput}`} />
                                <div className={`${styles.label}`}>Full Name</div>
                                <input onChange={(e) => setName(e.target.value)} value={name} className={`${styles.txtInput}`} placeholder="eg Sumit Kumar" />
                                <div className={`${styles.label}`}>Type of Employment</div>
                                <select onChange={(e) => setEmployment_type(e.target.value)} value={employment_type} className={`${styles.txtInput}`}>
                                    <option value="">Select Employment Type</option>
                                    <option value="Full-Time">Full-Time</option>
                                    <option value="Part-Time">Part-Time</option>
                                </select>
                                <div className={`${styles.label}`}>Maximum No. of Children Assigned</div>
                                <input onChange={(e) => { e.target.value > 0 ? setNo_of_children_assigned(e.target.value) : alert("Must be a positive number") }} value={no_of_children_assigned} type="number" className={`${styles.txtInput}`} />


                                <div className={`${styles.pageLabel}`}>Login Credentials</div>


                                <div className={`${styles.label}`}>Mobile Number</div>
                                <input onChange={(e) => setMobile(e.target.value)} value={mobile} className={`${styles.txtInput}`} placeholder="eg +91-9999000333" />
                                <div className={`${styles.label}`}>Email ID</div>
                                <input onChange={(e) => setEmail(e.target.value)} value={email} className={`${styles.txtInput}`} placeholder="eg su@gmail.com" />
                                <div className={`${styles.flexRow}`}>
                                    <input onChange={(e) => { setChecked(!checked); }} type="checkbox" className={`${styles.checkInput}`} />
                                    <div className={`${styles.checkBoxTxt}`}>
                                        A message will be sent to the added mobile number giving them access to CompassTot’s
                                        Customer Success Manager platform. Check the box if you agree and proceed.
                                    </div>
                                </div>
                                <div className="row">

                                    <button className={`mx-auto  ${styles.pinkBtn}`} onClick={() => createCSM()}>Save And proceed</button>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            <Adminhomepagefooter />
        </div >
    )
}
