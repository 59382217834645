import React from 'react'

export default function Terms() {
    return (
        <>
            <div className="container">
                <div className="rowd-flex justify-content-center">

                    <div style={{ fontWeight: "bold", marginTop: 70 }}>End User License Agreement (T&C)
                        <br />
                        <br />
                        Childwise Private Limited (CompassTot) End User License Agreement
                        <br />
                        <br />
                        PLEASE READ THE FOLLOWING CAREFULLY BEFORE INSTALLING AND/OR USING CHILDWISE
                        PVT. LTD.’S WEBSITE<br />
                        <br /> AND OR MOBILE SOFTWARE APPLICATION.
                    </div>
                    <div style={{ marginTop: 20 }}>
                        By clicking the “accept” or “ok” button and in any event by installing and/or using the <b>Childwise
                            Pvt. Ltd</b>. mobile software application and or website (the <b>“App”</b> ), you expressly acknowledge
                        and agree that you are entering into a legal agreement with registered email [*] and registered
                        password [*] (“<b>Childwise</b>”, “<b>CompassTot</b>”, “<b>we</b>”, “<b>us</b>” or “<b>our</b>”), and have understood and agree
                        to comply with, and be legally bound by, the terms and conditions of this End User License
                        Agreement (“<b>Agreement</b>”). You hereby waive any applicable rights to require an original (non-
                        electronic) signature or delivery or retention of non-electronic records, to the extent not
                        prohibited under applicable law. If you do not agree to be bound by this Agreement please do
                        not download, install or use the App.
                    </div>
                    <ul style={{ marginTop: 20, display: "block", color: "black" }}>
                        <li><b>Ability to Accept</b>: By installing the App you affirm that you are over eighteen (18) years of age
                            and have full ability and power to enter into this Agreement.</li>
                        <li>
                            <b>App Usage Rules</b>: If you are downloading the App from a third party platform or service
                            provider (“<b>Distributor</b>”) such as, without limitation, Apple’s Appstore or Google Play, please be
                            aware that the Distributor may have established usage rules which also govern your use of the
                            App (“<b>Usage Rules</b>”). Usage Rules may be applicable depending on where the App has been
                            downloaded from. You acknowledge that, prior to downloading the App from a Distributor, you
                            have had the opportunity to review and understand, and will comply with, its Usage Rules. The
                            Usage Rules that are applicable to your use of the App are deemed incorporated into this
                            Agreement by this reference. You represent and warrant that you are not prohibited by any
                            applicable Usage Rules and/or applicable law and/or any third party right from using the App; if
                            you are unable to make such a representation you are prohibited from downloading, installing
                            and/or using the App.
                        </li>
                        <li><b>App License</b>: Subject to the terms and conditions of this Agreement, we hereby grant you a
                            personal, revocable, non-exclusive, non-sublicensable, non-assignable, non-transferable license
                            (“<b>License</b>”) to: (i) download and install the App on a mobile telephone, tablet or device (each a
                            “<b>Device</b>”) that you own, control or are legally entitled to use; and (ii) access and use the App on
                            that Device in accordance with this Agreement and any applicable Usage Rules (defined below).</li>
                        <li><b>License Restrictions</b>: You agree not to, and shall not permit any third party to: (i) sublicense,
                            redistribute, sell, lease, lend, rent or otherwise commercially exploit the App; (ii) make the App
                            available over a network where it could be used by multiple devices owned or operated by
                            different people at the same time; (iii) access, disassemble, reverse engineer, decompile,
                            decrypt, or attempt to derive the source code of, the App; (iv) copy (except for back-up
                            purposes), modify, transform, improve, or create derivative works of the App or any part
                            thereof; (v) circumvent, disable or otherwise interfere with security-related features of the App
                            or features that prevent or restrict use or copying of any content or that enforce limitations on
                            use of the App; (vi) remove, alter or obscure any proprietary notice or identification, including
                            copyright, trademark, patent or other notices, contained in or displayed on or via the App;
                            (vii) use any communications systems provided by the App to send unauthorized and/or
                            unsolicited commercial communications or for illegal purposes, including violations of applicable
                            laws and/or third party rights; (viii) use the CompassTot name, logo or trademarks without our prior written consent; and/or (ix) use the App to violate any applicable laws, rules or regulations,
                            or for any unlawful, harmful, irresponsible, or inappropriate purpose, or in any manner that
                            breaches this Agreement or violates third party rights.</li>
                        <li>In order to use some of the App features you may have to create or use an account (an
                            “<b>Account</b>”). If you create an Account, you must provide accurate and complete information
                            about yourself and your child. You are solely responsible for the activity that occurs in your
                            Account, and you must keep your Account password secure. You must notify us immediately of
                            any unauthorized use of your Account. You can reach us at admin@compasstot.com. The
                            personal information you submit is subject to our Privacy Policy (defined below) which must be
                            expressly consented to by you.</li>
                        <li>
                            <b>Location Data</b>. Certain features or functionality (“<b>Features</b>”) of the App may collect or be
                            dependent on data related to your geographic location (“<b>Location Data</b>”). If you wish to use
                            these Features, you agree to provide or to make your Location Data accessible or available to us.
                            To the extent that we do collect Location Data, we shall use it in accordance with our Privacy
                            Policy (defined below). If you do not provide or make such Location Data accessible then the
                            Features may be limited or not operate.
                        </li>
                        <li>
                            <span><b> Intellectual Property Rights:</b> </span>

                            <ul style={{ marginTop: 20, display: "block", color: "black" }}>
                                <li>Ownership . The App is licensed as described herein and not sold, assigned or transferred to you
                                    under this Agreement and you acknowledge that Childwise Pvt. Ltd. and its licensors retain all
                                    title, ownership rights and Intellectual Property Rights (defined below) in and to the App (and its
                                    related software and know how). We reserve all rights not expressly granted herein to the App.
                                    “<b>Intellectual Property Rights</b>” means any and all rights in and to any and all trade secrets,
                                    patents, copyrights, designs, service marks, trademarks, know-how, or similar intellectual
                                    property rights, as well as any and all moral rights, rights of privacy, publicity and similar rights
                                    of any type under the laws or regulations of any governmental, regulatory, or judicial authority,
                                    whether foreign or domestic.</li>
                                <li>Content. The content on the App or generated by the App, including without limitation, the text,
                                    information, statistics, analytics, documents, descriptions, products, software, graphics, photos,
                                    sounds, videos, interactive features, the App’s uses and, broadly speaking, our services (the
                                    “<b>Materials</b>”), and the trademarks, service marks and logos contained therein (“<b>Marks</b>”, and
                                    together with the Materials the “<b>Content</b>”), is the property of Childwise Pvt. Ltd. and/or its
                                    licensors and may be protected by applicable copyright or other intellectual property laws and
                                    treaties. You shall not violate any Intellectual Property Rights with respect to the App, the
                                    Materials and the Content. “CompassTot” and the CompassTot logo are Marks of Childwise Pvt.
                                    Ltd. and its affiliates. All other Marks used on the App are the trademarks, service marks, or
                                    logos of their respective owners. You shall not violate them.</li>
                                <li>Use of Content . The content on the App is provided to you “as is” for your personal use only and
                                    may not be used, copied, distributed, transmitted, broadcast, displayed, sold, licensed, de-
                                    compiled, or otherwise exploited or marketed for any other purposes whatsoever without our
                                    prior written consent. If you download or print a copy of the content you must retain all
                                    copyright and other proprietary notices contained therein.</li>
                            </ul>
                        </li>
                        <li>
                            <ul style={{ marginTop: 20, display: "block", color: "black" }}>
                                <li>
                                    The use of the App and, therefore, the License granted hereunder, is subject to the monthly or
                                    annual or as otherwise agreed payment intervals in the form of upfront payment and or
                                    subscription charges (the “<b>Fees</b>”). Please be aware that any failure to pay applicable Fees may
                                    result in you not having, or losing, access to some, or all of the App. Unless otherwise specified
                                    in writing by CompassTot, any Fees paid by you to CompassTot are not returnable for any
                                    reason whatsoever and no requests for refunds shall be granted on any grounds whatsoever.
                                    CompassTot, at its sole discretion and only as a goodwill gesture, may decide to return and or
                                    refund Fees either partly or fully, the quantum of such return and or return to be decided solely
                                    by CompassTot and such action by CompassTot shall not be treated as acceptance by
                                    CompassTot of any fault and or any liability and or any valid claim for return or refund.
                                </li>
                                <li>
                                    Please be aware that your use of the App may require and utilize internet connection, data
                                    access, OS or certain updated or upgraded version of certain OS or browsers. To the extent that
                                    third parties, including service providers or carriers, charges for your internet or data usage or
                                    any of the aforementioned concepts are applicable, you agree to be solely responsible for those
                                    charges and understand that failure to pay for such concepts may result in you not having, or
                                    losing, access to some, or all of the App.
                                </li>
                                <li><b>Information Description</b>: We attempt to be as accurate as possible. However, we cannot, and
                                    do not, warrant that the App, the Features, the Content and/or the Materials are accurate,
                                    complete, reliable, current, error-free or virus-free. We reserve the right to make changes of any
                                    kind in or to such concepts, or any part thereof without the requirement of giving you any
                                    notice prior to or after making such changes.</li>
                                <li>We will use any personal information that we may collect or obtain in connection with the App
                                    in
                                    accordance
                                    with
                                    our
                                    privacy
                                    policy
                                    which can
                                    be
                                    viewed
                                    at [<a href='https://compasstot.com/privacy-policy'>https://compasstot.com/privacy-policy</a>.] (“<b>Privacy Policy</b>”), and you expressly consent to us doing
                                    so. Notwithstanding, you are aware that you are not legally obligated to provide us personal
                                    information, and you hereby confirm that providing us personal information is at your own free
                                    will. Please also be aware that certain personal information and other information provided by
                                    you in connection with your use of the App may be stored on your Device (even if we do not
                                    collect that information). You are solely responsible for maintaining the security of your Device
                                    from unauthorized access. Notwithstanding your telephone/mobile is registered with Customer
                                    Preference Registration Facility (CPRF) and or notwithstanding the privacy policy of CompassTot
                                    and or notwithstanding the Telecom Regulatory Authority of India [TRAI] restrictions/guidelines
                                    on unsolicited tele-calls or guidelines/regulations on commercial communications or other TRAI
                                    Regulations/ guidelines, or any other Regulations/guidelines of any governmental/quasi-
                                    judicial/judicial authorities, whether in India or anywhere in the world, by you accessing the App
                                    of CompassTot and or by logging in with User ID and password and or by you using the website
                                    and/or App of CompassTot and or leaving CompassTot’s website and/or App half way
                                    enquiring/verifying/registering for website and/or App of CompassTot as to products/Services of
                                    CompassTot and or CompassTot’s affiliates whether specifically disclosed to you or not, and or
                                    by making a request to call you back, it is deemed that you have expressly authorized
                                    CompassTot to provide your phone number/mobile number, email ID and other contact
                                    details/personal details of you and details as to Services availed/product purchased by you from
                                    CompassTot to CompassTot’s affiliates and or associates and or vendors and or service providers
                                    and CompassTot can at its sole discretion provide/share your phone number/mobile number,
                                    email ID and other contact details/personal details to CompassTot’s affiliates and or associates
                                    and or vendors and or service providers, and in this regard you are hereby authorizing
                                    CompassTot and its affiliates and or associates and or vendors and or service providers to call
                                    you or send you text messages/electronic communications, for solicitation and
                                    procurement/cross selling of various products and or services of CompassTot and or various
                                    products and services of CompassTot’s affiliates and or associates and or vendors and or service
                                    providers, including the upgrading and or renewal of services availed/product purchased by you
                                    from CompassTot and or CompassTot’s affiliates and or associates and or vendors and or service
                                    providers. In this regard you shall be deemed to have provided your consent to CompassTot and
                                    CompassTot’s affiliates and or associates and or vendors and or service providers, as per TRAI
                                    guidelines/regulations and accordingly you shall not have any complaint on whatever grounds
                                    nor complain to TRAI or any other governmental/quasi-judicial/judicial authorities, whether in
                                    India or anywhere in the world, as to any alleged unsolicited calls/messages/ communication by
                                    CompassTot and or CompassTot’s affiliates and or associates and or vendors and or service
                                    providers to you.”</li>
                            </ul>
                        </li>
                        <li>
                            <span>
                                <b>
                                    Warranty Disclaimers.
                                </b>
                            </span>
                            <ul style={{ marginTop: 20, display: "block", color: "black" }}>
                                <li>
                                    THE APP, THE FEATURES, THE CONTENT AND THE MATERIALS ARE PROVIDED ON AN “AS IS”
                                    AND “AS AVAILABLE” BASIS WITHOUT WARRANTIES OF ANY KIND INCLUDING, WITHOUT
                                    LIMITATION, REPRESENTATIONS, WARRANTIES AND CONDITIONS OF MERCHANTABILITY,
                                    FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, WHETHER EXPRESS,
                                    IMPLIED, OR STATUTORY, AND THOSE ARISING BY STATUTE OR FROM A COURSE OF DEALING
                                    OR USAGE OF TRADE.
                                </li>
                                <li>
                                    WE DISCLAIM ANY WARRANTY AS TO THE ACCURACY, COMPLETENESS, OR TIMELINESS OF ANY
                                    INFORMATION TRANSMITTED VIA THE APP, THE FEATURES, THE CONTENT AND/OR THE
                                    MATERIALS, AND/OR AS TO THE RESULTS TO BE ATTAINED BY YOU FROM ACCESS TO OR USE OF
                                    THE APP, THE FEATURES, THE CONTENT AND/OR THE MATERIALS.
                                </li>
                                <li>
                                    THE CONTENT, INFORMATION AND CONTENT AND THE SERVICES IN GENERAL ARE NOT
                                    INTENDED FOR, NOR DO THEY CONSTITUTE, LEGAL, PROFESSIONAL, PSYCHOLOGICAL,
                                    EDUCATIONAL, MEDICAL OR HEALTH CARE SERVICES OR DIAGNOSES AND MAY NOT BE USED
                                    FOR SUCH PURPOSES. WE MAKE NO WARRANTIES WITH RESPECT TO ANY RESULTS FROM THE
                                    USE OF THE SERVICES PROVIDED. WE RECOMMEND THAT PARENTS AND/OR GUARDIANS NEVER
                                    LEAVE THEIR CHILDREN UNATTENDED AND SHOULD SEEK THEIR PHYSICIAN TO RESOLVE ANY
                                    QUESTIONS ABOUT ANY MEDICAL CONDITION. YOU SHOULD NOT ACT OR REFRAIN FROM
                                    ACTING BASED ON THE INFORMATION, SERVICES, PLATFORMS, APPLICATIONS OR CONTENT
                                    AVAILABLE ON OUR WEBSITE AND/OR APP.
                                </li>
                                <li>
                                    WE DO NOT WARRANT THAT THE APP, THE FEATURES, THE CONTENT AND/OR THE MATERIALS
                                    WILL OPERATE ERROR-FREE, THAT THE APP IS FREE OF VIRUSES OR OTHER HARMFUL CODE OR
                                    THAT WE WILL CORRECT ANY ERRORS THEREIN. YOU AGREE THAT WE WILL NOT BE HELD
                                    RESPONSIBLE FOR ANY CONSEQUENCES TO YOU OR ANY THIRD PARTY THAT MAY RESULT FROM
                                    TECHNICAL PROBLEMS INCLUDING WITHOUT LIMITATION IN CONNECTION WITH THE INTERNET
                                    (SUCH AS SLOW CONNECTIONS, TRAFFIC CONGESTION OR OVERLOAD OF OUR OR OTHER
                                    SERVERS) OR ANY NETWORK OR TELECOMMUNICATIONS OR INTERNET PROVIDERS. CHILDWISE
                                    PVT. LTD. DOES NOT WARRANT THIRD PARTY PRODUCTS, SERVICES AND/OR COMPONENTS.
                                </li>
                                <li>
                                    IF YOU HAVE A DISPUTE WITH ANY OTHER APP USER OR THIRD PARTY, YOU AGREE THAT WE
                                    ARE NOT LIABLE FOR ANY CLAIMS OR DAMAGES ARISING OUT OF OR CONNECTED WITH SUCH A
                                    DISPUTE.
                                </li>
                                <li>
                                    Applicable law may not allow the exclusion of certain warranties, so to that extent such
                                    exclusions may not apply.
                                </li>
                            </ul>

                        </li>
                        <li>
                            <span>
                                <b>
                                    Limitation of Liability.
                                </b>
                            </span>
                            <ul style={{ marginTop: 20, display: "block", color: "black" }}>
                                <li>UNDER NO CIRCUMSTANCES SHALL CHILDWISE PVT. LTD. BE LIABLE FOR ANY SPECIAL, DIRECT,
                                    INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, OR FOR ANY LOSS OF DATA,
                                    REVENUE, BUSINESS OR REPUTATION, THAT ARISES UNDER OR IN CONNECTION WITH THIS
                                    AGREEMENT, OR THAT RESULTS FROM THE USE OF, OR THE INABILITY TO USE, THE APP, THE
                                    FEATURES, THE CONTENT AND/OR THE MATERIALS EVEN IF CHILDWISE PVT. LTD. HAS BEEN
                                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</li>
                                <li>IN ANY EVENT, CHILDWISE PVT. LTD.’S TOTAL AGGREGATE LIABILITY FOR ALL DAMAGES AND
                                    LOSSES THAT ARISE UNDER OR IN CONNECTION WITH THIS AGREEMENT, OR THAT RESULT
                                    FROM YOUR USE OF OR INABILITY TO USE THE APP, THE FEATURES, THE CONTENT AND/OR THE
                                    MATERIALS, SHALL NOT, IN ANY CIRCUMSTANCE, EXCEED THE TOTAL AMOUNTS, IF ANY,
                                    ACTUALLY PAID TO CHILDWISE PVT. LTD. FOR USING THE APP WITHIN THE TWO (2) MONTHS
                                    PRECEDING THE DATE OF BRINGING A CLAIM.</li>
                                <li>
                                    NEITHER PARTY SHALL BE LIABLE TO THE OTHER FOR BREACH OF OBLIGATIONS UNDER ANY
                                    AGREEMENT ARISING OUT OF AN EVENT BEYOND THE CONTROL OF THE PARTIES, INCLUDING
                                    WITHOUT LIMITATION ACTS OF NATURE, TERRORISM, WAR, POLITICAL INSURGENCY,
                                    INSURRECTION, RIOT, CIVIL DISTURBANCE, CIVIL OR MILITARY ACTS, INSURRECTION,
                                    EARTHQUAKE, FLOOD OR ANY OTHER CAUSE NATURAL OR CREATED BY MAN BEYOND OUR
                                    CONTROL, WHICH CAUSES THE TERMINATION OF AN AGREEMENT OR CONTRACT ENTERED
                                    INTO, AND WHICH COULD NOT REASONABLY BE FORESEEN. ANY PARTY AFFECTED BY SUCH
                                    EVENTS SHALL PROMPTLY GIVE NOTICE TO THE OTHER PARTY AND SHALL MAKE EVERY EFFORT
                                    TO COMPLY WITH THE TERMS AND CONDITIONS OF ANY AGREEMENT CONTAINED HEREIN.
                                </li>
                                <li>
                                    You agree to defend, indemnify and hold harmless Childwise Pvt. Ltd. and our affiliates, and our
                                    respective officers, directors, employees and agents, from and against any and all claims,
                                    damages, obligations, losses, liabilities, costs and expenses (including but not limited to
                                    attorney’s fees) arising from: (i) your use of, or inability to use, the App, the Features, the
                                    Materials and/or the Content; (ii) your violation of this Agreement; and (iii) your violation of any
                                    applicable law and/or third party right, including without limitation any copyright, property,
                                    Intellectual Property Right, or privacy right. Without derogating from or excusing your
                                    obligations under this section, we reserve the right (at your own expense), but are not under
                                    any obligation, to assume the exclusive defense and control of any matter which is subject to an
                                    indemnification by you if you choose not to defend or settle it. You agree not to settle any
                                    matter subject to an indemnification by you without first obtaining our express approval.
                                </li>
                                <li><b>Export Laws</b>: You agree to comply fully with all applicable export laws and regulations to ensure
                                    that neither the App (including the Features, the Content and the Materials) nor any technical
                                    data related thereto are exported or re-exported directly or indirectly in violation of, or used for
                                    any purposes prohibited by, such laws and regulations.</li>
                                <li>
                                    <b>Updates and Upgrades</b>: We may from time to time provide updates or upgrades to the App
                                    (each a “<b>Revision</b>”), but are not under any obligation to do so. Such Revisions will be supplied
                                    according to our then-current policies, which may include automatic updating or upgrading
                                    without any additional notice to you. You consent to any such automatic updating or upgrading
                                    of the App. All references herein to the App shall include Revisions. This Agreement shall govern
                                    any Revisions that replace or supplement the original App, unless the Revision is accompanied
                                    by a separate license agreement which will govern the Revision.
                                </li>
                                <li>
                                    <b>Third Party Open Source Software</b>: Portions of the App may include third party open source
                                    software that are subject to third party terms and conditions (“<b>Third Party Terms</b>”). If there is a
                                    conflict between any Third Party Terms and the terms of this Agreement, then the Third Party
                                    Terms shall prevail but solely in connection with the related third party open source software.
                                    Notwithstanding anything in this Agreement to the contrary, Childwise Pvt. Ltd. makes no
                                    warranty or indemnity hereunder with respect to any third party open source software.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span>
                                <b>
                                    Term and Termination:
                                </b>
                            </span>
                            <ul style={{ marginTop: 20, display: "block", color: "black" }}>
                                <li>
                                    This Agreement is effective until terminated by us or you. We reserve the right, at any time, to:
                                    (i) discontinue or modify any aspect of the App; and/or (ii) terminate this Agreement and your
                                    use of the App with or without cause and shall not be liable to you or any third party for any of
                                    the foregoing. If you object to any term or condition of this Agreement or any subsequent
                                    modifications thereto, or become dissatisfied with the App in any way, your only recourse is to
                                    immediately discontinue use of the App.
                                </li>
                                <li>Upon termination of this Agreement, you shall cease all use of the App. This Section 17.2 and
                                    Sections, 7 (Intellectual Property Rights), 10 (Privacy), 11 (Warranty Disclaimers), 12 (Limitation
                                    of Liability), 13 (Indemnity), 16 (Third Party Open Source Software) and 18 (Assignment) to 21
                                    (General) shall survive termination of this Agreement.</li>
                                <li>This Agreement, and any rights and licenses granted hereunder, may not be transferred or
                                    assigned by you but may be assigned by Childwise Pvt. Ltd. without restriction or notification.</li>
                                <li>
                                    We reserve the right to modify this Agreement at any time by publishing the revised Agreement
                                    on the website and/or App and/or by sending you an in-App notification. Such change will be
                                    effective immediately following the foregoing notification thereof, and your continued use of
                                    the App thereafter means that you accept those changes.
                                </li>
                                <li>
                                    <b>Governing Law and Disputes</b>. This Agreement shall be governed by and construed in
                                    accordance with the laws of the State of India without regard to its conflict of laws rules. You
                                    agree to submit to the personal and exclusive jurisdiction of the courts located in Mumbai,
                                    India, and waive any jurisdictional, venue, or inconvenient forum objections to such courts.
                                    Notwithstanding the foregoing, we may seek injunctive relief in any court of competent
                                    jurisdiction. Nothing in this Agreement shall limit your rights under applicable mandatory law.
                                </li>
                                <li>
                                    This Agreement, and any other legal notices published by us in connection with the App, shall
                                    constitute the entire agreement between you and Childwise Pvt. Ltd. concerning the App. In the
                                    event of a conflict between this Agreement and any such legal notices, the terms of the
                                    applicable notice shall prevail with respect to the subject matter of such notice. No amendment
                                    to this Agreement will be binding unless in writing and signed by Childwise Pvt. Ltd. If any
                                    provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity
                                    of such provision shall not affect the validity of the remaining provisions of this Agreement,
                                    which shall remain in full force and effect. No waiver of any term of this Agreement shall be
                                    deemed a further or continuing waiver of such term or any other term, and a party’s failure to
                                    assert any right or provision under this Agreement shall not constitute a waiver of such right or
                                    provision. YOU AGREE THAT ANY CAUSE OF ACTION THAT YOU MAY HAVE ARISING OUT OF OR
                                    RELATED TO THE APP MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                                    ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED. Nothing in these
                                    terms shall limit your rights under applicable mandatory law.
                                </li>
                                <li>
                                    You represent and warrant that: (a) you are not located in a country that is subject to a U.S.
                                    Government embargo, or that has been designated by the U.S. Government or by India as a
                                    “terrorist supporting” country; and (b) you are not listed on any U.S. Government or Indian list
                                    of prohibited or restricted parties.
                                </li>
                                <li>
                                    If you have any questions, complaints, or claims regarding the App, please contact Childwise Pvt.
                                    Ltd. at:
                                    Email: admin@compasstot.com
                                </li>
                                <li>
                                    By entering into this Agreement you, to the extent legally permitted, hereby waive any
                                    applicable law requiring that this Agreement be localized to meet your language and other local
                                    requirements
                                </li>
                                <li>
                                    [<a href='https://compasstot.com/privacy-policy'>https://compasstot.com/privacy-policy</a> ] (“Privacy Policy”)
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
