import './App.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";
import React, { useEffect, useState, createContext, useMemo } from 'react';
import Homescreen from './Components/HomeScreen/Homescreen';
import QuestionAnswers from './Components/QuestionAnswer/QuestionAnswers';
import DashboardContainer from './Components/Dashboard/DashboardContainer';
import Profile from './Components/Profile/Profile';
import AdminDashboard from './Components/AdminComponents/AdminDashboard/AdminDashboard';
import AdminHomepage from './Components/AdminComponents/AdminHomepage/AdminHomepage';
import AdminChildProfile from './Components/AdminComponents/AdminChildProfile/AdminChildProfile';
import AdminMilestone from './Components/AdminComponents/AdminMilestone/AdminMilestone';
import AdminActivity from './Components/AdminComponents/AdminActivity/AdminActivity';
import AdminLevel from './Components/AdminComponents/AdminLevel/AdminLevel';
import ActivityCenter from './Components/ActivityCenter/ActivityCenter';
import Timetable from './Components/Dashboard/TimeTable/Timetable';
import ActivityDetails from './Components/AcitivityDetails/ActivityDetails';
import CompletedActivities from './Components/CompletedActivities/CompletedActivities';
import UpcomingActivities from './Components/UpcomingActivities/UpcomingActivities';
import OngoingActivities from './Components/OngoingActivities/OngoingActivities';
import ManageCSM from './Components/AdminComponents/ManageCSM/ManageCSM';
import AddCSM from './Components/AdminComponents/AddCSM/AddCSM';
import CSMCustomers from './Components/AdminComponents/CSMCustomers/CSMCustomers';
import ChildDetails from './Components/AdminComponents/ChildDetails/ChildDetails';
import CSMChildrenList from './Components/CSMComponents/CSMChildrenList/CSMChildrenList';
import CSMProfile from './Components/CSMComponents/CSMProfile/CSMProfile';
import CSMChildDetails from './Components/CSMComponents/CSMChildDetails/CSMChildDetails';
import CSMChildProfile from './Components/CSMComponents/CSMChildProfile/CSMChildProfile';
import CSMCustomPlans from './Components/CSMComponents/CSMCustomPlans/CSMCustomPlans';
import CSMChildParentsDetails from './Components/CSMComponents/CSMChildParentsDetails/CSMChildParentsDetails';
import ChildrenProfile from './Components/ChildrenProfile/ChildrenProfile';

import jwt_decode from 'jwt-decode'

import axios from 'axios'
import Payments from './Components/Payments/Payments';
import NotFound from './Components/NotFound/NotFound';
import PaymentSuccess from './Components/Payments/PaymentSuccess';
import PaymentError from './Components/Payments/PaymentError';
import AdminCsmList from './Components/AdminComponents/AdminCsmList/AdminCsmList';
import AdminCsmCustomerList from './Components/AdminComponents/AdminCsmCustomerList/AdminCsmCustomerList';
import AdminChildParentDetails from './Components/AdminComponents/AdminChildParentDetails/AdminChildParentDetails';
import ActivityCenterDetails from './Components/ActivityCenterDetails/ActivityCenterDetails';
import Settings from './Components/Settings/Settings';
import UserPersonalDetails from './Components/UserPersonalDetails/UserPersonalDetails';
import UserPayments from './Components/UserPayments/UserPayments';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import Terms from './Components/TermsAndConditions/Terms';



export const AuthContext = createContext()
export const jwtContext = createContext()

function App() {

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [decodedObj, setDecodedObj] = useState({ role: '' })
  const history = useHistory()



  const checkAuthorized = () => {
    let token = localStorage.getItem('ADMIN_AUTH_TOKEN');
    let role = localStorage.getItem('role')
    if (role)
      setDecodedObj({ role: role })
    if (token) {
      setIsAuthorized(true)
      let roleName = localStorage.getItem('role')
      console.log(roleName)
      if (roleName == "admin") {
        let path = `/Admin-Dashboard`;

        // history.push(path);
        <Redirect push to={path} />

      }
      else if (roleName == "user") {
        // alert("heu")
        let path = `/Dashboard`;
        <Redirect to={path} />
      }
      else if (roleName == "csm") {
        let path = `/CSM-Manage-Children`;
        <Redirect push to={path} />
      }
    }
    else {
      setIsAuthorized(false)
    }
  }






  useMemo(() => {
    axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem('ADMIN_AUTH_TOKEN');
        // console.log(typeof config.url, "interseptor Config")
        if (config.url == "http://staging.favcy.com/payments/pay") {

          return config;
        }
        else {
          if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
          }
          // config.headers['Content-Type'] = 'application/json';
          return config;

        }
      },
      error => {
        Promise.reject(error)
      });
    axios.interceptors.response.use(
      (res) => {
        // Add configurations here
        return res;
      },
      (err) => {
        console.log(err?.response?.status, "ONLY IN ERROR")
        if (err?.response?.status === 401 || err?.response?.data?.message === "Unauthenticated." || err?.response?.message == "unauthorized") {
          // toast.error('401 Unauthorized')
          localStorage.removeItem('FAVCY_AUTH_TOKEN')
          localStorage.removeItem('ADMIN_AUTH_TOKEN')
          setIsAuthorized(false)

          ///////////////
          history.push("/")
          // window.location.href = "http://localhost:3000/"

        }
        return Promise.reject(err);
      }
    );
  }, [])


  useEffect(() => {
    checkAuthorized()
  }, [])





  return (
    <AuthContext.Provider value={[isAuthorized, setIsAuthorized]}>
      <jwtContext.Provider value={[decodedObj, setDecodedObj]}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Homescreen />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
            <Route exact path="/payment-success">
              <PaymentSuccess />
            </Route>
            <Route exact path="/payment-error">
              <PaymentError />
            </Route>

            <Route path="/Test">
              <QuestionAnswers />
            </Route>
            <Route path="/payments">
              <Payments />
            </Route>
            {isAuthorized && decodedObj?.role == "admin" &&
              <>
                <Route exact path="/Admin-Dashboard">
                  <AdminHomepage />
                </Route>
                <Route exact path="/Admin-Child-Profile/:id">
                  <AdminChildProfile />
                </Route>
                <Route exact path="/Admin-Milestones/:id">
                  <AdminMilestone />
                </Route>
                <Route exact path="/Admin-Activity/:id">
                  <AdminActivity />
                </Route>
                <Route exact path="/Admin-Level/:id">
                  <AdminLevel />
                </Route>
                {/* //////admin CSM///// */}
                <Route exact path="/Manage-CSM">
                  <ManageCSM />
                </Route>
                <Route exact path="/Admin-CSM-List">
                  <AdminCsmList />
                </Route>
                <Route exact path="/Admin-CSM-User-List">
                  <AdminCsmCustomerList />
                </Route>
                <Route exact path="/Admin-Child-Parent-Details/:id">
                  <AdminChildParentDetails />
                </Route>
                <Route exact path="/CSM-Customers/:id">
                  <CSMCustomers />
                </Route>
                <Route exact path="/Add-CSM">
                  <AddCSM />
                </Route>
                <Route exact path="/Admin-CSM-child-details/:id">
                  <ChildDetails />
                </Route>
              </>
            }
            {isAuthorized && decodedObj?.role == "user" &&
              <>
                <Route path="/Dashboard">
                  <DashboardContainer />
                </Route>
                <Route path="/Test">
                  <QuestionAnswers />
                </Route>
                <Route path="/Profile">
                  <Profile />
                </Route>
                <Route path="/Activity-Center">
                  <ActivityCenter />
                </Route>
                <Route path="/Activities-Completed">
                  <CompletedActivities />
                </Route>
                <Route path="/Activities-Upcoming">
                  <UpcomingActivities />
                </Route>
                <Route path="/Activities-Ongoing">
                  <OngoingActivities />
                </Route>
                <Route path="/Activity-Detail/:id">
                  <ActivityDetails />
                </Route>
                <Route path="/Activity-Center-Detail/:id">
                  <ActivityCenterDetails />
                </Route>
                <Route path="/Time-Table">
                  <Timetable />
                </Route>
                <Route path="/Settings">
                  <Settings />
                </Route>
                <Route path="/User-Personal-Details">
                  <UserPersonalDetails />
                </Route>
                <Route path="/User-Payments">
                  <UserPayments />
                </Route>
              </>
            }
            {
              isAuthorized && decodedObj?.role == "csm" &&
              <>
                <Route path="/CSM-Manage-Children">
                  <CSMChildrenList />
                </Route>
                <Route path="/CSM-Child-Details/:id">
                  <CSMChildDetails />
                </Route>
                <Route path="/CSM-Child-Profile/:id">
                  <CSMChildProfile />
                </Route>
                <Route path="/CSM-Custom-Plans">
                  <CSMCustomPlans />
                </Route>
                <Route path="/CSM-Child-Parents-Details/:id">
                  <CSMChildParentsDetails />
                </Route>
                <Route path="/CSM-Profile">
                  <CSMProfile />
                </Route>
              </>
            }
            {/* /////user////// */}


            {/* <Route exact path="/">
            <Homescreen />
          </Route>
         
          <Route path="/Profile">
            <Profile />
          </Route>
          <Route path="/Activity-Center">
            <ActivityCenter />
          </Route>
          <Route path="/Activities-Completed">
            <CompletedActivities />
          </Route>
          <Route path="/Activities-Upcoming">
            <UpcomingActivities />
          </Route>
          <Route path="/Activities-Ongoing">
            <OngoingActivities />
          </Route>
          <Route path="/Activity-Detail">
            <ActivityDetails />
          </Route>
          <Route path="/Time-Table">
            <Timetable />
          </Route> */}


            {/* ///////admin//////// */}
            {/* <Route path="/AdminHome">
            <AdminDashboard />
          </Route>
          <Route path="/Admin-Dashboard">
            <AdminHomepage />
          </Route>
          <Route path="/Admin-Child-Profile">
            <AdminChildProfile />
          </Route>
          <Route path="/Admin-Milestones/:id">
            <AdminMilestone />
          </Route>
          <Route path="/Admin-Activity/:id">
            <AdminActivity />
          </Route>
          <Route path="/Admin-Level/:id">
            <AdminLevel />
          </Route> */}
            {/* //////admin CSM///// */}
            {/* <Route path="/Manage-CSM">
            <ManageCSM />
          </Route>
          <Route path="/CSM-Customers">
            <CSMCustomers />
          </Route>
          <Route path="/CSM-Customers">
            <CSMCustomers />
          </Route>
          <Route path="/Add-CSM">
            <AddCSM />
          </Route>
          <Route path="/Admin-CSM-child-details">
            <ChildDetails />
          </Route> */}

            {/* //////CSM///// */}
            {/* <Route path="/CSM-Manage-Children">
            <CSMChildrenList />
          </Route>
          <Route path="/CSM-Child-Details">
            <CSMChildDetails />
          </Route>
          <Route path="/CSM-Child-Profile">
            <CSMChildProfile />
          </Route>
          <Route path="/CSM-Custom-Plans">
            <CSMCustomPlans />
          </Route>
          <Route path="/CSM-Child-Parents-Details">
            <CSMChildParentsDetails />
          </Route>
          <Route path="/CSM-Profile">
            <CSMProfile />
          </Route> */}
            {/* ///////children profile /////// */}
            {/* <Route path="/Children-Profile">
            <ChildrenProfile />
          </Route> */}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </jwtContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
