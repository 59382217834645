import CSMFooter from '../CSMFooter/CSMFooter'
import styles from "./CSMProfile.module.css"
import { getCSMCustomersFromCsmDetails, getCSMProfile, updateCSMprofile } from '../../../Services/CSM';
import React, { useState, useEffect, useContext } from 'react';


import { Line } from 'react-chartjs-2';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";
// import { CloneDeep } from "react-lodash";
import _ from 'lodash'
////////images
import { AuthContext, jwtContext } from '../../../App';

import chevronRight from "../../../assets/img/chevronRight.png";
import growth from '../../../assets/img/growth.png';
import checklist from '../../../assets/img/checklist.png';
import customerreview from '../../../assets/img/customer-review.png';
import parents1 from '../../../assets/img/parents1.png';
import back from "../../../assets/img/back.png";
import helicopterLeft from "../../../assets/img/helicopter_cloud_modal_left.png";
import cloudRight from "../../../assets/img/cloud_modal_right.png";
import chevronRightBlack from "../../../assets/img/chevronRightBlack.png";
export default function CSMProfile() {
    const history = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [csmProfile, setCsmProfile] = useState({});
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

    let id = useParams()
    console.log(id.id)
    const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
    const [decodedJwt, setDecodedJwt] = useContext(jwtContext);
    const data = {
        labels: ['1', '2', '3', '4', '5', '6'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                fill: false,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
            {
                label: '# of asd',
                data: [120, 190, 30, 50, 20, 30],
                fill: false,
                backgroundColor: 'rgba(50,53,93,1)',
                borderColor: 'rgba(50,53,93,0.5)',
            },
        ],
    };


    const getCsmUserDetails = async () => {
        try {
            setLoading(true)
            let res = await getCSMProfile()
            console.log(res.data.data)
            if (res.status == 200 || res.status == 304) {
                setLoading(false)
                setCsmProfile(res.data.data)
                setName(res.data.data.name)
                setEmail(res.data.data.email)
                // let temparr = []
                // res.data.data.users.forEach(el => {
                //     if (el.user.length > 0) {
                //         temparr.push(el.user[0])
                //     }
                // });
                // setUserArr(temparr)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)

                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }
    }
    
    const handleCSMProfileUpdate = async () => {
        try {
            setLoading(true)
            let obj = {
                name:name,
                email:email,
            }
            let res = await updateCSMprofile(obj)
            console.log(res.data.data)
            if (res.status == 200 || res.status == 304) {
                setLoading(false)
                getCSMProfile()
                // let temparr = []
                // res.data.data.users.forEach(el => {
                //     if (el.user.length > 0) {
                //         temparr.push(el.user[0])
                //     }
                // });
                // setUserArr(temparr)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)

                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }
    }




    const handleBackNavigation = () => {
        let path = `/CSM-Manage-Children`;
        history.push(path);
    }
    useEffect(() => {
        getCsmUserDetails()
    }, [])



    const handleLogout = () => {
        localStorage.removeItem('FAVCY_AUTH_TOKEN')
        localStorage.removeItem('ADMIN_AUTH_TOKEN')
        localStorage.removeItem('role');
        setIsAuthorized(false)
        setDecodedJwt({ role: '' })
        history.push('/')
    }


    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    };
    return (
        <>
            <div className={`${styles.headerProfile}`}>
                <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div>
                <div className={`${styles.headerHeadings}`} style={{ color: "#fff" }}>
                    Profile
                </div>
            </div>
            {
                loading ?
                    <>
                        <div className="row mt-5 justify-content-center d-flex">
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Loader
                                    visible={true}
                                    type="TailSpin"
                                    color="#FF6F96"
                                    height={100}
                                    width={100}
                                />
                            </div>
                        </div>
                    </>
                    :
                    <div className={`${styles.mainProfileContainer}`}>

                        <div className={`${styles.chartContainer}`}>
                            <div className={`${styles.childName}`}><b>Your Name</b></div>
                            <input onChange={(e)=> setName(e.target.value)} value={name}  className={`${styles.txtInput}`} placeholder="Enter your name" />
                            <div className={`${styles.age}`}><b>Number</b></div>
                            <input value={csmProfile?.mobile} disabled className={`${styles.txtInput}`} placeholder="Enter your number" />
                            <div className={`${styles.age}`}><b>Email</b></div>
                            <input onChange={(e)=> setEmail(e.target.value)} value={email}  className={`${styles.txtInput}`} placeholder="Enter your email" />
                            <button  className={`btn ${styles.blueBtn}`} onClick={()=> handleCSMProfileUpdate()}>Update  Profile</button>

                        </div>


                        <div className={`${styles.optionsContainer2}`} style={{ margin: "25px" }} onClick={() => handleLogout()}>
                            <img src={customerreview} className={`${styles.leftIcon}`} alt="" />
                            <div className={`${styles.optionsTextContainer}`}>
                                <div className={`${styles.optionsTextHeading}`}>
                                    Logout
                                </div>
                            </div>
                            <img src={chevronRight} className={`${styles.chevronRight}`} alt="" />
                        </div>
                    </div>


            }
            <CSMFooter />


        </>
    )
}
