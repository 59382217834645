import React, { useRef, useEffect, useState, useContext } from 'react'
import styles from "./AdminDashboard.module.css"


import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

///////bootstrap 
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";

import Swal from 'sweetalert2';


/////images 
import logo from '../../../assets/img/logo.png'
import videoPlayBtn from '../../../assets/img/videoPlayBtn.png';
import facebook from '../../../assets/img/facebook.png'
import facebookLogo from '../../../assets/img/facebookLogo.png'
import mobilephone from '../../../assets/img/mobilephone.png'
import linkedIn from '../../../assets/img/linkedin.png'
import twitter from '../../../assets/img/twitter.png'
import youtube from '../../../assets/img/youtube.png'
import favcyLogo from "../../../assets/img/favcyLogo.png"
import favcyLogo2 from "../../../assets/img/favcyLogo2.png"



///////video
import video from "../../../assets/videos/video.mp4"
import { useHistory } from 'react-router-dom';


import { AuthContext, jwtContext } from '../../../App';

///////services
import { adminLogin, getOtpFromNumber } from '../../../Services/Admin';


function AdminDashboard() {
    const [modalShow, setModalShow] = useState(false);
    const [modalShowSendOtp, setModalShowSendOtp] = useState(false);
    const [modalShowVerifyOtp, setModalShowVerifyOtp] = useState(false);
    const HowItWorksRef = useRef()
    const history = useHistory();
    const [mobile, setMobile] = useState("");
    const [gToken, setGToken] = useState("");
    const handleRedirectToAdminDash = () => {
        let path = `/Admin-Dashboard`;
        history.push(path);
    }

    const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
    const [decodedObj, setDecodedObj] = useContext(jwtContext);

    const getOtp = async (obj) => {
        try {
            let res = await getOtpFromNumber(obj)
            if (res.status == 201 || res.status == 200 || res.status == 304) {
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.data?.data?.message}`,
                });
                setGToken(res.data.guest_token)
            }
            setMobile(obj.mobile)
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.log(err)
                // alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });

            }
        }
    }
    const loginAdmin = async (obj) => {
        try {
            let formData = new FormData()
            formData.append("mobile", parseInt(obj.mobile))
            formData.append("country_code", parseInt(91))
            formData.append("guest_token", obj.guest_token)
            formData.append("otp", parseInt(obj.otp))

            let res = await adminLogin(formData)
            console.log(res)
            if (res.status == 201 || res.status == 200 || res.status == 304) {
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.message}`,
                });
                localStorage.setItem("ADMIN_AUTH_TOKEN", res.data.token);
                let tempObj = {
                    role: res?.data?.user?.roles[0]?.name
                }
                console.log(tempObj)
                setDecodedObj(tempObj)
                localStorage.setItem("role", res?.data?.user?.roles[0]?.name);

                setIsAuthorized(true)
                handleRedirectToAdminDash()

            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.log(err)
                alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });

            }
        }
    }


    function MyVerticallyCenteredModal(props) {

        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className={styles.modalHeaderContainer}>
                    <Modal.Title className={`${styles.ModalHeaderPink}`} id="contained-modal-title-vcenter">
                        <div className="text-white" style={{ fontSize: 18 }}>LOGIN</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {/* <button className={styles.LoginBtnBlue} style={{ backgroundColor: "#395A93" }} onClick={() => { props.onHide(); setModalShowSendOtp(true) }}> <img src={facebookLogo} style={{ height: 18, width: 10, marginRight: 5 }} alt="" /> LOGIN WITH FACEBOOK</button> */}
                    <button className={styles.LoginBtnBlue} onClick={() => { props.onHide(); setModalShowSendOtp(true); }}>
                        <img src={mobilephone} style={{ height: 18, width: 15, marginRight: 5 }} alt="mobile" />
                        LOGIN WITH OTP</button>
                    <div className="row mt-4">
                        <div className="col-1">
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="col-11">

                            <div style={{ marginTop: 0, padding: 0, textAlign: "left", fontSize: 13 }} className={styles.ModalTxt}> I agree to the  <u><b> Terms & Conditions
                            </b></u> and
                                <b><u>Privacy Policy </u></b>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        );
    }




    function MyVerticallyCenteredModal1({ handleotp, ...props }) {
        const [phone, setPhone] = useState(0);

        const handleSendingOtp = () => {
            if (phone.length <= 12 && phone.length > 1) {
                let tempPhone = phone
                tempPhone = tempPhone.slice(2)
                setPhone(previousValue => previousValue.slice(2))
                let obj = {
                    mobile: tempPhone,
                    country_code: parseInt(91),
                }
                handleotp(obj)
                setModalShowVerifyOtp(true);
                props.onHide();
            }
            else {
                
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please Enter a valid Mobile Number`,
                });
            }
        }


        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className={styles.modalHeaderContainer}>
                    <Modal.Title className={`${styles.ModalHeader}`} id="contained-modal-title-vcenter">
                        <img className={`${styles.modalHeaderImg}`} alt="favcyLogo" src={favcyLogo2} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className={styles.txtUpperCase}>VERIFY YOUR MOBILE NUMBER</p>
                    <PhoneInput
                        inputExtraProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true
                        }}
                        country={'in'}
                        inputClass={styles.inputClass}
                        buttonClass={styles.btn}
                        containerClass={styles.phoneInputContainer}
                        dropdownStyle={{ color: "black", border: "none" }}
                        placeholder="Enter phone number"
                        value={phone}
                        onChange={setPhone} />

                    <button className={styles.LoginBtnBlue} onClick={() => { handleSendingOtp() }}>SEND OTP</button>

                    <p className={styles.ModalTxt} style={{ fontSize: 9 }}>By tapping "Send OTP" above, Favcy will send you a One Time Password (OTP) to confirm your number. Enter the received OTP on the next screen to Login into your account.</p>



                </Modal.Body>
            </Modal>
        );
    }



    function MyVerticallyCenteredModal2({ handleloginwithotp, ...props }) {
        const [phone, setPhone] = useState(0);
        const [otp, setOtp] = useState("");

        useEffect(() => {
            setPhone(`+91${mobile}`)
        }, [mobile])


        const handleOtpSubmit = () => {
            let obj = {
                mobile: phone.slice(3),
                country_code: parseInt(91),
                guest_token: gToken,
                otp: otp,
            }
            console.log(obj, "otp submit")
            handleloginwithotp(obj)
            props.onHide();
        }


        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className={styles.modalHeaderContainer}>
                    <Modal.Title className={`${styles.ModalHeader}`} id="contained-modal-title-vcenter">
                        <img className={`${styles.modalHeaderImg}`} alt="favcyLogo" src={favcyLogo2} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className={styles.txtUpperCase}>VERIFY YOUR MOBILE NUMBER</p>
                    <PhoneInput
                        inputExtraProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true
                        }}
                        country={'in'}
                        inputClass={styles.inputClass}
                        buttonClass={styles.btn}
                        containerClass={styles.phoneInputContainer}
                        dropdownStyle={{ color: "black", border: "none" }}
                        placeholder="Enter phone number"
                        value={phone}
                        onChange={setPhone} />

                    <div className={styles.ModalTxt} style={{ fontSize: 12 }}>Please enter the OTP you have received on your
                        mobile no. Edit phone no.
                    </div>
                    {/* <div style={{ textDecoration: "underline", color: "#0698EC", cursor: "pointer" }}> */}

                    <input type="text" onChange={(e) => setOtp(e.target.value)} placeholder="ENTER OTP" className={styles.ModalTextInput} />


                    <button className={styles.LoginBtnBlue} onClick={() => { handleOtpSubmit() }}>LOGIN</button>
                </Modal.Body>
            </Modal>
        );
    }






    return (
        <>
            <div className={styles.mainContainer}></div>
            <Navbar style={{ padding: 0, margin: 0, width: "100%", position: "sticky", top: 0, zIndex: 150 }} className="col-12 bg-secondary" collapseOnSelect expand="lg" bg="light" variant="light">
                <Container className="col-lg-11 navbarContainer">
                    <Navbar.Brand href="#home" ><img src={logo} alt="Logo" /></Navbar.Brand>
                    <button className={`${styles.LoginBtnMobile}`} onClick={() => setModalShow(true)}>Login</button>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ color: "#FF6F96", borderColor: "#FF6F96", borderRadius: 50, borderWidth: 2, height: 45, width: 45, margin: 0, padding: 0 }} />
                    <Navbar.Collapse id="responsive-navbar-nav" style={{ zIndex: 100 }}>
                        <Nav className="ml-auto bg-light px-2">
                            <Nav.Link onClick={() => HowItWorksRef.current.scrollIntoView()}>How It Works</Nav.Link >
                        </Nav>
                    </Navbar.Collapse>

                    <button className={`${styles.LoginBtn}`} onClick={() => setModalShow(true)}>Login</button>

                </Container>
            </Navbar>

            <div className={`${styles.HompageBgImage}`}>
                <div className="row" style={{ margin: 0, padding: 0 }}>
                    <div className="col-lg-6 col-12">

                        <div className="pageHeaading">
                            Welcome to

                            CompassTot!
                        </div>

                        <div className="pageSubHeadings">
                            This is your central hub where you will find all  the features that you need to efficiently  manage your CompassTot.
                        </div>

                        <button className="pinkBtn">Admin Login</button>

                    </div>
                    <div className="col-lg-6 col-12">
                        <div className={`${styles.videoContainer}`}>
                            <div className={`${styles.introVideoStyle} introVideo`}>
                                <div className="videoOverlay">
                                    <img src={videoPlayBtn} alt="play" style={{ marginBottom: 15 }} />
                                </div>
                                <video controls={false} src={video} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <section className={styles.howItWorksContainer} style={{ margin: 0, padding: 0 }} ref={HowItWorksRef}>
                <div className="faqHeading">
                    <span style={{ color: "#32355D", marginRight: 5 }}>How it works</span>
                </div>


                <div className={`${styles.AccordianContainer} accordion`} id="accordionPanelsStayOpenExample">



                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                1. Learn the Basics
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                            <div className="accordion-body">
                                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>


                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                2. Personalise Your Storefront
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                            <div className="accordion-body">



                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                                            A. Customise Your Store Details
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseFour" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFour">
                                        <div className="accordion-body">
                                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>


                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                                            B. Change Your Store’s Banner
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseFive" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingFive">
                                        <div className="accordion-body">
                                            <div className={`${styles.HowItWorksVideoContainer}`}>
                                                <video className={`${styles.VideoAccordian}`} controls={false} src={video} />
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                                            C. Add Images and Videos
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseSix" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSix">
                                        <div className="accordion-body">
                                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingSeven">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                                            D. Enable Live Streaming
                                        </button>
                                    </h2>
                                    <div id="panelsStayOpen-collapseSeven" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingSeven">
                                        <div className="accordion-body">
                                            <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingNine">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseNine" aria-expanded="false" aria-controls="panelsStayOpen-collapseNine">
                                3. For a Consistent Palate Experience
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseNine" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingNine">
                            <div className="accordion-body">
                                <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>


                </div>

            </section>






            <div className="footerContainer">
                <div className="row bg-dark d-flex justify-content-center" style={{ margin: 0, padding: 0 }}>
                    <div className="col-lg-10 col-11">
                        <div className="row">

                            <div className="col-lg-3 col-12 text-white">

                                <img src={favcyLogo} alt="favcyLogo" className="logo" />

                                <div className="description">
                                    CompassTot is hosted on Favcy, 'The No Download' Engagement OS!
                                </div>

                                <b>Address:</b>

                                <p>4th Floor, The Circle
                                    Huda City Center Metro Station,
                                    Sector 29,
                                    Gurugram, Haryana 122002
                                </p>


                                <p className="details"><b>Phone:</b> +91-9311368780</p>

                                <p className="details"><b>Email:</b> palate@market.com</p>
                            </div>
                            <div className="col-lg-3 col-12 text-white mt-lg-5 pt-lg-3 ">

                                <div className="goToHeading">GO TO</div>
                                <ul className="footerList" style={{ margin: 0, paddingLeft: 15 }}>
                                    <li onClick={() => HowItWorksRef.current.scrollIntoView()} className="listItem">How it works</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center col-12 text-white lastColumn">
                                <div className="row">

                                    <div className="col-6  mb-4 my-lg-0 d-flex align-items-center justify-content-center">
                                        <img src={linkedIn} alt="linkedIn" style={{ height: 50, width: 50 }} />
                                        <div className="ml-2">
                                            Like us
                                            on Linkedin
                                        </div>
                                    </div>

                                    <div className="col-6 mb-4 my-lg-0 d-flex align-items-center justify-content-center">
                                        <img src={facebook} alt="facebook" style={{ height: 50, width: 50 }} />
                                        <div className="ml-2">
                                            Like us
                                            on Facebook
                                        </div>

                                    </div>
                                    <div className="col-6 mb-4 my-lg-0 d-flex align-items-center justify-content-center">
                                        <img src={twitter} alt="twitter" style={{ height: 50, width: 50 }} />
                                        <div className="ml-2">
                                            Like us
                                            on Twitter
                                        </div>
                                    </div>


                                    <div className="col-6 mb-4 my-lg-0 d-flex align-items-center justify-content-center">
                                        <img src={youtube} alt="youtube" style={{ height: 50, width: 50 }} />
                                        <div className="ml-2">
                                            Like us
                                            on Youtube
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center copyRight text-white">
                                Copyrights © 2021 All Rights Reserved by CompassToT
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col-12 d-flex justify-content-center copyRight text-white">
                                Terms of Use / Privacy Policy
                            </div>

                        </div>
                    </div>
                </div>
            </div >


            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

            <MyVerticallyCenteredModal1
                show={modalShowSendOtp}
                handleotp={getOtp}
                onHide={() => setModalShowSendOtp(false)}
            />

            <MyVerticallyCenteredModal2
                show={modalShowVerifyOtp}
                handleloginwithotp={loginAdmin}
                onHide={() => setModalShowVerifyOtp(false)}
            />
        </>

    )
}

export default AdminDashboard
