import React, { useEffect, useLayoutEffect, useState } from 'react';
import DashboardFooter from '../../Dashboard/DashboardFooter/DashboardFooter';
import back from '../../../assets/img/back.png'
import arrowLeft from '../../../assets/img/arrowLeft.svg'
import Loader from "react-loader-spinner";
import Swal from 'sweetalert2';

import styles from './Timetable.module.css'
import { useHistory } from 'react-router-dom';
import { getActivityByDay, getActivityByDayActivityId } from '../../../Services/Activity';
export default function Timetable() {

    const [SelectedSkill, setSelectedSkill] = useState("");
    // console.log(state, "state")
    const history = useHistory()
    const [days, setDays] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [daysArr, setDaysArr] = useState([]);
    const [startDay, setStartDay] = useState(1);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [endDay, setEndDay] = useState(windowWidth > 600 ? 10 : 5);
    const [selectedDay, setSelectedDay] = useState(1);
    const [activityObj, setActivityObj] = useState({});
    const [displayActivityArr, setDisplayActivityArr] = useState([]);
    const isSelected = (day) => {
        return day === selectedDay
    }



    const nextDaySet = () => {
        if (windowWidth > 600) {
            if (currentPage + 1 < totalPages) {
                setCurrentPage(previousValue => previousValue + 1)
                if (endDay + 10 < days) {
                    setEndDay(endDay + 10)
                    setStartDay(endDay + 1)
                }
                else {
                    setStartDay(days - 9)
                    setEndDay(days)
                }
            }
        }
        else {
            if (currentPage + 1 < totalPages) {
                setCurrentPage(previousValue => previousValue + 1)
                if (endDay + 5 < days) {
                    setEndDay(endDay + 5)
                    setStartDay(endDay + 1)
                }
                else {
                    setStartDay(days - 4)
                    setEndDay(days)
                }
            }
        }


    }


    const PreviousDaySet = () => {
        if (windowWidth > 600) {
            if (currentPage !== 0) {
                setCurrentPage(previousValue => previousValue - 1)
                if (startDay - 10 > 0) {
                    setEndDay(endDay - 10)
                    setStartDay(startDay - 10)
                }
                else {
                    setStartDay(0)
                    setEndDay(10)
                }
            }
        }
        else {
            if (currentPage !== 0) {
                setCurrentPage(previousValue => previousValue - 1)
                if (startDay - 5 > 0) {
                    setEndDay(endDay - 5)
                    setStartDay(startDay - 5)
                }
                else {
                    setStartDay(0)
                    setEndDay(10)
                }
            }
        }
    }



    const CurrentDaySet = (today) => {
        let tempWindowWidth = window.innerWidth
        if (today == 0) {
            setStartDay(1)
            setEndDay(5)
            setCurrentPage(0)
        }
        else {
            if (tempWindowWidth > 600) {
                let val = Math.ceil(today % 5)
                if (val == 0) {
                    console.log(Math.ceil(today/10))
                    setStartDay(Math.floor(today / 10) * 10 -9)
                    setEndDay(Math.ceil(today / 10) * 10)
                }
                else{
                    setStartDay(Math.floor(today / 10) * 10 + 1)
                    setEndDay(Math.ceil(today / 10) * 10)
                    
                }
                setCurrentPage(Math.ceil(today / 10) - 1)
            }
            else {
                let val = Math.ceil(today % 5)
                if (val == 0) {
                    setStartDay((Math.floor(today / 5) * 5) - 4)
                    setEndDay((Math.ceil(today / 5) * 5))
                }
                else {
                    setStartDay((Math.floor(today / 5) * 5) + 1)
                    setEndDay(Math.ceil(today / 5) * 5)
                }
                setCurrentPage(Math.ceil(today / 5) - 1)
            }
        }
    }


    const generateArr = (array1) => {
        let tempToday = localStorage.getItem('today')
        let arr = [];
        for (let i = 1; i <= array1; i++) {
            arr.push({ day: i })
        }
        setDaysArr(arr);
        if (windowWidth > 600) {
            setTotalPages(array1 / 10);
            CurrentDaySet(JSON.parse(tempToday) + 1)
        }
        else {
            setTotalPages(array1 / 5);
            CurrentDaySet(JSON.parse(tempToday) + 1)
        }
    }




    const getActivityArr = async () => {
        try {
            setLoading(true)
            let res = await getActivityByDay()
            if (res.data.data) {
                setActivityObj(res.data.data);
                generateArr(Object.keys(res.data.data)?.length)
                setDays(Object.keys(res.data.data)?.length)
                let today = localStorage.getItem("today")
                handleDateSelection(parseInt(today) + 1)
                setLoading(false)
            }
        }
        catch (err) {
            console.log(JSON.stringify(err?.response), null, 2);
            if (err?.response?.data?.message) {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.log(err.response)
                // alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)

            }
        }
    }




    useEffect(() => {
        getActivityArr()
        let skill = localStorage.getItem('SelectedSkill')
        setSelectedSkill(skill)

    }, [])


    const handleDateSelection = async (day) => {

        setLoading(true)
        setDisplayActivityArr([])
        setSelectedDay(day)
        let tempFinalArr = [];
        // if (activityObj[day].length > 0) {

        // for (const el of activityObj[day]) {
        try {
            console.log(day, "as")
            let res = await getActivityByDayActivityId(day)
            if (res.data.status == 200 || res.data.status == 304) {
                setLoading(false)
                let tempArr = [].concat.apply([], res.data.data)
                console.log(tempArr)
                for (let el of tempArr) {
                    for (let ele of el.levels) {
                        let obj = {
                            levelObj: { ...ele },
                            ...el,
                        }
                        tempFinalArr.push(obj)
                    }
                }
                console.log(tempFinalArr)
                setDisplayActivityArr([...tempFinalArr])
            }
            // console.log(res.data.data)
            // if (res.data.status == 200 || res.data.status == 304) {
            //     setLoading(false)
            //     // console.log(res.data.data.milestone.skill.name)
            //     let activityObj = res.data.data
            //     if (activityObj.levels.length > 0) {
            //         activityObj.levels.forEach(ele => {
            //             ele.activityName = activityObj.name
            //             ele.skillName = activityObj.milestone.skill.name
            //             ele.activityId = activityObj.id
            //             tempArr.push(ele)
            //         });
            //     }
            // }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message)
                setLoading(false)
            }
            else {
                console.error(err.response)
                setLoading(false)
            }
        }
        // setDisplayActivityArr([...tempArr])
        // }
        // }
        // else {
        // setLoading(false)
        // }


    }



    useLayoutEffect(() => {
        const updateWindowWidth = () => {
            console.log(window.innerWidth)
            setWindowWidth(window.innerWidth)
        }
        window.addEventListener('resize', updateWindowWidth);
        updateWindowWidth();
        return () => {
            window.removeEventListener('resize', updateWindowWidth)
        }
    }, []);



    const handleRedirect = () => {

        let path = "/Dashboard";
        history.push(path)

    }


    const handleActivityRedirect = (id, obj) => {
        let path = `/Activity-Detail/${id}`;
        history.push(path)
        localStorage.setItem("SelectedActivity", obj)
    }




    return (
        <>
            <div className={styles.Header}>
                <img onClick={() => handleRedirect()} className={styles.FilterIcon} src={back} />
                <span className={styles.headerHeading}>{SelectedSkill}</span>
            </div>


            <div className={styles.activityTimeTable}>Activity TIMETABLE</div>

            <div className={styles.flexRow} style={{ justifyContent: "center" }}>
                <img onClick={() => PreviousDaySet()} src={arrowLeft} alt="" />
                <div className={styles.daysRangeTxt}>
                    {startDay}-{endDay}
                </div>
                <img onClick={() => nextDaySet()} style={{ transform: `rotateY(180deg)` }} src={arrowLeft} alt="" />
            </div>

            <div className="container-fluid">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-10 col-11  d-flex justify-content-center">


                        <div className={`${styles.CalenderRow} row`} >
                            <div className={`${styles.CalenderInnerRow}`} style={{ justifyContent: "space-between", transform: `translateX(-${(windowWidth < 600 ? 80 : 70) * currentPage}vw)` }}>

                                {
                                    daysArr && daysArr.map((el, index) => {
                                        return (
                                            <div key={index} className={styles.dayItemContainer}>
                                                <div onClick={() => handleDateSelection(el.day)} className={styles.dayItem} style={{ backgroundColor: isSelected(el.day) ? "#ECF7FF" : "white" }}>
                                                    <div className={styles.dayItemTxt} style={{ color: isSelected(el.day) ? "#FF6F96" : "black" }}>
                                                        DAY
                                                    </div>
                                                    <div className={styles.dayItemTxt} style={{ color: isSelected(el.day) ? "#FF6F96" : "black" }}>
                                                        {el.day}
                                                    </div>
                                                    {
                                                        isSelected(el.day) &&
                                                        <div className={styles.smallCircle}></div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                        <div className={styles.topLeftImage}></div>
                        <div className={styles.topRightImage}></div>
                    <div className={`${styles.contentContainer} col-12`}>
                        <div className={styles.bottomImage}></div>

                        <div style={{ marginBottom: 50, alignSelf: "center" }}>
                            {
                                loading ?
                                    <>
                                        <div className="row mt-5 justify-content-center d-flex">
                                            <div className="mt-5" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Loader
                                                    visible={true}
                                                    type="TailSpin"
                                                    color="#FF6F96"
                                                    height={100}
                                                    width={100}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>

                                        {
                                            displayActivityArr.length > 0 ?
                                                displayActivityArr && displayActivityArr.map((el, index) => {
                                                    return (
                                                        <div key={index} className={styles.activityCard} style={{ borderLeft: `solid 5px ${el.milestone.skill.border_color}` }}>
                                                            <p className={styles.activityCardHeading}>{el?.milestone?.skill?.name} : Day {el?.day}</p>
                                                            <div className={styles.activityCardTxtBold}>
                                                                {el?.name}
                                                            </div>
                                                            <div className={[styles.activityCardTxt]}>
                                                                {
                                                                    el?.extra &&
                                                                    <>
                                                                        <span className={styles.activityCardTxtBold}>Requirements : </span>  {el?.extra}
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className={styles.activityCardTxt}>
                                                                <span className={styles.activityCardTxtBold}>{el?.levelObj?.s_no}</span> : {el?.levelObj?.name}
                                                            </div>
                                                            {
                                                                el?.levelObj?.complete_status == true ?
                                                                    <button className={styles.bluebutton}>Completed</button>
                                                                    :
                                                                    <button onClick={() => handleActivityRedirect(el.levelObj.id, el.levelObj)} className={styles.pinkbutton}>Go to Activity</button>
                                                            }
                                                        </div>
                                                    )
                                                })
                                                :

                                                <>
                                                    <div className={styles.activityCard} style={{ borderLeft: "solid 5px #4985B2" }}>
                                                        <p className={styles.activityCardHeading}>No Data Found for this day</p>
                                                    </div>
                                                </>
                                        }
                                    </>
                            }
                        </div>

                    </div>
                </div>
            </div>
            <DashboardFooter />
        </>
    )
}
