import React, { useState, useLayoutEffect, useEffect, useContext } from 'react'

import { AuthContext, jwtContext } from '../../App';
////////css
import "./QuestionAnswers.css"
import "bootstrap/dist/css/bootstrap.min.css";

///////bootstrap 
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { url } from '../../Services/Url';
////////router
import {
    Link
} from "react-router-dom";
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2'
import favcyLogo2 from "../../assets/img/favcyLogo2.png"
import 'react-phone-input-2/lib/style.css'
///////images
import logo from '../../assets/img/logo.png'
import testDetailIcon from "../../assets/img/testDetailIcon.png"
import checkedQuestionIcon from "../../assets/img/checkedQuestionIcon.png"
import unCheckedQuestionIcon from "../../assets/img/unCheckedQuestionIcon.png"
import testCompletedImage from "../../assets/img/testCompleted.png"
import freePlan from "../../assets/img/freePlan.png"
import paidPlan from "../../assets/img/paidPlan.png"
import registerComplete from "../../assets/img/registerComplete.png"
import chevronDownwithoutBg from "../../assets/img/chevronDownwithoutBg.png"
import { getTests } from '../../Services/Test';
import { getPackages, getFreePackages } from '../../Services/Packages';
import { registerUser, handlePaymentPlan, userLogin, handleOrderPlan, handlePayment, submitTestResult } from '../../Services/User';

import axios from "axios";

import Loader from "react-loader-spinner";
import { useRef } from 'react';



export default function QuestionAnswers() {
    const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
    const [decodedObj, setDecodedObj] = useContext(jwtContext);
    const [checked, setChecked] = useState(0);
    const [userIsAuthorised, setUserIsAuthorised] = useState("");
    const history = useHistory()
    const [childName, setChildName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const sliderElement = React.createRef();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [testCompleted, setTestCompleted] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [questionArr, setquestionArr] = useState([]);
    const [stepcount, setStepcount] = useState(0);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [freePackIsSelected, setFreePackIsSelected] = useState(false);
    const [freePackageArr, setFreePackageArr] = useState([]);
    const [packagesArr, setPackagesArr] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({});
    const [selectedPlan, setSelectedPlan] = useState(0);
    const [gToken, setGToken] = useState("");
    const [loading, setLoading] = useState(false);
    const [gender, setGender] = useState("");
    const [registerObj, setRegisterObj] = useState({});
    const [favcyPaymentResponse, setFavcyPaymentResponse] = useState("");
    const [resultText, setResultText] = useState('');
    const [biologicalAge, setBiologicalAge] = useState("");
    const [yearsAge, setYearsAge] = useState(0);
    const [adminAuthToken, setAdminAuthToken] = useState('');
    const [developmentalAge, setDevelopmentalAge] = useState("");
    const [devAgeVal, setDevAgeVal] = useState(0);
    const [userDataObj, setUserDataObj] = useState({});
    const [decodedJwt, setDecodedJwt] = useContext(jwtContext);
    const [resultArray, setResultArray] = useState([]);
    const testRef = useRef();
    const aboutTestRef = useRef();


    const calcAgeInDigits = (age) => {
        console.log(age)
        console.log(Math.floor(age / 12))
        // age = age - 1
        setYearsAge(Math.floor(age / 12))
        console.log(age % 12, "age % 12 ")
        setBiologicalAge(`${Math.floor(age / 12)} Years & ${age % 12} Month${age % 12 != 1 ? 's' : ''}`)
    }
    const calcDevAgeInDigits = (value) => {
        console.log("age % 12 ")
        setDevAgeVal(value)
        let age = localStorage.getItem("age")
        // age = age - 1;
        age = parseInt(age) + parseInt(value);
        console.log(age % 12, "age % 12 ")
        setDevelopmentalAge(`${Math.floor(age / 12)} Years & ${age % 12} Month${age % 12 != 1 ? 's' : ''}`)
    }

    const getTest = async () => {
        try {
            let age = localStorage.getItem("age")
            console.log(age, "asda")
            calcAgeInDigits(age)
            let obj = {
                age_group_id: age
            }
            let res = await getTests(obj)
            if (res.status === 200 || res.status === 304) {
                console.log(res.data)
                setTotalPages(res.data.length)
                setquestionArr(res.data.map(el => {
                    let obj = {
                        ...el,
                        checked: 2
                    }
                    return obj
                }))
            }
        }
        catch (err) {
            console.error(err)
            alert(err)
        }
    }


    const handleAnswerSet = (index, val) => {
        setquestionArr(prevVal => {
            prevVal[index].checked = val;
            return [...prevVal]
        })
    }


    useEffect(() => {
        let tempObj = localStorage.getItem("obj")
        tempObj = JSON.parse(tempObj)
        setUserDataObj(tempObj)
        if (tempObj?.name) {
            setChildName(tempObj?.child_name)
            setPhone(tempObj?.mobile)
            setEmail(tempObj?.email)
        }
        getTest()

    }, [])




    useLayoutEffect(() => {
        const updateWindowWidth = () => {
            setWindowWidth(window.innerWidth)
        }
        window.addEventListener('resize', updateWindowWidth);
        updateWindowWidth();
        return () => {
            window.removeEventListener('resize', updateWindowWidth)
        }
    }, []);


    const handleNextClick = async () => {

        if ((totalPages - 1) > currentPage) {
            setCurrentPage(prevVal => prevVal + 1)
        }
        else if ((totalPages) > currentPage) {
            let age = localStorage.getItem("age")
            let obj = {
                points: questionArr.map(el => el.checked),
                skills: questionArr.map(el => el.skill),
                question_id: questionArr.map(el => el.id),
                total_questions: totalPages,
                age_group_id: age
            }
            // console.log(obj)
            let { data: res } = await axios.post(`${url}site/submit-free-test`, obj);
            if (res) {
                console.log(res, "result")
                setResultArray(res.data.result_array)
                calcDevAgeInDigits(res.data.value)
                setResultText(res.data.result)
                setTestCompleted(true)
            }

        }
    }

    const handleUserRegister = async (obj) => {
        try {
            setStepcount(1)
            setLoading(true)
            let res = await registerUser(obj);
            if (res.status == 200 || res.status == 201 || res.status == 304) {
                setGToken(res.data.guest_token)
                setRegisterObj(obj)
                setLoading(false)
            }
        }
        catch (err) {
            setStepcount(0)
            // console.log(JSON.stringify(err.response, null, 2))
            if (err?.response?.data) {
                console.error(err?.response?.data);
                let errStr = Object.values(err?.response?.data).flatMap(el => el).reduce((acc, el) => acc + el + ' ', '')
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${errStr}`,
                })
                setLoading(false)
            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)
            }
        }
    }



    const loginUser = async (obj) => {
        try {

            // console.log(obj, "otp object")
            let formData = new FormData()
            formData.append("mobile", parseInt(obj.mobile))
            formData.append("country_code", parseInt(91))
            formData.append("guest_token", obj.guest_token)
            formData.append("otp", parseInt(obj.otp))

            let res = await userLogin(formData)
            if (res.status == 201 || res.status == 200 || res.status == 304) {
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.message}`,
                });
                setAdminAuthToken(res.data.token)

                localStorage.setItem("ADMIN_AUTH_TOKEN", res.data.token);
                localStorage.setItem("FAVCY_AUTH_TOKEN", res.data.auth_token);

                let tempObj = {
                    role: res?.data?.user?.roles[0]?.name
                }

                setDecodedObj(tempObj)
                localStorage.setItem("role", res?.data?.user?.roles[0]?.name);
                setStepcount(2)

            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.log(err)
                alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });

            }
        }
    }

    const handleOrderCreate = async (couponcode) => {
        try {

            let favcyToken = localStorage.getItem("FAVCY_AUTH_TOKEN")
            let obj = {
                coupon_code: couponcode,
                package_id: selectedPackage.id,
                type: selectedPackage.type,
                auth_token: favcyToken,
            }
            let res = await handleOrderPlan(obj, adminAuthToken);
            if (res.status == 200 || res.status == 201 || res.status == 304) {
                if (selectedPackage.type == "free") {
                    setIsAuthorized(true)
                    setModalShow(true)
                    handleRedirectToDash()
                }
                else {
                    localStorage.setItem("orderResponse", JSON.stringify(res.data))
                    history.push("/payments")
                }
            }

        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.log(err)
                alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });

            }
        }
    }


    const handleRedirectToDash = () => {
        let role = localStorage.getItem("role")
        if (role == "admin") {
            let path = `/Admin-Dashboard`;
            history.push(path);
        }
        else if (role == "user") {
            let path = `/Dashboard`;
            history.push(path);
        }
        else if (role == "csm") {
            let path = `/CSM-Manage-Children`;
            history.push(path);
        }
    }


    function MyVerticallyCenteredModal({ handleregisterwithotp, handleOrderCreateFunc, handleOtpEntry, ...props }) {

        const [childNameValue, setChildNameValue] = useState("");

        const [dob, setDob] = useState('');
        const [yourname, setYourname] = useState('');
        const [email, setEmail] = useState("");
        const [mobile, setMobile] = useState('');
        const [responseObj, setResponseObj] = useState("");//////register Response
        const [phone, setPhone] = useState(0);
        const [otp, setOtp] = useState("");
        const [couponCode, setCouponCode] = useState("");




        const handleOtpSubmit = () => {
            let obj = {
                mobile: registerObj.mobile,
                country_code: parseInt(91),
                guest_token: gToken,
                otp: otp,
            }
            // console.log(obj, "otp submit")
            handleOtpEntry(obj)
        }


        const handleInput = async () => {
            try {
                let obj = {
                    email,
                    dob: new Date(dob).toISOString().substring(0, 10),
                    mobile,
                    name: yourname,
                    child_name: childNameValue,
                    package_id: selectedPackage.id,
                    type: freePackIsSelected ? "free" : "paid"
                }
                handleregisterwithotp(obj)
            }
            catch (err) {
                if (err?.response?.data?.message) {
                    console.error(err?.response?.data?.message);
                    Swal.fire({
                        title: 'Error',
                        type: 'error',
                        text: `${err?.response?.data?.message}`,
                    });
                }
                else {
                    console.error(err)
                    Swal.fire({
                        title: 'Error',
                        type: 'error',
                        text: `${err?.message}`,
                    });
                }
            }
        }


        const handleOrderCreateInModal = () => {
            handleOrderCreateFunc(couponCode)
        }


        const checkSelectedPackage = (id) => {
            if (selectedPackage.id == id) {
                return true
            }
            else
                return false
        }


        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="RegisterModal" style={{ height: stepcount === 0 ? 500 : stepcount === 1 ? 250 : 200, width: stepcount === 3 && 400 }}>
                    <div className="RegisterModalInnerBox">
                        {
                            stepcount === 0
                                ?

                                <>
                                    <div className="RegisterModalHeading">Enter Your Child’s Details</div>
                                    <input type="text" onChange={(e) => setChildNameValue(e.target.value)} placeholder="Your Child’s Full Name" className="registerModalTextInput" />
                                    <input onChange={(e) => setDob(e.target.value)} className="registerModalTextInput" type="date" placeholder="Your Child’s Age (DOB) " />
                                    {/* <img alt="chevronDown" style={{ height: 10, width: 10 }} src={chevronDownwithoutBg} /> */}
                                    <input type="text" onChange={(e) => setYourname(e.target.value)} placeholder="Your Name" className="registerModalTextInput" />
                                    <input type="text" onChange={(e) => setMobile(e.target.value)} placeholder="Your Mobile Number" className="registerModalTextInput" />
                                    <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder="Your Email Address" className="registerModalTextInput" />
                                    <button style={{ marginTop: 30 }} onClick={() => { handleInput() }} className="blueBtnRegister">
                                        Next
                                    </button>
                                </>

                                :
                                stepcount === 1 ?
                                    <>
                                        {
                                            loading ?
                                                <>
                                                    <div className="row mt-5 justify-content-center d-flex">
                                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                            <Loader
                                                                visible={true}
                                                                type="TailSpin"
                                                                color="#FF6F96"
                                                                height={100}
                                                                width={100}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <p className="txtUpperCase mt-5">VERIFY YOUR MOBILE NUMBER</p>

                                                    <div className="ModalTxt" style={{ fontSize: 12 }}>Please enter the OTP you have received on your
                                                        mobile no. Edit phone no.
                                                    </div>
                                                    <input type="text" onChange={(e) => setOtp(e.target.value)} placeholder="ENTER OTP" className="ModalTextInput" />

                                                    <button className="LoginBtnBlue" onClick={() => { handleOtpSubmit() }}>LOGIN</button>

                                                </>
                                        }
                                    </>
                                    :
                                    stepcount === 2 ?
                                        <>
                                            <div className="RegisterModalHeading" style={{ fontSize: 17 }}>Select Your Preferred Package</div>

                                            <div className="plancontainer pt-4">
                                                <button onClick={() => setFreePackIsSelected(true)} className={`${freePackIsSelected === true ? "selectedPlan" : "unSelectedPlan"}`}>
                                                    <img alt="freePlan" className="planImage" src={freePlan} />
                                                    FREE PACK
                                                </button>
                                                <button onClick={() => setFreePackIsSelected(false)} className={`${freePackIsSelected === false ? "selectedPlan" : "unSelectedPlan"}`}>
                                                    <img alt="paidPlan" className="planImage" src={paidPlan} />
                                                    PAID PACK
                                                </button>
                                            </div>
                                            <button style={{ marginTop: 50, marginBottom: 30 }} onClick={() => setStepcount(3)} className="blueBtnRegister">
                                                Next
                                            </button>
                                        </>
                                        :
                                        stepcount === 3 ?
                                            <>
                                                {/* <div className="RegisterModalHeading">Choose Your Billing Plan</div> */}
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
                                                    <div className="RegisterModalHeading" style={{ flex: 1 }}>Choose Your Billing Plan</div>
                                                    <div onClick={() => { setStepcount(2) }} style={{ color: "blue", cursor: "pointer", marginTop: 15 }}>
                                                        Change
                                                    </div>
                                                </div>
                                                <div className="planSelectionContainer">

                                                    {

                                                        freePackIsSelected == true ?
                                                            freePackageArr.map(el => {
                                                                return (
                                                                    <div key={el.id} onClick={() => setSelectedPackage(el)} className="planSelectionText" >
                                                                        <div className={`${checkSelectedPackage(el.id) ? "radioContainerSelected" : "radioContainerUnSelected"}`}>
                                                                            <div className={`${checkSelectedPackage(el.id) ? "radioCircleSelected" : "radioCircleUnSelected"}`}></div>
                                                                        </div>
                                                                        {el.name}
                                                                    </div>
                                                                )
                                                            })

                                                            :

                                                            packagesArr.map(el => {
                                                                return (
                                                                    <div key={el.id} onClick={() => setSelectedPackage(el)} className="planSelectionText" >
                                                                        <div className={`${checkSelectedPackage(el.id) ? "radioContainerSelected" : "radioContainerUnSelected"}`}>
                                                                            <div className={`${checkSelectedPackage(el.id) ? "radioCircleSelected" : "radioCircleUnSelected"}`}></div>
                                                                        </div>
                                                                        {el.name}
                                                                    </div>
                                                                )
                                                            })

                                                    }

                                                </div>

                                                <div className="worldImageContainer">
                                                    <div className="worldImageText">Annual Plan</div>
                                                    <div className="worldImageHeading" style={{ textDecorationLine: selectedPackage.type == "free" ? "line-through" : "none" }}>{parseInt(selectedPackage.price / 12)}/month</div>
                                                    <div className="worldImageText">Billed Annually</div>
                                                </div>

                                                <input type="text" onChange={(e) => setCouponCode(e.target.value)} placeholder="Enter Discount Voucher Code" className="registerModalTextInput" style={{ marginTop: 0 }} />

                                                <button style={{ marginTop: 20, marginBottom: 30 }} onClick={() => handleOrderCreateInModal()} className="blueBtnRegister">
                                                    Continue to Payment
                                                </button>
                                            </>
                                            :
                                            stepcount === 4 &&
                                            <>
                                                <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 20, marginBottom: 10, }}>
                                                    <img src={registerComplete} style={{ height: 50, width: 50, marginTop: 10, marginBottom: 10 }} alt="" />
                                                </div>

                                                <div className="registerationcompleted">
                                                    Congratulations!
                                                </div>
                                                <div className="RegisterCompletedSubHeading" style={{ marginBottom: 30 }}>
                                                    Your Child’s CompassTot Profile
                                                    Has Been Setup.
                                                </div>
                                                <div className="RegisterCompletedSubHeading">
                                                    Get Started With Your CompassTot
                                                </div>



                                                <Link to="/Dashboard">
                                                    <button style={{ marginTop: 30, marginBottom: 30 }} className="blueBtnRegister">
                                                        {childNameValue}’s CompassTot
                                                    </button>
                                                </Link>
                                            </>

                        }

                    </div>
                </Modal.Body>

            </Modal>
        );
    }





    const getPaidPackages = async () => {
        try {
            let res = await getPackages()
            if (res.status == 200 || res.status == 201 || res.status == 304) {
                setPackagesArr(res.data)
                setSelectedPackage(res.data[0])
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
            }
        }
    }
    const getFreePack = async () => {
        try {
            let res = await getFreePackages()
            if (res.status == 200 || res.status == 201 || res.status == 304) {
                // console.log(res.data)
                setFreePackageArr(res.data)
                setSelectedPackage(res.data[0])
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
            }
        }
    }

    const handleResultDataSubmit = async () => {
        try {
            setLoading(true)
            let age = localStorage.getItem("age")
            if (gender == "") {
                alert("Please select a gender")
                setLoading(false)
            }
            else if (email == "") {
                alert("Please enter your email")
                setLoading(false)
            }
            else if (childName == "") {
                alert("Please enter your child's name")
                setLoading(false)
            }
            else if (phone.length != 10) {
                alert("Please enter a valid phone number")
                setLoading(false)
            }
            else {
                let obj = {
                    mobile: parseInt(phone),
                    email: email,
                    age: parseInt(age - 1),
                    name: childName,
                    result: devAgeVal,
                    gender: gender,
                    result_array: resultArray
                }
                console.log(obj)
                let res = await submitTestResult(obj)
                if (res.status == 200 || res.status == 201 || res.status == 304) {
                    console.log(res.data, "submite test final result")
                    setLoading(false)
                    let authToken = localStorage.getItem("ADMIN_AUTH_TOKEN");
                    if (authToken && authToken != "") {
                        setIsAuthorized(true)
                        handleRedirectToDash()
                    }
                    else {
                        setModalShow(true)
                    }
                }
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)
            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)
            }
        }
    }


    const handleLogout = () => {
        localStorage.removeItem('FAVCY_AUTH_TOKEN')
        localStorage.removeItem('userObj')
        localStorage.removeItem('ADMIN_AUTH_TOKEN')
        localStorage.removeItem('role');
        localStorage.removeItem('obj');
        setIsAuthorized(false)
        setDecodedJwt({ role: '' })
        history.push('/')
    }





    useEffect(() => {
        let authToken = localStorage.getItem("ADMIN_AUTH_TOKEN");
        let userObj = localStorage.getItem("userObj");
        userObj = JSON.parse(userObj)
        if (authToken && authToken != "") {
            setUserIsAuthorised(authToken)
            Swal.fire({
                title: 'Please Submit Test to Proceed',
                type: 'info',
                text: ``,
            }).then(() => {
                testRef.current.scrollIntoView()
                // console.log(result)
            })
            setIsLoggedIn(true)
        }
        else {
            setIsLoggedIn(false)
        }
        if (userObj && userObj.name) {
            setChildName(userObj?.child_name);
            setPhone(userObj?.mobile);
            setEmail(userObj?.email);
        }
        getPaidPackages()
        getFreePack()
    }, [])




    return (
        <>
            <Navbar style={{ padding: 0, margin: 0, width: "100%", position: "sticky", top: 0, }} className="col-12 bg-light" collapseOnSelect expand="lg" bg="light" variant="light">
                <Container className="col-lg-11 navbarContainer">
                    <Navbar.Brand ><img onClick={() => history.push("/")} src={logo} alt="" /></Navbar.Brand>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto bg-light">
                            <Nav.Link className="link" onClick={() => aboutTestRef.current.scrollIntoView()}>About the Test</Nav.Link>
                            <Nav.Link className="link" onClick={() => testRef.current.scrollIntoView()}>Test</Nav.Link>
                            {
                                isLoggedIn == true &&
                                <Nav.Link className="link" onClick={() => handleLogout()}>Logout</Nav.Link>
                            }
                        </Nav>
                    </Navbar.Collapse>
                    {/* <Navbar.Brand className="loginBtn">Login</Navbar.Brand> */}
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ color: "#FF6F96", borderColor: "#FF6F96", borderRadius: 50, borderWidth: 2, height: 45, width: 45, margin: 0, padding: 0 }} />
                </Container>
            </Navbar>
            {

                loading ?
                    <>
                        <div className="row mt-5 justify-content-center d-flex">
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Loader
                                    visible={true}
                                    type="TailSpin"
                                    color="#FF6F96"
                                    height={100}
                                    width={100}
                                />
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <section className="mainContainer">
                            <div className="pageHeading">Discover Together With Us,Your Compass!</div>
                            <div className="questionpageSubHeading">Take the free test with us to know more about how your child is developing at the moment, and how you can get the best for your child accordingly.</div>
                            <button className="blueBtn" onClick={() => testRef.current.scrollIntoView()}>Start free test</button>
                        </section>

                        <section className="testContainer" ref={aboutTestRef}>
                            <div className="testDetails">
                                <img src={testDetailIcon} alt="" />
                                <div className="testDetailsText">
                                    Take the free test with us to know more about how your child is developing at the moment, and how you can get the best for your child accordingly.
                                </div>
                            </div>
                            <div ref={testRef} className={`${testCompleted === false ? "testVisible" : "testInVisible"}`}>
                                <div className={`testQuestionContainer`}>
                                    <div className="testQuestionHeading">Test</div>
                                    <div className="testQuestionCount">Question ({currentPage + 1}/{totalPages})</div>
                                    <div className="horizontalRow"></div>
                                    <div className="questionSliderContainer">
                                        <div ref={sliderElement} id="sliderContainer" style={{ transform: `translateX(-${(windowWidth > 600 ? 64 : 85) * currentPage}vw)` }}>
                                            {questionArr.length > 0 ?
                                                <>
                                                    {
                                                        questionArr.map((el, i) => {
                                                            return (
                                                                <div key={el.id} className="innerQuestionContainer">

                                                                    <div className="questionText">
                                                                        {el.question}
                                                                    </div>

                                                                    <div className="questionOptionRow" style={{ marginTop: 20 }}>
                                                                        <button className="checkBtn" onClick={() => handleAnswerSet(i, 2)}>
                                                                            {
                                                                                el.checked === 2 ?

                                                                                    <img className="checkIcon" src={checkedQuestionIcon} alt="" />
                                                                                    :
                                                                                    <img className="checkIcon" src={unCheckedQuestionIcon} alt="" />
                                                                            }
                                                                            Yes
                                                                        </button>
                                                                    </div>

                                                                    <div className="questionOptionRow">
                                                                        <button className="checkBtn" onClick={() => handleAnswerSet(i, 0)}>
                                                                            {
                                                                                el.checked === 0 ?
                                                                                    <img className="checkIcon" src={checkedQuestionIcon} alt="" />
                                                                                    :
                                                                                    <img className="checkIcon" src={unCheckedQuestionIcon} alt="" />
                                                                            }
                                                                            No
                                                                        </button>
                                                                    </div>

                                                                    <div className="questionOptionRow">
                                                                        <button className="checkBtn" onClick={() => handleAnswerSet(i, 1)}>
                                                                            {
                                                                                el.checked === 1 ?
                                                                                    <img className="checkIcon" src={checkedQuestionIcon} alt="" />
                                                                                    :
                                                                                    <img className="checkIcon" src={unCheckedQuestionIcon} alt="" />
                                                                            }
                                                                            Partially Yes
                                                                        </button>
                                                                    </div>

                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className="testResultHeading">No Questions available for this age.</div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {
                                        questionArr.length > 0 &&
                                        <button onClick={() => handleNextClick()} className="blueBtnNext">
                                            Next
                                        </button>
                                    }
                                </div>
                            </div>
                            {
                                testCompleted === true &&
                                <div className={`${testCompleted === true ? "testVisible" : "testInVisible"}`}>
                                    <div className={`testQuestionContainer`}>
                                        <img alt="testCompleted" src={testCompletedImage} className="testCompletedImage" />
                                        <div className="testResultHeading">You Have Successfully Completed
                                            the Test...!</div>
                                        <div className="testResultHeading">Your Child Seems to be {resultText}</div>
                                        {
                                            devAgeVal >= 1 ?
                                                <div className="TestResultDescription">
                                                    <b>Age as per Date of Birth</b> : {biologicalAge}
                                                    <br />
                                                    <b>Developmental Age</b> : {developmentalAge}
                                                    <br />
                                                    <br />
                                                    Dear Parent,
                                                    <br />
                                                    <br />
                                                    Thank you for taking the self-assessment test…!
                                                    Based on your answers, CompassTot is happy to report that as at {biologicalAge},
                                                    your Child seems to be ahead of the Expected Normal Overall Developmental Curve by {Math.abs(devAgeVal)} Months...!!
                                                    <br />
                                                    <br />
                                                    That means, your Child’s Age is likely to be {developmentalAge}.
                                                    Yippee…!!!
                                                    <br />
                                                    <br />
                                                    Let us make sure that your Child continues to remain ahead of the Expected Normal
                                                    Overall Developmental Curve going forward too and does not lose his/her lead because
                                                    of missing out on doing right things, at the right time & in the right way. And while we are
                                                    at it, let us also try to improve the extent and quality of your Child’s developmental lead,
                                                    without putting any undue pressure on him/her.
                                                    <br />
                                                    <br />
                                                    How do we do that…? We do that with the help of neuroscience based, developmentally
                                                    appropriate, play-based fun activities, tailored to your Child’s unique & evolving growth
                                                    path. All our activities are designed in ways Children and Parents enjoy it, which
                                                    improves quality of time you spend with your Child & builds an even stronger bond
                                                    between you. This in effect, helps in improving learning outcomes.
                                                    <br />
                                                    <br />
                                                    Simply put, we assist you with play-based fun activities that will be specifically curated
                                                    for your Child to make sure that you don’t miss out on giving him/her the right exposure,
                                                    at the right time & in the right way, to aid his/her skill development & achievement of
                                                    important milestones in various domains. Furthermore, we focus not only on the pace of developmental progression (how much time a child takes to learn something and how many milestones a child achieves by a particular age / during a particular period), but also on the quality of your Child’s developmental progression (how well a child learns something and how well the learning is reinforced to build a stronger foundation) – as both play an important role in determining whether the resulting neural connection that form in the brain are strong or weak.
                                                    <br />
                                                    <br />
                                                    If you would like to get this test report emailed, including a detailed report about your Child’s developmental age in different domains of development i.e. Gross Motor, Fine Motor & Cognitive, please share your
                                                    contact details and we will email the report to you immediately <b>(yes, it is completely
                                                        FREE…!).</b>
                                                    <br />
                                                    <br />
                                                    You can also start your <b>1-MONTH FREE TRIAL</b> immediately after requesting for your
                                                    report <b>(no, we do not ask for your credit card details while starting your Free Trial…!)</b>. All
                                                    we request you is to make the most of your Free Trial, so that it’ll benefit most to your
                                                    Child.
                                                    <br />
                                                    <br />
                                                    <i>(Please note, if you do not register for your Free Trial immediately after requesting for
                                                        your report, you may need to give this test again, for us to be able to curate activities for
                                                        your Child according to his/her current developmental stage.)</i>
                                                </div>
                                                :
                                                devAgeVal < 1 && devAgeVal > -1 ?
                                                    <div className="TestResultDescription">
                                                        <b>Age as per Date of Birth</b> : {biologicalAge}
                                                        <br />
                                                        <b>Developmental Age</b>: {developmentalAge}
                                                        <br />
                                                        <br />
                                                        Dear Parent,
                                                        <br />
                                                        <br />
                                                        Thank you for taking the self-assessment test…!
                                                        <br />
                                                        <br />
                                                        Based on your answers, CompassTot is happy to report that as at {biologicalAge},
                                                        your Child seems to be on the Expected Normal Overall Developmental Curve…!!
                                                        (Note)
                                                        <br />
                                                        <br />
                                                        That means, your Child’s Overall Developmental Age is also likely to be {developmentalAge}. Yippee…!!!
                                                        <br />
                                                        <br />
                                                        Let us make sure that your Child continues to remain on the Expected Normal Overall
                                                        Developmental Curve going forward too and does not fall behind because of missing out
                                                        on doing right things, at the right time & in the right way. And while we are at it, let us
                                                        also try to give your Child a slight lead and help him/her get ahead of the Expected
                                                        Normal Overall Developmental Curve, by improving the extent and quality of your
                                                        Child’s developmental progression, without putting any undue pressure on him/her.
                                                        <br />
                                                        <br />
                                                        How do we do that…? We do that with the help of neuroscience based, developmentally
                                                        appropriate, play-based fun activities, tailored to your Child’s unique & evolving growth
                                                        path. All our activities are designed in ways Children and Parents enjoy it, which
                                                        improves quality of time you spend with your Child & builds an even stronger bond
                                                        between you. This in effect, helps in improving learning outcomes.
                                                        <br />
                                                        <br />
                                                        Simply put, we assist you with play-based fun activities that will be specifically curated
                                                        for your Child to make sure that you don’t miss out on giving him/her the right exposure,
                                                        at the right time & in the right way, to aid his/her skill development & achievement of
                                                        important milestones in various domains. Furthermore, we focus not only on the pace of developmental progression (how much time a child takes to learn something and how many milestones a child achieves by a particular age / during a particular period), but also on the quality of your Child’s developmental progression (how well a child learns something and how well the learning is reinforced to build a stronger foundation) – as both play an important role in determining whether the resulting neural connection that form in the brain are strong or weak.
                                                        <br />
                                                        <br />
                                                        If you would like to get this test report emailed, including a detailed report about your Child’s developmental age in different domains of development i.e. Gross Motor, Fine Motor & Cognitive, please share your
                                                        contact details and we will email the report to you immediately <b>
                                                            (yes, it is completely
                                                            FREE…!).
                                                        </b>
                                                        <br />
                                                        <br />
                                                        You can also start your <b>1-MONTH FREE TRIAL</b> immediately after requesting for your
                                                        report <b>(no, we do not ask for your credit card details while starting your Free Trial…!)</b>. All
                                                        we request you is to make the most of your Free Trial, so that it’ll benefit most to your
                                                        Child.
                                                        <br />
                                                        <br />
                                                        <i>(Please note, if you do not register for your Free Trial immediately after requesting for
                                                            your report, you may need to give this test again, for us to be able to curate activities for
                                                            your Child according to his/her current developmental stage.)</i>
                                                        <br />
                                                        <br />

                                                    </div>
                                                    :
                                                    devAgeVal <= -1 &&
                                                    <div className="TestResultDescription">
                                                        <b>Age as per Date of Birth</b> : {biologicalAge}
                                                        <br />
                                                        <b>Developmental Age</b>: {developmentalAge}
                                                        <br />
                                                        <br />
                                                        Dear Parent,
                                                        Thank you for taking the self-assessment test…!
                                                        <br />
                                                        <br />
                                                        Based on your answers, CompassTot is concerned to report that as at {biologicalAge}
                                                        Months, your Child seems to be behind on the Expected Normal Overall Developmental
                                                        Curve by {Math.abs(devAgeVal)} Months.
                                                        <br />
                                                        <br />
                                                        That means, your Child’s Age is likely to be {developmentalAge}.
                                                        So, we need to buckle up.
                                                        <br />
                                                        <br />
                                                        First things first, let’s not panic and start putting your Child under pressure, as Children
                                                        do not learn at a constant pace all the time. So, this lag could be because of that or
                                                        maybe simply because of not being exposed to developmentally appropriate activities
                                                        due to which your Child never had an opportunity to learn some skills. In any case, we
                                                        can address those issues going forward. So, let us try our best to make sure that your
                                                        Child recovers from the lag & gets back on the Expected Normal Overall Developmental
                                                        Curve at earliest possible, without putting any undue pressure on him/her. Once we
                                                        achieve that, our next priority should be to make sure that your Child continues to
                                                        remain on the Expected Normal Overall Developmental Curve going forward too and
                                                        does not fall behind because of missing out on doing right things, at the right time & in
                                                        the right way.
                                                        <br />
                                                        <br />
                                                        How do we do that…? We do that with the help of neuroscience based, developmentally
                                                        appropriate, play-based fun activities, tailored to your Child’s unique & evolving growth
                                                        path. All our activities are designed in ways Children and Parents enjoy it, which
                                                        improves quality of time you spend with your Child & builds an even stronger bond
                                                        between you. This in effect, helps in improving learning outcomes.
                                                        <br />
                                                        <br />
                                                        Simply put, we assist you with play-based fun activities that will be specifically curated
                                                        for your Child to make sure that you don’t miss out on giving him/her the right exposure,
                                                        at the right time & in the right way, to aid his/her skill development & achievement of
                                                        important milestones in various domains. Furthermore, we focus not only on the pace of developmental progression (how much time a child takes to learn something and how many milestones a child achieves by a particular age / during a particular period), but also on the quality of your Child’s developmental progression (how well a child learns something and how well the learning is reinforced to build a stronger foundation) – as both play an important role in determining whether the resulting neural connection that form in the brain are strong or weak.
                                                        <br />
                                                        <br />
                                                        If you would like to get this test report emailed, including a detailed report about your Child’s developmental age in different domains of development i.e. Gross Motor, Fine Motor & Cognitive, please share your
                                                        contact details and we will email the report to you immediately <b>
                                                            (yes, it is completely
                                                            FREE…!).
                                                        </b>
                                                        <br />
                                                        <br />
                                                        You can also start your <b>1-MONTH FREE TRIAL</b> immediately after requesting for your
                                                        report <b>(no, we do not ask for your credit card details while starting your Free Trial…!)</b>. All
                                                        we request you is to make the most of your Free Trial, so that it’ll benefit most to your
                                                        Child.
                                                        <br />
                                                        <br />
                                                        <i>(Please note, if you do not register for your Free Trial immediately after requesting for
                                                            your report, you may need to give this test again, for us to be able to curate activities for
                                                            your Child according to his/her current developmental stage.)</i>
                                                    </div>
                                        }



                                        <div className="TestResultDescription">To get the full interactive report on your
                                            email or mobile number, please enter here:</div>
                                        <select type="text" onChange={(e) => setGender(e.target.value)} value={gender} style={{ height: 50, color: "#FF6F96" }} className="testCompletedTextInput" >
                                            <option value="">Select your child's gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                        <input onChange={(e) => setChildName(e.target.value)} value={childName} type="text" placeholder="Enter your Child's name" className="testCompletedTextInput" />
                                        <input onChange={(e) => setPhone(e.target.value)} value={phone} type="text" placeholder="Enter your mobile number" className="testCompletedTextInput" />
                                        <input onChange={(e) => setEmail(e.target.value)} value={email} type="text" placeholder="Enter your email address" className="testCompletedTextInput" />
                                        <button onClick={() => { handleResultDataSubmit() }} style={{ marginTop: 30, fontSize: 17 }} className="blueBtnNext">
                                            {
                                                userIsAuthorised != ""
                                                    ? "Submit and go to dashboard"
                                                    :

                                                    "Submit and Proceed to Sign Up"
                                            }
                                        </button>
                                    </div>
                                </div>
                            }
                        </section>

                        <MyVerticallyCenteredModal
                            handleregisterwithotp={handleUserRegister}
                            handleOtpEntry={loginUser}
                            handleOrderCreateFunc={handleOrderCreate}
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                        />

                    </>
            }


        </>

    )
}
