import React, { useState } from 'react'
import { useEffect } from 'react';
import { handlePayment } from '../../Services/User';
import Swal from 'sweetalert2';
import {paymentUrl}from '../../Services/Url'

export default function Payments() {

    const [auth_token, setAuth_token] = useState("");
    const [client, setClient] = useState("");
    const [order_id, setOrder_id] = useState("");
    const [success_callback, setSuccess_callback] = useState("");
    const [error_callback, setError_callback] = useState("");
    const [additionalParams, setadditionalParams] = useState("");
    const [favcyPaymentResponse, setFavcyPaymentResponse] = useState("");
    const [redirect, setRedirect] = useState("0");


    const handlePaymentTemp = async () => {
        try {
            let formData = new FormData()
            formData.append("auth_token", auth_token)
            formData.append("client", client)
            formData.append("order_id", order_id)
            formData.append("success_callback", success_callback)
            formData.append("error_callback", error_callback)
            formData.append("additional_params", JSON.stringify(additionalParams))


            let res = await handlePayment(formData);
            if (res.status == 200 || res.status == 201 || res.status == 304) {
                console.log(res, "Pay to favcy")
                setFavcyPaymentResponse(res?.data)
                let element = document.getElementById("paymentGateway").innerHTML = res?.data
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.error(err)
                // alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });

            }
        }
    }



    useEffect(() => {

        let token = localStorage.getItem("FAVCY_AUTH_TOKEN")
        if (token) {
            setAuth_token(token)
        }
        let userToken = localStorage.getItem("ADMIN_AUTH_TOKEN")
        let obj = {
            token: userToken,
            brand_name:"Compasstot"
        }
        setadditionalParams(JSON.stringify(obj));

        setClient("compasstot");

        setRedirect("O")
        let obj2 = localStorage.getItem("orderResponse")
        let tempObj = JSON.parse(obj2)
        console.log(tempObj.order_id, "Object")
        if (tempObj.order_id) {
            setOrder_id(tempObj.order_id)
        }

        setSuccess_callback(`${paymentUrl}payment-success`);
        setError_callback(`${paymentUrl}payment-error`);
        // setSuccess_callback("http://localhost:3000/payment-success");
        // setError_callback("http://localhost:3000/payment-error")

        if (client != "" && order_id != "" && success_callback != "" && error_callback != "" && auth_token != "") {
            let form = document.getElementById("form");
            form.submit();
            console.log(client, order_id, success_callback, error_callback, auth_token, additionalParams)
        }

    }, [client, order_id, success_callback, error_callback, auth_token])


    return (
        <div>
            <form id="form" action="https://staging.favcy.com/payments/pay" method="post">

                <input type="text" class="displayHidden" name="auth_token" value={auth_token} onChange={(e) => setAuth_token(e.target.value)} placeholder="Auth Token" />
                <input type="text" class="displayHidden" name="client" value={client} onChange={(e) => setClient(e.target.value)} placeholder="Client" />
                <input type="text" class="displayHidden" name="order_id" value={`${order_id}`} onChange={(e) => setOrder_id(e.target.value)} placeholder="Order_Id" />
                <input type="text" class="displayHidden" name="success_callback" value={success_callback} onChange={(e) => setSuccess_callback(e.target.value)} placeholder="Success Callback" />
                <input type="text" class="displayHidden" name="error_callback" value={error_callback} onChange={(e) => setError_callback(e.target.value)} placeholder="Error Callback" />
                <input type="text" class="displayHidden" name="redirect" value={redirect} onChange={(e) => setRedirect(e.target.value)} placeholder="Error Callback" />
                <input type="text" class="displayHidden" name="additional_params" value={additionalParams} onChange={(e) => setadditionalParams(e.target.value)}
                    placeholder="Error Callback" />
                <input type="submit" class="displayHidden" placeholder="Submit" />

            </form>

            {/* <button onClick={() => handlePaymentTemp()}>Submit</button> */}
            <div id="paymentGateway">

            </div>
        </div>
    )
}
