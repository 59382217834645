import React, { useEffect, useState } from 'react'


import './DashboardContainer.css'
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

///////images
import dashBoardIcon1 from '../../assets/img/dashBoardIcon1.png'
import dashBoardIcon2 from '../../assets/img/dashBoardIcon2.png'
import chevronDown from '../../assets/img/chevronDown.png'
import Modal from "react-bootstrap/Modal";

/////bootstrap
import Accordion from 'react-bootstrap/Accordion'

import Swal from 'sweetalert2';

//////video
import video from "../../assets/videos/video.mp4"
import DashboardFooter from './DashboardFooter/DashboardFooter';
import videoPlayBtn from '../../assets/img/videoPlayBtn.png';
import { getSkillForUser } from '../../Services/Skill';
import { getCurrentProgressForUser, getUserAges } from '../../Services/Activity';


import { getUserProfile, submitSelectedAge } from '../../Services/User';




export default function DashboardContainer() {
    const [modalShow, setModalShow] = useState(false);
    const history = useHistory()
    const [skillsArr, setSkillsArr] = useState([]);
    const [milestoneArr, setMilestoneArr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userAgeObj, setUserAgeObj] = useState({});
    const [childName, setChildName] = useState("");

    const [currentArr, setCurrentArr] = useState([]);

    const handleRedirect = (url) => {
        let path = `/Time-Table`
        localStorage.setItem('SelectedSkill', url)
        history.push({ pathname: path })
    }

    const getSkills = async () => {
        try {
            let res = await getSkillForUser()
            if (res.data.data) {
                setSkillsArr(res.data.data)
                let mileArr = [];
                res.data.data.forEach(el => {
                    el.milestones.forEach(ele => {
                        mileArr.push(ele)
                    })
                });
                setMilestoneArr(mileArr)
                setLoading(false)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.data}`,
                });
                setLoading(false)

            }
            else {
                console.error(err.response)
                // alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)

            }
        }
    }


    const getCurrentProgress = async () => {
        setLoading(true)

        try {
            const { data: res } = await getCurrentProgressForUser();
            if (res) {
                let temp = res.data.filter(el => el.milestones.length > 0)

                console.log(temp)
                temp.forEach((el, index) => {
                    el.displayMilestoneArr = el.milestones
                        .filter((ele, i, arr) => i == arr.findIndex(elx => elx.age_group_id == ele.age_group_id))
                        .map(ele => ({ age_group_id: ele.age_group_id, milestones: el.milestones.filter(elx => elx.age_group_id == ele.age_group_id) }))
                    //   return el
                })
                console.log(temp)

                setCurrentArr([...temp])
                let tempArr = [];
                res.data.forEach(el => {
                    el.milestones.forEach(ele => {
                        tempArr.push(ele)

                    });
                });
                setMilestoneArr(tempArr)
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)
    }

    const getUserAgesandData = async () => {
        setLoading(true)
        try {
            localStorage.removeItem("age");
            const { data: res } = await getUserAges();
            if (res) {
                console.log(res, "asdas");
                setUserAgeObj(res.user.free_test);
                localStorage.setItem("today", res.user.today);
                localStorage.setItem("age", res.user.age);
                // localStorage.setItem(res.user.today);
                localStorage.setItem("obj", JSON.stringify(res.user))
                if (res.free_test == false) {
                    if (res.user.age) {
                        let path = `/Test`
                        history.push({ pathname: path })
                    }
                    else {
                        alert("Age Group Not found")
                    }
                }
                else if (!res.user.selected_age) {
                    console.log(res.user.free_test)
                    handleAgeSet(parseInt(res.user.free_test?.d_age) + 1)
                    setLoading(false)
                    // setModalShow(true)
                }
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)

    }
    const getProfile = async () => {
        setLoading(true)

        try {
            const { data: res } = await getUserProfile();
            if (res) {
                // 
                let tempChildname = res.child_name.trim().split(' ')
                setChildName(tempChildname[0])
                setLoading(false)
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)

    }

    const handleAgeSet = async (value) => {
        setLoading(true)
        try {
            console.log(value)
            let obj = { age: value }
            const { data: res } = await submitSelectedAge(obj);
            if (res) {
                setLoading(false)
                getCurrentProgress()
                getUserAgesandData()
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false)

    }

    useEffect(() => {
        getProfile()
        getUserAgesandData()
        getCurrentProgress()
    }, [])




    // function MyVerticallyCenteredModal({ handleAgeSelection, ...props }) {
    //     const [selectedAge, setSelectedAge] = useState();

    //     const handleSubmit = async () => {
    //         let age = parseInt(selectedAge) + 1;
    //         handleAgeSelection(age)
    //         props.onHide()
    //     }


    //     useEffect(() => {

    //      }, [])


    //     return (
    //         <Modal
    //             {...props}
    //             size="sm"
    //             aria-labelledby="contained-modal-title-vcenter"
    //             centered
    //         >
    //             <Modal.Body className="RegisterModal" style={{ height: 200 }}>
    //                 <div className="RegisterModalInnerBox">
    //                     <div className="ModalHeaderHeading">Please select an age using which you experiance will be tailored</div>
    //                     <div className="planSelectionText my-3" onClick={() => setSelectedAge(userAgeObj?.age)}>
    //                         <div className={"radioContainerSelected"}>
    //                             <div className={selectedAge == userAgeObj?.age ? "radioCircleSelected" : "radioCircleUnSelected"}></div>
    //                         </div>
    //                         {parseInt(userAgeObj?.age)} Biological age
    //                     </div>
    //                     <div className="planSelectionText my-3" onClick={() => { setSelectedAge(userAgeObj?.d_age) }}>
    //                         <div className={"radioContainerSelected"}>
    //                             <div className={selectedAge == userAgeObj?.d_age ? "radioCircleSelected" : "radioCircleUnSelected"}></div>
    //                         </div>
    //                         {parseInt(userAgeObj?.d_age)} Developmental age
    //                     </div>

    //                     <button className="blueBtnModal" onClick={() => handleSubmit()}>Submit</button>

    //                 </div>
    //             </Modal.Body>

    //         </Modal>
    //     );
    // }
    return (

        <>
            {
                loading ?
                    <>
                        <div className="row mt-5 justify-content-center d-flex">
                            <div className="mt-5" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Loader
                                    visible={true}
                                    type="TailSpin"
                                    color="#FF6F96"
                                    height={100}
                                    width={100}
                                />
                            </div>
                        </div>
                    </>
                    :
                    <>


                        <div className="header">
                            <div className="headerHeading">
                                <img className="headerIcon" alt="Header-icon" src={dashBoardIcon1} />
                                Welcome to {childName}’s CompassTot
                                <img className="headerIcon" alt="Header-icon" src={dashBoardIcon2} />
                            </div>
                        </div>


                        <section className="dashboardContainer">

                            {/* <div className="introVideo">
                                <div className="videoOverlay">
                                    <img src={videoPlayBtn} alt="" style={{ marginBottom: 15 }} />
                                    Intro Video of What to Expect For The Age Group
                                </div>
                                <video src={video} controls />
                            </div> */}


                            {
                                currentArr && currentArr.map(el => {
                                    return (

                                        <div key={el.id} style={{ borderColor: el.border_color, borderLeftWidth: 5, borderRightWidth: 1, borderTopWidth: 1, borderBottomWidth: 1, borderRadius: 10 }} className="grossMotorSkillContainer" >
                                            <div className="grossMotorSkillHeading">
                                                {el.name}
                                            </div>
                                            <div className="levelIndicatorContainer">
                                                <div className="levelIndicatorOuter"><div className="levelIndicatorInner" style={{ width: el.bar ? `${el.bar}%` : 0 }}></div></div>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", width: "100%" }}>
                                                <div>{Math.round(el.bar)}% Completed</div>
                                            </div>
                                            <div className="currentSkillContainer">
                                                {el?.displayMilestoneArr.map((ele, ind) => {
                                                    return (
                                                        <>
                                                            <div key={ind} className="currentSkillHeading">Month {ele?.age_group_id - 1} </div>
                                                            {

                                                                ele?.milestones.map((elx, ind) => {
                                                                    return (
                                                                        <div key={ind} className="currentSkillDetails"> {elx?.name}</div>
                                                                    )
                                                                })
                                                            }
                                                        </>

                                                    )
                                                })}


                                            </div>
                                            <button className="blueBtnSkill" onClick={() => handleRedirect(el.name)}>
                                                Open Timetable
                                            </button>
                                        </div>
                                    )
                                })
                            }

                        </section>




                        {/* <MyVerticallyCenteredModal
                            show={modalShow}
                            handleAgeSelection={handleAgeSet}
                            onHide={() => setModalShow(false)}
                        /> */}




                        <DashboardFooter />
                    </>
            }
        </>

    )
}
