import React, { useEffect, useState } from 'react'
import './DashboardFooter.css'
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import activityCenterSelected from "../../../assets/img/activityCenterSelected.png";
import activityCenterUnSelected from "../../../assets/img/activityCenterUnSelected.png";
import dashboardActive from "../../../assets/img/dashboardActive.png";
import dashboardInactive from "../../../assets/img/dashboardInactive.png";
import profileSelected from "../../../assets/img/profileSelected.png";
import profileUnselected from "../../../assets/img/profileUnselected.png";


export default function DashboardFooter() {
    const history = useHistory();

    const location = useLocation();


    const [activeRoute, setActiveRoute] = useState(0);

    const handleRouteChange = (val) => {
        setActiveRoute(val)
        if (val === 0) {
            history.push("/Dashboard");
        }
        else if (val === 1) {
            history.push("/Activity-Center");
        }
        else if (val === 2) {
            history.push("/Profile");
        }
    }


    const checkLocation = () => {
        console.log("CHECKING", location.pathname)
        if (location.pathname.includes('Dashboard')) {
            setActiveRoute(0)
        }
        else if (location.pathname.includes('Activity-Center') || location.pathname.includes('Activity-Center-Detail') ) {
            setActiveRoute(1)
            console.log(location.pathname)
        }
        else if (location.pathname.includes('Profile') || location.pathname.includes('Activities-Completed') || location.pathname.includes('Activities-Ongoing') || location.pathname.includes('Activities-Upcoming') || location.pathname.includes('Settings' )) {
            setActiveRoute(2)
            console.log(location.pathname)
        }
    }



    useEffect(() => {
        checkLocation()
    }, [location?.pathname])

    return (
        <>
            {/* //////// footer ////////// */}

            <div className="footer">
                <div className="circleContainer">
                    <div className="circle" onClick={() => { handleRouteChange(0); setActiveRoute(0) }}>
                        <div className={`${activeRoute === 0 ? "innerCircleActive" : "innerCircleInActive"}`}>
                            {
                                activeRoute === 0 ?

                                    <img src={dashboardActive} alt="" />

                                    :

                                    <img src={dashboardInactive} alt="" />
                            }
                        </div>
                    </div>
                    <div className={`${activeRoute === 0 ? "activeItem" : "inactiveItem"} MenuItem1Name`} >Dashboard</div>
                </div>
                <div className="circleContainer1">

                    <div className="circle" onClick={() => { handleRouteChange(1); setActiveRoute(1) }}>
                        <div className={`${activeRoute === 1 ? "innerCircleActive" : "innerCircleInActive"}`}>
                            {
                                activeRoute === 1 ?

                                    <img src={activityCenterSelected} alt="" />

                                    :

                                    <img src={activityCenterUnSelected} alt="" />
                            }
                        </div>

                    </div>
                    <div className={`${activeRoute === 1 ? "activeItem" : "inactiveItem"} MenuItem2Name`} >Activity Centre
                    </div>
                </div>
                <div className="circleContainer2">
                    <div className="circle" onClick={() => { setActiveRoute(2); handleRouteChange(2) }}>
                        <div className={`${activeRoute === 2 ? "innerCircleActive" : "innerCircleInActive"}`}>
                            {
                                activeRoute === 2 ?

                                    <img src={profileSelected} alt="" />

                                    :

                                    <img src={profileUnselected} alt="" />
                            }

                        </div>
                    </div>
                    <div className={`${activeRoute === 2 ? "activeItem" : "inactiveItem"} MenuItem3Name`} >Profile</div>
                </div>
            </div>
        </>
    )
}
