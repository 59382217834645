import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import Loader from "react-loader-spinner";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Line } from "react-chartjs-2";
import { useHistory } from "react-router-dom";
import { AuthContext, jwtContext } from "../../App";
import activitiesCompleted from "../../assets/img/activitiesCompleted.png";
import chevronRight from "../../assets/img/chevronRight.png";
import facebook from "../../assets/img/facebook2.png";
import twitter from "../../assets/img/twitter2.png";
import googlePlus from "../../assets/img/googlePlus.png";
import instagram from "../../assets/img/instagram.png";
import youtube from "../../assets/img/youtube2.png";
import whatsapp from "../../assets/img/whatsapp.png";
import linkedIn from "../../assets/img/linkedIn2.png";
import logout from "../../assets/img/logout.png";
import personalDetails from "../../assets/img/personalDetails.png";
import pintrest from "../../assets/img/pintrest.png";
import skype from "../../assets/img/skype.png";
import telegram from "../../assets/img/telegram.png";
import line from "../../assets/img/line.png";
import wechat from "../../assets/img/wechat.png";
// import whatsapp from "../../assets/img/whatsapp.png";
import settingsIcon from "../../assets/img/settingsIcon.png";
import { getAge, getUserchart, getUserchartByMonth } from "../../Services/User";
import styles from "../AdminComponents/AdminLevel/AdminLevel.module.css";
import DashboardFooter from "../Dashboard/DashboardFooter/DashboardFooter";
import "./Profile.css";
import { getUserAges } from "../../Services/Activity";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
/////////////////////////////////////////// Add Level ///////////////////////////////////////////////////////////////////////////////////////////////////////
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={styles.Modal} style={{ height: 410 }}>
        <div className={styles.ModalInnerBox}>
          <>
            <div className={styles.modalHeading}>Share Screen Here</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginBottom: 30,
              }}
            >
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <img src={facebook} alt="" />
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <img src={twitter} alt="" />
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <img src={googlePlus} alt="" />
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <img src={instagram} alt="" />
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <img src={youtube} alt="" />
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <img src={whatsapp} alt="" />
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <img src={linkedIn} alt="" />
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <img src={pintrest} alt="" />
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <img src={skype} alt="" />
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <img src={telegram} alt="" />
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <img src={line} alt="" />
              </div>
              <div
                style={{
                  width: "25%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <img src={wechat} alt="" />
              </div>
            </div>
          </>
          <button
            onClick={() => props.onHide()}
            style={{ marginTop: 10, marginBottom: 30, borderRadius: 4 }}
            className="blueBtnRegister"
          >
            Share
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function Profile() {
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
  const [decodedJwt, setDecodedJwt] = useContext(jwtContext);
  const [userdata, setUserdata] = useState({});
  const [chartMonthsArr, setChartMonthsArr] = useState([]);
  const [chartDataArr, setChartDataArr] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [childMonthArr, setChildMonthArr] = useState([]);
  const [Age, setAge] = useState(0);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState("");
  const [ageArr, setAgeArr] = useState([]);
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Developmental Lead(+)/Lag(-) In Months (y-axis)",
        data: [],
        fill: false,
        responsive: true,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
      },
    ],
  });

  const handleRedirect = () => {
    let path = "/Settings";
    history.push({ pathname: path });
  };

  const getUserAge = async () => {
    try {
      setLoading(true)
      const { data: res } = await getAge();
      console.log(res, "age ress");
      if (res) {
        setLoading(false)
        setAgeArr(res.data);
      }
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("FAVCY_AUTH_TOKEN");
    localStorage.removeItem("userObj");
    localStorage.removeItem("ADMIN_AUTH_TOKEN");
    localStorage.removeItem("role");
    localStorage.removeItem("obj");
    setIsAuthorized(false);
    setDecodedJwt({ role: "" });
    history.push("/");
  };

  const getUserChartData = async () => {
    try {
      setLoading(true)
      const { data: res } = await getUserchart(Age);
      if (res.data) {
        console.log(res.data)

        setChartMonthsArr(res.data.month);
        let dataArr = res.data.month.map((el) => {
          if (el.value) {
            return `${el.value}`;
          } else {
            return 0;
          }
        });
        setData({
          labels: res.data.month.map((el) => el.day),
          datasets: [
            {
              label: "Developmental Lead (+) / Lag (-) In Months",
              data: dataArr,
              fill: false,
              responsive: true,
              borderWidth: 7,
              backgroundColor: "rgb(255, 99, 132)",
              borderColor: "rgba(255, 99, 132, 0.2)",
            },
          ],
        });
        // console.log(dataArr);
        // console.log(res.data.month);
        setChartDataArr(dataArr);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  };

  // useEffect(() => {
  //   getUserChartData();
  // }, [Age, month]);



  useEffect(() => {
    // getUserMonths();
    getUserAge();
    getUserData()
  }, []);


  const getUserData = async () => {
    try {
      let res = await getUserAges()
      if (res.status == 200 || res.status == 201 || res.status == 304) {
        console.log(JSON.stringify(res.data, null, 2))
        setUserdata(res?.data?.user);
      }
    }
    catch (err) {
      console.error(err)
      alert(err)
      setLoading(false)
    }
  }

  const optionsVal = {
    responsive: true,
    scales: {
      y: {
        suggestedMin: -6,
        suggestedMax: 6,
      },
      x: {
        afterFit: function (scale) {
          scale.width = 80; //<-- set value as you wish
        },
      },
    },
  };



  const getChartDataByMonth = async () => {
    try {
      if (Age == "" && month == "") {
        alert("Please select age as per date of birth or period to move forward")
        return;
      }
      setLoading(true)
      const { data: res } = await getUserchartByMonth(month);
      if (res.data) {
        console.log(res.data.month)
        setChartMonthsArr(res.data.month);
        let dataArr = res?.data?.month.map((el) => {
          if (el.value) {
            return `${el.value}`;
          } else {
            return 0;
          }
        });
        setData({
          labels: res.data.month.map((el) => el.day),
          datasets: [
            {
              label: "Developmental Lead (+) / Lag (-) In Months",
              data: dataArr,
              fill: false,
              responsive: true,
              borderWidth: 7,
              backgroundColor: "rgb(255, 99, 132)",
              borderColor: "rgba(255, 99, 132, 0.2)",
            },
          ],
        });
        // console.log(dataArr);
        // console.log(res.data.month);
        setChartDataArr(dataArr);
        setLoading(false)
      }
    }
    catch (err) {
      setLoading(false)
      console.error(err)
      alert(err)
    }
  }




  const handleChartDataGet = () => {
    // alert("ASd")
    if (Age == "" && month == "") {
      alert("please select age or period to view progress")
    }
    else if (Age == "") {
      getChartDataByMonth()
    }
    else {
      getUserChartData()
    }
  }




  if (loading) {

    return (
      <>
        <div className="row mt-5 justify-content-center d-flex">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <Loader
              visible={true}
              type="TailSpin"
              color="#FF6F96"
              height={100}
              width={100}
            />
          </div>
        </div>
      </>
    )
  }
  else {








    return (
      <>
        <div className="headerProfile">
          <div className="headerHeadings" style={{ color: "#fff" }}>
            Profile
          </div>
          <img
            onClick={() => handleRedirect()}
            src={settingsIcon}
            className="shareImg"
            alt=""
          />
        </div>
        <div className="mainProfileContainer">
          <div className="chartContainer">
            <div className="childName" style={{ textTransform: "capitalize" }}>
              <b>Child's Name</b> : {userdata.child_name}
            </div>
            <div className="childName" style={{ textTransform: "capitalize" }}>
              <b>Age as per Date of Birth </b> : {userdata.age} Months
            </div>

            <div className="childName" style={{ textTransform: "capitalize" }}>
              <b>Developmental Age</b> : {userdata.da_age} Months
            </div>
            <div style={{ width: 250, marginTop: 30 }}>
              <label>Age as per Date of Birth :</label>
              <select
                placeholder="Select Age"
                onChange={(e) => { setAge(e.target.value); setMonth("") }}
                value={Age}
                className="form-control"
              >
                <option value={""}>Please Select age</option>
                {ageArr &&
                  ageArr.length > 0 &&
                  ageArr.map((el) => {
                    return <option value={el} key={el}>{el}</option>;
                  })}
              </select>
            </div>
            <div style={{ width: 250 }} className="text-center pt-lg-4">
              <b> OR</b>
            </div>
            <div style={{ width: 250 }}>
              <label>Select Period</label>

              <select
                value={month}
                placeholder="Select Month"
                onChange={(e) => { setMonth(e.target.value); setAge("") }}
                className="form-control"
              >
                <option value="">Please Select period</option>
                <option value="3">3</option>
                <option value="6">6</option>
              </select>
            </div>


            <div className="optionsContainer2" style={{ width: 180, height: 40, marginTop: 20 }} onClick={() => handleChartDataGet()}>
              <div className="optionsTextContainer">
                <div className="optionsTextHeading">View Progress</div>
              </div>
            </div>
          </div>
          <div
            className={`container`}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="rotateText">Above The Curve</div>
              <div className="rotateText">On The Curve</div>
              <div className="rotateText">Below The Curve</div>
            </div>
            <div style={{ flex: 1, overflowX: "scroll" }}>
              <Line data={data} options={optionsVal} />
            </div>
          </div>
          {
            month && month > 1 ?

              <i>X Axis Denotes Weeks</i>
              :
              <i>X Axis Denotes Days</i>
          }
          <div
            className="noteText"
            style={{ textTransform: "capitalize", marginBottom: 15 }}
          >
            <b>Note :</b>
          </div>
          <div className="noteText" style={{ textTransform: "capitalize" }}>
            {" "}
            <b>0</b> in the chart means your child is likely to be on the Developmental Curve
          </div>
          <div className="noteText" style={{ textTransform: "capitalize" }}>
            <b>Positive values </b> in the chart means your child is likely to be Ahead of the
            Developmental Curve
          </div>
          <div
            className="noteText"
            style={{ textTransform: "capitalize", marginBottom: 40 }}
          >
            <b>Negative values </b> in the chart means your child is likely to be Behind the
            Developmental Curve
          </div>
          <div
            className="optionsContainer2"
            onClick={() => history.push("/Activities-Ongoing")}
          >
            <img src={activitiesCompleted} className="leftIcon" alt="" />
            <div className="optionsTextContainer">
              <div className="optionsTextHeading">Activities Ongoing</div>
            </div>
            <img src={chevronRight} className="chevronRight" alt="" />
          </div>
          <div
            className="optionsContainer3"
            onClick={() => history.push("/Activities-Completed")}
          >
            <img src={personalDetails} className="leftIcon" alt="" />
            <div className="optionsTextContainer">
              <div className="optionsTextHeading">Activities Completed</div>
            </div>
            <img src={chevronRight} className="chevronRight" alt="" />
          </div>
          <div
            className="optionsContainer4"
            onClick={() => history.push("/Activities-Upcoming")}
          >
            <img src={logout} className="leftIcon" alt="" />
            <div className="optionsTextContainer">
              <div className="optionsTextHeading">Activities Coming Up</div>
            </div>
            <img src={chevronRight} className="chevronRight" alt="" />
          </div>
          <div className="optionsContainer2" onClick={() => handleLogout()}>
            <img src={activitiesCompleted} className="leftIcon" alt="" />
            <div className="optionsTextContainer">
              <div className="optionsTextHeading">Logout</div>
            </div>
            <img src={chevronRight} className="chevronRight" alt="" />
          </div>
        </div>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        <DashboardFooter />
      </>
    );
  }
}

