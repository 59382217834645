import React, { useState, useEffect } from "react";
import styles from "./CSMChildProfile.module.css";
import Modal from "react-bootstrap/Modal";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
// import { CloneDeep } from "react-lodash";
import _ from "lodash";
////////images
import chevronRight from "../../../assets/img/chevronRight.png";
import growth from "../../../assets/img/growth.png";
import checklist from "../../../assets/img/checklist.png";
import customerreview from "../../../assets/img/customer-review.png";
import parents1 from "../../../assets/img/parents1.png";
import back from "../../../assets/img/back.png";
import helicopterLeft from "../../../assets/img/helicopter_cloud_modal_left.png";
import cloudRight from "../../../assets/img/cloud_modal_right.png";
import chevronRightBlack from "../../../assets/img/chevronRightBlack.png";
import CSMFooter from "../CSMFooter/CSMFooter";
import {
  getCsmChartData,
  getCsmChartDataByAge,
  getCsmChartDataByMonth,
  getCSMCustomersFromCsmDetails,
  getCsmUserChartData,
  getCSMUsersAge,
  getCustomPlanFromCSM,
  getMilestonesForCSM,
  setCustomPackageFromCSM,
} from "../../../Services/CSM";
import { getUserChartData, getUsersAge } from "../../../Services/Admin";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export default function CSMChildProfile() {
  function MyVerticallyCenteredModal({ handleSubmit, mileStoneArr, ...props }) {
    const [milestonesArr, setMilestonesArr] = useState([]);

    const [selectedActivityArr, setSelectedActivityArr] = useState([]);

    useEffect(() => {
      mileStoneArr = mileStoneArr.sort(
        (a, b) => a.age_group_id - b.age_group_id
      );

      let filteredActivityArrtemp = [
        ...mileStoneArr.filter(
          (el) => el.selected || el.activities.some((ele) => ele.selected)
        ),
      ];
      let finalArr = [];
      filteredActivityArrtemp.forEach((el) => {
        if (el.activities) {
          el.activities.forEach((elx) => {
            if (
              !finalArr.some(
                (ele) => ele.milestoneId == el.id && elx.id == el.activityId
              )
            ) {
              finalArr.push({ milestoneId: el.id, activityId: elx.id });
            }
          });
        }
      });
      setSelectedActivityArr([...finalArr]);
      setMilestonesArr(mileStoneArr);
    }, [mileStoneArr]);
    useEffect(() => { }, [milestonesArr]);
    useEffect(() => { }, [selectedActivityArr]);
    const handleActivityClick = (milestoneId, activityId) => {
      let index = selectedActivityArr.findIndex(
        (el) => el.milestoneId == milestoneId && el.activityId == activityId
      );
      if (index == -1) {
        setSelectedActivityArr((prevState) => [
          ...prevState,
          { milestoneId, activityId },
        ]);
      } else {
        setSelectedActivityArr((prevState) => [
          ...prevState.filter((el, i) => i != index),
        ]);
      }
    };

    const checkIsSelected = (milestoneId, activityId) => {
      return selectedActivityArr.some(
        (el) => el.milestoneId == milestoneId && el.activityId == activityId
      );
    };
    const checkIsMilestoneSelected = (milestoneId) => {
      return selectedActivityArr.some((el) => el.milestoneId == milestoneId);
    };
    const selectMilestone = (milestoneId) => {
      let milestoneObj = milestonesArr.find((el) => el.id == milestoneId);
      if (milestoneObj) {
        let nonExistArr = milestoneObj.activities.filter(
          (el) =>
            !selectedActivityArr.some(
              (ele) => ele.milestoneId == milestoneId && el.id == ele.activityId
            )
        );
        if (nonExistArr.length) {
          setSelectedActivityArr((prevState) => [
            ...prevState,
            ...nonExistArr.map((el) => ({ milestoneId, activityId: el.id })),
          ]);
        } else {
          setSelectedActivityArr((prevState) => [
            ...prevState.filter((el) => el.milestoneId != milestoneId),
          ]);
        }
      }
    };

    const handleMilestonesubmit = () => {
      let arr = [];
      arr = milestonesArr.reduce((acc, el) => {
        let tempArr = el.activities.filter((elx) =>
          selectedActivityArr.some((ele) => ele.activityId == elx.id)
        );
        return [...acc, ...tempArr];
      }, []);

      handleSubmit(arr);

      props.onHide();
    };

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className={styles.Modal} style={{ height: 660 }}>
          <img
            src={cloudRight}
            alt="helicopter"
            className={styles.cloudImage}
          />
          <div className={styles.ModalInnerBox}>
            <img
              src={helicopterLeft}
              alt="helicopter"
              className={styles.helicopterLeftImage}
            />
            <div className={styles.modalHeading}>Select Range</div>
            {/* <div className={styles.modalSubHeading}>
                            
                        </div> */}
            <div className={styles.milestonesArrContainer}>
              {milestonesArr &&
                milestonesArr.length > 0 &&
                milestonesArr.map((el, index) => {
                  return (
                    <div key={el.id}>
                      <div
                        className={`${checkIsMilestoneSelected(el?.id)
                          ? styles.selectedBg
                          : ""
                          }`}
                      >
                        <div
                          className={`${styles.milestoneHeadingContainer}`}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                          onClick={() => selectMilestone(el?.id)}
                        >
                          <div className={`${styles.milestoneHeading} `}>
                            Month {parseInt(el.age_group_id) - 1}
                          </div>
                          <div
                            className={`${styles.milestoneHeadingPink} `}
                            style={{ marginTop: 5, marginBottom: 5 }}
                          >
                            Skill name : {el.skill.name}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "center",
                            }}
                            onClick={() => selectMilestone(el?.id)}
                          >
                            <div className={`${styles.milestoneHeading} `}>
                              {el?.name}
                            </div>
                            <img
                              className={styles.chevron}
                              src={chevronRightBlack}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      {el.activities.map((ele, ind) => {
                        return (
                          <div
                            className={`${checkIsSelected(el?.id, ele.id)
                              ? styles.selectedBg
                              : ""
                              }`}
                          >
                            <div
                              onClick={() =>
                                handleActivityClick(el?.id, ele?.id)
                              }
                              className={`${styles.activitiesHeadingContainer} `}
                            >
                              {ind + 1}. {ele?.name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>

            <button
              onClick={() => handleMilestonesubmit()}
              style={{ marginTop: 10, marginBottom: 30, borderRadius: 4 }}
              className={`${styles.pinkBtn} blueBtnRegister`}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  const history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [childDetails, setChildDetails] = useState({});
  const [chartMonthsArr, setChartMonthsArr] = useState([]);
  const [chartDataArr, setChartDataArr] = useState([]);
  const [selectedCustomPlanArr, setSelectedCustomPlanArr] = useState([]);
  const [Age, setAge] = useState(0);
  const [month, setMonth] = useState(1);
  const [ageArr, setAgeArr] = useState([]);
  const [userdata, setUserdata] = useState({});

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });
  let id = useParams();
  const [allMilestoneArr, setAllMilestoneArr] = useState([]);

  const handleChartDataGet = () => {
    // alert("ASd")
    if (Age == "" && month == "") {
      alert("please select age or period to view progress")
    }
    else if (Age == "") {
      getChartDataByMonth()
    }
    else {
      getUserChartData()
    }
  }



  const getChartDataByMonth = async () => {
    try {
      if (Age == "" && month == "") {
        alert("Please select age as per date of birth or period to move forward")
        return;
      }
      setLoading(true)
      const { data: res } = await getCsmChartDataByMonth(id.id, month);
      if (res.data) {
        console.log(res.data.month)
        setChartMonthsArr(res.data.month);
        let dataArr = res?.data?.month.map((el) => {
          if (el.value) {
            return `${el.value}`;
          } else {
            return 0;
          }
        });
        setData({
          labels: res.data.month.map((el) => el.day),
          datasets: [
            {
              label: "Developmental Lead (+) / Lag (-) In Months",
              data: dataArr,
              fill: false,
              responsive: true,
              borderWidth: 7,
              backgroundColor: "rgb(255, 99, 132)",
              borderColor: "rgba(255, 99, 132, 0.2)",
            },
          ],
        });
        // console.log(dataArr);
        // console.log(res.data.month);
        setChartDataArr(dataArr);
        setLoading(false)
      }
    }
    catch (err) {
      setLoading(false)
      console.error(err)
      alert(err)
    }
  }

  const getUserChartData = async () => {
    try {
      setLoading(true)
      const { data: res } = await getCsmChartDataByAge(id.id, Age);
      if (res.data) {
        console.log(res.data);
        setChartMonthsArr(res.data.month);
        let dataArr = res.data.month.map((el) => {
          if (el.value) {
            return `${el.value}`;
          } else {
            return 0;
          }
        });
        setData({
          labels: res.data.month.map((el) => el.day),
          datasets: [
            {
              label: "Developmental Lead (+) / Lag (-) In Months",
              data: dataArr,
              fill: false,
              responsive: true,
              borderWidth: 7,
              backgroundColor: "rgb(255, 99, 132)",
              borderColor: "rgba(255, 99, 132, 0.2)",
            },
          ],
        });
        // console.log(dataArr);
        // console.log(res.data.month);
        setChartDataArr(dataArr);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  };



  const getUserAge = async () => {
    try {
      setLoading(true)

      const { data: res } = await getCSMUsersAge(id.id);
      console.log(res);
      if (res) {
        setAgeArr(res.data);
        setAge(res.data[0])
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  };

  const getMilestone = async (arr) => {
    try {
      setLoading(true);
      let res = await getMilestonesForCSM();
      if (res.status == 200 || res.status == 304 || res.status == 202) {
        let tempArr = res?.data.map((el) => {
          let obj = {
            ...el,
            selected: el.activities.some((ele) =>
              arr.some((elx) => elx.activity_id == ele.id)
            ),
            activities: el.activities.map((ele) => {
              let nestedObj = {
                ...ele,
                selected: arr.some((elx) => elx.activity_id == ele.id),
              };
              return nestedObj;
            }),
          };
          return obj;
        });
        // res.data.forEach(el => {
        //     el.selected = false
        //     el.activities.forEach(ele => {
        //         if (arr.some(elx => elx.activity_id == ele.id)) {
        //             ele.selected = true
        //         }
        //         else {
        //             ele.selected = false
        //         }
        //     })
        // });
        // let tempArr = res.data
        setAllMilestoneArr(tempArr);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err?.response?.data?.message);
        Swal.fire({
          title: "Error",
          type: "error",
          text: `${err?.response?.data?.message}`,
        });
        setLoading(false);
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          type: "error",
          text: `${err?.message}`,
        });
        setLoading(false);
      }
    }
  };
  const handleCustomPlanSubmit = async (selectedArr) => {
    try {
      setLoading(true);
      let arr = selectedArr.map((el) => el.id);
      let obj = {
        user_id: id.id,
        activities: arr,
      };
      let res = await setCustomPackageFromCSM(obj);
      if (res.status == 200 || res.status == 304 || res.status == 202) {
        Swal.fire({
          title: "Success",
          type: "success",
          text: `${res?.data?.message}`,
        });
        getcustomPlans();
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err?.response?.data?.message);
        Swal.fire({
          title: "Error",
          type: "error",
          text: `${err?.response?.data?.message}`,
        });
        setLoading(false);
      } else {
        console.error(err);
        Swal.fire({
          title: "Error",
          type: "error",
          text: `${err?.message}`,
        });
        setLoading(false);
      }
    }
  };

  const getcustomPlans = async () => {
    try {
      setLoading(true);
      let res = await getCustomPlanFromCSM(id.id);
      if (res.status == 200 || res.status == 304) {
        setLoading(false);
        console.log(res.data.data);
        setSelectedCustomPlanArr(res.data.data);
        getMilestone(res.data.data);
        // setChildDetails(res.data.data)
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err?.response?.data?.message);
        Swal.fire({
          title: "Error",
          type: "error",
          text: `${err?.response?.data?.message}`,
        });
        setLoading(false);
      } else {
        console.error(err);

        Swal.fire({
          title: "Error",
          type: "error",
          text: `${err?.message}`,
        });
        setLoading(false);
      }
    }
  };

  const getCsmUserDetails = async () => {
    try {
      setLoading(true);
      let res = await getCSMCustomersFromCsmDetails(id.id);
      if (res.status == 200 || res.status == 304) {
        setLoading(false);
        setUserdata(res.data.data);
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        console.error(err?.response?.data?.message);
        Swal.fire({
          title: "Error",
          type: "error",
          text: `${err?.response?.data?.message}`,
        });
        setLoading(false);
      } else {
        console.error(err);

        Swal.fire({
          title: "Error",
          type: "error",
          text: `${err?.message}`,
        });
        setLoading(false);
      }
    }
  };

  const getUsersChartData = async () => {
    try {
      setLoading(true)
      if (Age != "" && month != "") {
        console.log("SENDING");
        const { data: res } = await getCsmChartDataByMonth(id.id, month);
        if (res.data) {
          let monthsArr = Object.keys(res.data.month)
            .filter((el) => el != "end" || el != "start" || el != "status")
            .map((key) => ({
              ...res.data.month[key],
            }))
            .filter((el) => el.day);

          //   console.log(Object.keys(res.data.month));
          //   res.data.month.map((el) => {
          //     return `${el.day}`;
          //   });
          console.log("MONTH", monthsArr);
          setChartMonthsArr(monthsArr);
          let dataArr = monthsArr.map((el) => {
            if (el.value) {
              return `${el.value}`;
            } else {
              return 0;
            }
          });
          setData({
            labels: monthsArr.map((el) => el.day),
            datasets: [
              {
                label: "All the values on y axis denotes months",
                data: dataArr,
                fill: false,
                responsive: true,
                borderWidth: 7,
                backgroundColor: "rgb(255, 99, 132)",
                borderColor: "rgba(255, 99, 132, 0.2)",
              },
            ],
          });
          console.log(dataArr);
          console.log(monthsArr);

          setChartDataArr(dataArr);
          setLoading(false)
        }
      }
      else {
        setLoading(false)
        alert("Please fill all the details")
      }
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  };

  const handleBackNavigation = () => {
    let path = `/CSM-Manage-Children`;
    history.push(path);
  };

  // useEffect(() => {
  //   if (Age != "" && month != "") {
  //     getUsersChartData();
  //   }
  // }, [Age, month]);

  useEffect(() => {
    getUserAge();
    getCsmUserDetails();
    getcustomPlans();
    getCsmUserDetails();
  }, []);

  const optionsVal = {
    responsive: true,
    scales: {
      y: {
        suggestedMin: -15,
        suggestedMax: 15,
      },
    },
  };
  return (
    <>
      <div className={`${styles.headerProfile}`}>
        <div onClick={() => handleBackNavigation()} className={styles.btn}>
          <img src={back} alt="" />
        </div>
        <div className={`${styles.headerHeadings}`} style={{ color: "#fff" }}>
          Profile
        </div>
      </div>
      {loading ? (
        <>
          <div className="row mt-5 justify-content-center d-flex">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <Loader
                visible={true}
                type="TailSpin"
                color="#FF6F96"
                height={100}
                width={100}
              />
            </div>
          </div>
        </>
      ) : (
        <div className={`${styles.mainProfileContainer}`}>
          <div className="chartContainer">
            <div className="childName" style={{ textTransform: "capitalize" }}>
              <b>Child's Name</b> : {userdata?.child_name}
            </div>
            <div className="childName" style={{ textTransform: "capitalize" }}>
              <b>Age as per Date of Birth </b> : {userdata?.age} Months
            </div>

            <div className="childName" style={{ textTransform: "capitalize" }}>
              <b>Developmental Age</b> : {userdata?.da_age} Months
            </div>
            <div style={{ width: 250, marginTop: 30 }}>
              <label>Age as per Date of Birth :</label>
              <select
                placeholder="Select Age"
                onChange={(e) => { setAge(e.target.value); setMonth("") }}
                value={Age}
                className="form-control"
              >
                <option value={""}>Please Select age</option>
                {ageArr &&
                  ageArr.length > 0 &&
                  ageArr.map((el) => {
                    return <option value={el} key={el}>{el}</option>;
                  })}
              </select>
            </div>
            <div style={{ width: 250 }} className="text-center pt-lg-4">
              <b> OR</b>
            </div>
            <div style={{ width: 250 }}>
              <label>Select Period</label>

              <select
                value={month}
                placeholder="Select Month"
                onChange={(e) => { setMonth(e.target.value); setAge("") }}
                className="form-control"
              >
                <option value="">Please Select period</option>
                <option value="3">3</option>
                <option value="6">6</option>
              </select>
            </div>


            <div className="optionsContainer2 mb-4" style={{ width: 180, height: 40, marginTop: 20 }} onClick={() => handleChartDataGet()}>
              <div className="optionsTextContainer">
                <div className="optionsTextHeading">View Progress</div>
              </div>
            </div>
          </div>
          <div
            className={`container`}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="rotateText">Above The Curve</div>
              <div className="rotateText">On The Curve</div>
              <div className="rotateText">Below The Curve</div>
            </div>
            <div style={{ flex: 1, overflowX: "scroll" }}>
              <Line data={data} options={optionsVal} />
            </div>
          </div>
          {
            month && month > 1 ?

              <i>X Axis Denotes Weeks</i>
              :
              <i>X Axis Denotes Days</i>
          }
          <div
            className="noteText"
            style={{ textTransform: "capitalize", marginBottom: 15 }}
          >
            <b>Note :</b>
          </div>
          <div className="noteText" style={{ textTransform: "capitalize" }}>
            {" "}
            <b>0</b> in the chart means your child is likely to be on the Developmental Curve
          </div>
          <div className="noteText" style={{ textTransform: "capitalize" }}>
            <b>Positive values </b> in the chart means your child is likely to be Ahead of the
            Developmental Curve
          </div>
          <div
            className="noteText"
            style={{ textTransform: "capitalize", marginBottom: 40 }}
          >
            <b>Negative values </b> in the chart means your child is likely to be Behind the
            Developmental Curve
          </div>
          <div
            className={`${styles.optionsContainer2}`}
            onClick={() => history.push(`/CSM-Child-Parents-Details/${id.id}`)}
          >
            <img src={parents1} className={`${styles.leftIcon}`} alt="" />
            <div className={`${styles.optionsTextContainer}`}>
              <div className={`${styles.optionsTextHeading}`}>
                View Parent Details
              </div>
            </div>
            <img
              src={chevronRight}
              className={`${styles.chevronRight}`}
              alt=""
            />
          </div>
          <div
            className={`${styles.optionsContainer3}`}
            onClick={() => history.push(`/CSM-Child-Details/${id.id}`)}
          >
            <img src={growth} className={`${styles.leftIcon}`} alt="" />
            <div className={`${styles.optionsTextContainer}`}>
              <div className={`${styles.optionsTextHeading}`}>
                View Current Progress
              </div>
            </div>
            <img
              src={chevronRight}
              className={`${styles.chevronRight}`}
              alt=""
            />
          </div>
          <div
            className={`${styles.optionsContainer4}`}
            onClick={() => setModalShow(true)}
          >
            <img src={checklist} className={`${styles.leftIcon}`} alt="" />
            <div className={`${styles.optionsTextContainer}`}>
              <div className={`${styles.optionsTextHeading}`}>
                Assign Custom Plans
              </div>
            </div>
            <img
              src={chevronRight}
              className={`${styles.chevronRight}`}
              alt=""
            />
          </div>
        </div>
      )}
      <CSMFooter />
      <MyVerticallyCenteredModal
        // handleadd={handleMilestonesAdd}
        mileStoneArr={allMilestoneArr}
        show={modalShow}
        handleSubmit={handleCustomPlanSubmit}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
