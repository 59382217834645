import React, { useEffect, useState } from 'react'
import styles from './Adminhomepagefooter.module.css'
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import activityCenterSelected from "../../../assets/img/activityCenterSelected.png";
import activityCenterUnSelected from "../../../assets/img/activityCenterUnSelected.png";
import managementActive from "../../../assets/img/managementAcitve.png";
import management from "../../../assets/img/management.png";
import childProfileActive from "../../../assets/img/childProfileActive.png";
import childProfileInActive from "../../../assets/img/childProfileInActive.png";

export default function Adminhomepagefooter() {
    const history = useHistory();

    const location = useLocation();


    const [activeRoute, setActiveRoute] = useState(0);

    const handleRouteChange = (val) => {
        setActiveRoute(val)
        if (val === 0) {
            history.push("/Admin-Dashboard");
        }
        if (val === 2) {
            history.push("/Admin-CSM-User-List");
        }
        if (val === 1) {
            history.push("/Manage-CSM");
        }
    }


    const checkLocation = () => {
        if (location.pathname.includes('Admin-Dashboard')) {
            setActiveRoute(0)
        }
        // location.pathname.includes('Admin-CSM-List') ||
        else if ( location.pathname.includes('Admin-CSM-User-List') || location.pathname.includes('Admin-Child-Profile') || location.pathname.includes('Admin-Child-Parent-Details')) {

            setActiveRoute(2)
            console.log(location.pathname)
        }
        else if (location.pathname.includes('Manage-CSM') || location.pathname.includes('CSM-Customers') || location.pathname.includes('Add-CSM') || location.pathname.includes('Admin-CSM-child-details')) {
            setActiveRoute(1)
            console.log(location.pathname)
        }
    }



    useEffect(() => {
        checkLocation()
    }, [activeRoute])

    return (
        <>
            {/* //////// footer ////////// */}



            <div className={styles.footer}>

                <div className={styles.circleContainer}>

                    <div className={styles.circle} onClick={() => { handleRouteChange(0); setActiveRoute(0) }}>
                        <div className={`${activeRoute === 0 ? styles.innerCircleActive : styles.innerCircleInActive}`}>
                            {
                                activeRoute === 0 ?

                                    <img src={activityCenterSelected} alt="" />

                                    :

                                    <img src={activityCenterUnSelected} alt="" />
                            }
                        </div>

                    </div>
                    <div className={`${activeRoute === 0 ? styles.activeItem : styles.inactiveItem} ${styles.MenuItem2Name}`} >Activity Centre
                    </div>
                </div>

                <div className={styles.circleContainer1}>
                    <div className={styles.circle} onClick={() => { handleRouteChange(1); setActiveRoute(1) }}>
                        <div className={`${activeRoute === 1 ? styles.innerCircleActive : styles.innerCircleInActive}`}>
                            {
                                activeRoute === 1 ?

                                    <img src={managementActive} alt="" />

                                    :

                                    <img src={management} alt="" />
                            }
                        </div>
                    </div>
                    <div className={`${activeRoute === 1 ? styles.activeItem : styles.inactiveItem} ${styles.MenuItem1Name}`}>Manage CSM</div>
                </div>

                <div className={styles.circleContainer2}>
                    <div className={styles.circle} onClick={() => { setActiveRoute(2); handleRouteChange(2) }}>
                        <div className={`${activeRoute === 2 ? styles.innerCircleActive : styles.innerCircleInActive}`}>
                            {
                                activeRoute === 2 ?

                                    <img src={childProfileActive} alt="" />

                                    :

                                    <img src={childProfileInActive} alt="" />
                            }

                        </div>
                    </div>
                    <div className={`${activeRoute === 2 ? styles.activeItem : styles.inactiveItem} ${styles.MenuItem3Name}`}>Child Profile</div>
                </div>
            </div>
        </>
    )
}
