import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Adminhomepagefooter from '../AdminHomepageFooter/Adminhomepagefooter';
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";
import styles from "./AdminChildParentDetails.module.css";
import back from "../../../assets/img/back.png";
import { getUserDetailfromAdmin } from '../../../Services/User';

export default function AdminChildParentDetails() {
    const history = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [childDetails, setChildDetails] = useState({});
    let id = useParams()
    console.log(id.id)
    const handleBackNavigation = () => {
        history.goBack();
    }




    const getCsmUserDetails = async () => {
        try {
            setLoading(true)
            let res = await getUserDetailfromAdmin(id.id)
            console.log(res.data.data)
            if (res.status == 200 || res.status == 304) {
                setLoading(false)
                setChildDetails(res.data.data)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)

                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }
    }


    useEffect(() => {
        getCsmUserDetails()
    }, [])




    return (
        <div>


            <div className={`${styles.header}`}>
                <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div>
                <div className={`${styles.headerHeading}`}>
                    Parent Details
                </div>
            </div>
            <div className={`${styles.containerdiv} container`}>
                {
                    loading ?
                        <>
                            <div className="row mt-5 justify-content-center d-flex">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Loader
                                        visible={true}
                                        type="TailSpin"
                                        color="#FF6F96"
                                        height={100}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </>
                        :
                        <div className="row">
                            <div className="mx-auto col-lg-5 col-11">
                                <div className={`${styles.label}`}>Parent Name</div>
                                <input value={childDetails?.name} disabled className={`${styles.txtInput}`} placeholder="Nishant Chawla" />
                                <div className={`${styles.label}`}>Phone Number</div>
                                <input value={childDetails?.mobile} disabled className={`${styles.txtInput}`} placeholder="XXXXXXXXXX" />
                                <div className={`${styles.label}`}>Email Address</div>
                                <input value={childDetails?.email} disabled className={`${styles.txtInput}`} placeholder="eg. example@example.com" />
                            </div>
                        </div>
                }
            </div>
            <Adminhomepagefooter />

        </div>
    )
}
