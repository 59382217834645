import React, { useState, useEffect } from 'react'
import styles from './AdminLevel.module.css'
import Modal from "react-bootstrap/Modal";
import S3 from 'react-aws-s3';
import Adminhomepagefooter from "../AdminHomepageFooter/Adminhomepagefooter"
import edit from "../../../assets/img/edit.png";
import back from "../../../assets/img/back.png";
import plus from "../../../assets/img/plus.png";
import minus from "../../../assets/img/minus.png";
import DeleteActivity from "../../../assets/img/DeleteActivity.png";
import { useHistory } from 'react-router-dom';
import editIcon from "../../../assets/img/editIcon.png";
import deleteIcon from "../../../assets/img/deleteIcon.png";
import helicopterLeft from "../../../assets/img/helicopter_cloud_modal_left.png";
import cloudRight from "../../../assets/img/cloud_modal_right.png";
import Loader from "react-loader-spinner";
import Swal from 'sweetalert2';
import { config } from '../../../Services/BucketConfig';
import { editActivity, getActivity, getActivityById } from '../../../Services/Activity';
import { useLocation, useParams } from 'react-router';
import { addlevel, deletelevels, editlevels, getlevels, getlevelsById } from '../../../Services/Level';
export default function AdminLevel() {

    const ReactS3Client = new S3(config);
    const history = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [modalShow2, setModalShow2] = useState(false);
    const [editActivityModalShow, setEditActivityModalShow] = useState(false);
    const [editLevelModalShow, setEditLevelModalShow] = useState(false);
    const [mileStonesArr, setMileStonesArr] = useState([]);
    const [activityObj, setActivityObj] = useState({});
    const [levelArr, setLevelArr] = useState([]);
    let id = useParams();
    const [selectedLevel, setSelectedLevel] = useState("");


    const [loading, setLoading] = useState(false);

    const handleBackNavigation = () => {
        let path = `Admin-Activity`;
        history.goBack();
    }

    const handleMilestonesAdd = (obj) => {
        setMileStonesArr(previousValue => [...previousValue, obj])
        // handleAdd(obj)
    }

    const handleRedirect = () => {
        let path = `Admin-Level`;
        history.push(path);
    }



    const handleLevelAdd = async (obj) => {
        try {
            // console.log(obj.media.name)
            if(obj?.media){
            ReactS3Client
                .uploadFile(obj.media, obj.media.name)
                .then(async (data) => {
                    console.log(data, "response after data is uploaded to bucket")
                    console.log(data.location, "response after data is uploaded to bucket")
                    setLoading(true)
                    let formData = new FormData();
                    formData.append("s_no", obj.s_no)
                    formData.append("name", obj.name)
                    formData.append("description", obj.description)
                    formData.append("activity_id", obj.activity_id)
                    formData.append("status", obj.status)
                    formData.append("media", data.location)
                    obj.option_text.forEach(el => {
                        formData.append("option_text[]", el)
                    })
                    obj.points.forEach(el => {
                        formData.append("points[]", el)
                    })
                    let res = await addlevel(formData)
                    if (res.status == 200 || res.status == 304 || res.status == 202) {
                        Swal.fire({
                            title: 'Success',
                            type: 'success',
                            text: `${res?.data?.message}`,
                        });
                        setLoading(false);
                        getActivity();
                        getLevelArr();
                    }
                })
                .catch(err => console.error(err))
            }
            else{
                setLoading(true)
                let formData = new FormData();
                formData.append("s_no", obj.s_no)
                formData.append("name", obj.name)
                formData.append("description", obj.description)
                formData.append("activity_id", obj.activity_id)
                formData.append("status", obj.status)
                formData.append("media", "")
                obj.option_text.forEach(el => {
                    formData.append("option_text[]", el)
                })
                obj.points.forEach(el => {
                    formData.append("points[]", el)
                })
                let res = await addlevel(formData)
                if (res.status == 200 || res.status == 304 || res.status == 202) {
                    Swal.fire({
                        title: 'Success',
                        type: 'success',
                        text: `${res?.data?.message}`,
                    });
                    setLoading(false);
                    getActivity();
                    getLevelArr();
                }
            }


        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)
            }
        }
    }
    const handleLevelEdit = async (obj) => {
        try {
            if (obj?.media?.name) {
                ReactS3Client
                    .uploadFile(obj.media, obj.media.name)
                    .then(async (data) => {
                        console.log(data, "response after data is uploaded to bucket")
                        console.log(data.location, "response after data is uploaded to bucket")
                        setLoading(true)
                        let formData = new FormData();
                        formData.append("s_no", obj.s_no)
                        formData.append("name", obj.name)
                        formData.append("description", obj.description)
                        formData.append("activity_id", obj.activity_id)
                        formData.append("status", obj.status)
                        formData.append("media", data.location)
                        console.log(obj.option_id)
                        console.log(obj.option_text)
                        console.log(obj.points)
                        obj.option_id.forEach(el => {
                            formData.append("option_id[]", el)
                        })
                        obj.option_text.forEach(el => {
                            formData.append("option_text[]", el)
                        })
                        obj.points.forEach(el => {
                            formData.append("points[]", el)
                        })
                        let res = await editlevels(selectedLevel.id, formData)
                        if (res.status == 200 || res.status == 304 || res.status == 202) {
                            console.log(res.data, "asdada")

                            Swal.fire({
                                title: 'Success',
                                type: 'success',
                                text: `${res?.data?.message}`,
                            });
                            setLoading(false);
                            getLevelArr();
                            getActivity();
                        }
                    })
                    .catch(err => console.error(err))
            }
            else {
                setLoading(true)
                let formData = new FormData();
                formData.append("s_no", obj.s_no)
                formData.append("name", obj.name)
                formData.append("description", obj.description)
                formData.append("activity_id", obj.activity_id)
                formData.append("status", obj.status)
                formData.append("media", obj?.media ? obj.media : "")
                console.log(obj.option_id)
                console.log(obj.option_text)
                console.log(obj.points)
                obj.option_id.forEach(el => {
                    formData.append("option_id[]", el)
                })
                obj.option_text.forEach(el => {
                    formData.append("option_text[]", el)
                })
                obj.points.forEach(el => {
                    formData.append("points[]", el)
                })
                let res = await editlevels(selectedLevel.id, formData)
                if (res.status == 200 || res.status == 304 || res.status == 202) {
                    console.log(res.data, "asdada")

                    Swal.fire({
                        title: 'Success',
                        type: 'success',
                        text: `${res?.data?.message}`,
                    });
                    setLoading(false);
                    getLevelArr();
                    getActivity();
                }
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }
    }

    const handleLevelDelete = async () => {
        try {
            setLoading(true)
            let res = await deletelevels(selectedLevel.id)
            if (res.status == 200 || res.status == 304 || res.status == 202) {
                // console.log(res.data)
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.message}`,
                });
                setLoading(false);
                getActivity()
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }
    }





    const getActivity = async () => {
        try {
            setLoading(true)
            let res = await getActivityById(id.id)
            if (res.status == 200 || res.status == 304 || res.status == 202) {
                console.log("activity", res.data.data)
                setActivityObj(res.data.data);
                setLevelArr(res.data.data.levels)
                setLoading(false)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }

    }
    const getLevelArr = async () => {
        try {
            setLoading(true)
            let res = await getlevels()
            if (res.status == 200 || res.status == 304 || res.status == 202) {
                // console.log(res.data)
                // setLevelArr(res.data)
                setLoading(false)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }

    }

    const handleActivityEdit = async (obj) => {
        try {
            if (obj?.media?.name) {
                ReactS3Client
                    .uploadFile(obj.media, obj.media.name)
                    .then(async (data) => {
                        // console.log(data, "response after data is uploaded to bucket")
                        // console.log(data.location, "response after data is uploaded to bucket")
                        setLoading(true)
                        // console.log(obj, "edit obj")
                        let formData = new FormData();
                        formData.append("s_no", obj.s_no);
                        formData.append("name", obj.name);
                        formData.append("media", data.location);
                        formData.append("description", obj.additionalDetails);
                        formData.append("extra", obj.extraOptions);
                        formData.append("status", 1);
                        formData.append("milestone_id", obj.milestone_id);
                        formData.append("day", parseInt(obj.day));
                        let res = await editActivity(id.id, formData)
                        if (res.status == 200 || res.status == 304 || res.status == 202) {
                            Swal.fire({
                                title: 'Success',
                                type: 'success',
                                text: `${res?.data?.message}`,
                            });
                            setLoading(false);
                            getActivity();
                        }
                    })
                    .catch(err => console.error(err))
            }
            else {
                setLoading(true)
                console.log(obj, "edit obj")
                let formData = new FormData();
                formData.append("s_no", obj.s_no);
                formData.append("name", obj.name);
                formData.append("media", "");
                formData.append("description", obj.additionalDetails);
                formData.append("extra", obj?.extraOptions ? obj.extraOptions : "");
                formData.append("status", 1);
                formData.append("milestone_id", obj.milestone_id);
                formData.append("day", parseInt(obj.day));


                let res = await editActivity(id.id, formData)
                if (res.status == 200 || res.status == 304 || res.status == 202) {
                    console.log(res.data)
                    Swal.fire({
                        title: 'Success',
                        type: 'success',
                        text: `${res?.data?.message}`,
                    });
                    setLoading(false);
                    getActivity();
                }
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }

    }



    /////////////////////////////////////////// Add Level ///////////////////////////////////////////////////////////////////////////////////////////////////////

    function MyVerticallyCenteredModal({ handleadd, ...props }) {
        const [serialNumber, setSerialNumber] = useState("");
        const [name, setName] = useState("");
        const [additionalDetailsArr, setAdditionalDetailsArr] = useState([]);
        const [file, setFile] = useState();
        const [fileName, setFileName] = useState("");
        const [optionsArr, setOptionsArr] = useState([]);



        const HandleSubmit = () => {
            if (name == "") {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please Enter Level Name`,
                });
            }
            else if (additionalDetailsArr.length > 0 && additionalDetailsArr.some(el => el.optionText == "") ) {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please Enter Level Description`,
                });
            }
            // else if (optionsArr.length <= 0) {
            //     Swal.fire({
            //         title: 'Error',
            //         type: 'error',
            //         text: `Please Enter Options`,
            //     });
            // }
            // else if (optionsArr.length > 0 && optionsArr.some(el => el.optionText == "") && optionsArr.some(el => el.pointsAssigned == "")) {
            //     Swal.fire({
            //         title: 'Error',
            //         type: 'error',
            //         text: `Please Enter Options`,
            //     });
            // }
            else {
                console.log(additionalDetailsArr.reduce((acc,el)=> `${acc}&&&${el.value}`,''), "ASdad")
                let obj = {
                    name: name,
                    s_no: serialNumber,
                    description: additionalDetailsArr.reduce((acc,el)=> acc != "" ? `${acc}&&&${el.value}` : `${acc}${el.value}`,''),
                    activity_id: parseInt(id.id),
                    status: 1,
                    media: file?.name ? file : "",
                    option_text: optionsArr.map(el => el.optionText),
                    points: optionsArr.map(el => el.pointsAssigned),
                }
                console.log(obj, "object")
                handleadd(obj)
                setName("");
                setFile("");
                setFileName("");
                setOptionsArr([]);
                setAdditionalDetailsArr([])
                props.onHide()
            }
        }


        const addDescription = () => {
            setAdditionalDetailsArr(previousValue => {
                return [...previousValue, { value: "" }]
            })
        }
        const deleteDescription = () => {
            let tempArr = additionalDetailsArr.filter((el, index) => index != (additionalDetailsArr.length - 1))
            setAdditionalDetailsArr(tempArr)
        }

        const setDescriptionText = (val, index) => {
            setAdditionalDetailsArr(arr => [...arr.map((el, i) => i == index ? { ...el, value: val } : el)])
        }




        const addOption = () => {
            setOptionsArr(previousValue => {
                return [...previousValue, { optionText: "", pointsAssigned: "" }]
            })
        }
        const deleteOption = () => {

            let tempArr = optionsArr.filter((el, index) => index != (optionsArr.length - 1))
            setOptionsArr(tempArr)
        }

        const setOptionText = (val, index) => {
            setOptionsArr(arr => [...arr.map((el, i) => i == index ? { ...el, optionText: val } : el)])
        }

        const setPointsAssigned = (val, index) => {
            setOptionsArr(arr => [...arr.map((el, i) => i == index ? { ...el, pointsAssigned: val } : el)])
        }



        useEffect(() => {
            // console.log(levelArr)
            if (levelArr && levelArr.length > 0) {
                if (levelArr[levelArr.length - 1]?.s_no) {
                    let serialNo = levelArr[levelArr.length - 1]?.s_no;
                    if (serialNo) {
                        let tempSerialNo = parseInt(serialNo.slice(1))
                        setSerialNumber(`L${tempSerialNo + 1}`)
                    }
                }
            }
            else {
                setSerialNumber("L1")
            }
            addOption()
            addDescription()
        }, [levelArr])

        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className={styles.Modal} style={{ height: 600 }}>
                    <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                    <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                    <div className={styles.ModalInnerBox}>
                        <>
                            <div className={styles.modalHeading}>
                                Add Level
                            </div>

                            <input type="text" value={serialNumber} disabled onChange={(e) => setSerialNumber(e.target.value)} placeholder="Serial Number (Automated)" className={styles.modalTextInput} />

                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name of Level" className={styles.modalTextInput} />
                            
                            <label className={`${styles.modalTextInputLikeBtn}`}>
                                <span className={`${styles.fileInputText}`}>
                                    Description
                                </span>
                                <div>
                                    <img onClick={() => { addDescription() }} style={{ marginRight: 10 }} src={plus} alt="" />
                                    <img onClick={() => { deleteDescription() }} src={minus} alt="" />
                                </div>
                            </label>
                            {
                                additionalDetailsArr && additionalDetailsArr.map((el, index) => {
                                    return (
                                        <div className={styles.flexRowBetween} key={index}>
                                            <input type="text" value={el?.value} onChange={(e) => setDescriptionText(e.target.value, index)} placeholder="Option Text" className={styles.modalTextInput} />
                                        </div>
                                    )
                                })
                            }

                            {/* <textarea type="text" value={additionalDetailsArr} multiple={true} onChange={(e) => setAdditionalDetailsArr(e.target.value)} placeholder="Level Description" className={styles.modalTextInput} style={{ height: 80 }} /> */}

                            {/* <input type="text" value={extraOptions} onChange={(e) => setExtraOptions(e.target.value)} placeholder="" className={styles.modalTextInput} /> */}

                            {/* <p className={`${styles.modalNoteText}`}>
                                Please don't add Already Learnt Option. It'll be added by default
                            </p> */}

                            {/* <label className={`${styles.modalTextInputLikeBtn}`}>
                                <span className={`${styles.fileInputText}`}>
                                    Options
                                </span>
                                <div>
                                    <img onClick={() => { addOption() }} style={{ marginRight: 10 }} src={plus} alt="" />
                                    <img onClick={() => { deleteOption() }} src={minus} alt="" />
                                </div>
                            </label> */}
                            {/* {
                                optionsArr && optionsArr.map((el, index) => {
                                    return (
                                        <div className={styles.flexRowBetween} key={index}>
                                            <input type="text" value={el?.optionText} onChange={(e) => setOptionText(e.target.value, index)} placeholder="Option Text" className={styles.modalTextInputHalfWidth} />
                                            <input type="text" value={el?.pointsAssigned} onChange={(e) => setPointsAssigned(e.target.value, index)} placeholder="Points Assigned" className={styles.modalTextInputHalfWidth} />

                                        </div>
                                    )
                                })
                            } */}

                            <label htmlFor="fileInput" className={`${styles.modalTextInput} ${styles.cameraBackgroundRight}`}>
                                <span className={`${styles.fileInputText}`}>
                                    {fileName ? `${fileName.toString().slice(0, 25)}...` : 'Upload Interactive Video'}
                                </span>
                                <input type="file" id="fileInput" onChange={(e) => { setFile(e.target.files[0]); setFileName(e.target.files[0].name); }} placeholder="Upload Interactive Video" style={{ display: 'none' }} />
                            </label>
                        </>

                        <button onClick={() => HandleSubmit()} style={{ marginTop: 10, marginBottom: 30, borderRadius: 4 }} className="blueBtnRegister">
                            Add
                        </button>

                    </div>
                </Modal.Body>

            </Modal >
        );
    }
    /////////////////////////////////////////// edit Level ///////////////////////////////////////////////////////////////////////////////////////////////////////

    function MyVerticallyCenteredModal3({ handleEditLevel, levelObj, ...props }) {
        const [serialNumber, setSerialNumber] = useState("");
        const [name, setName] = useState("");
        const [additionalDetailsArr, setAdditionalDetailsArr] = useState([]);
        const [file, setFile] = useState();
        const [fileName, setFileName] = useState("");
        const [optionsArr, setOptionsArr] = useState([]);

        const HandleSubmit = () => {
            let obj = {
                s_no: serialNumber,
                name: name,
                description: additionalDetailsArr.reduce((acc,el)=> acc != "" ? `${acc}&&&${el.value}` : `${acc}${el.value}`,''),
                activity_id: parseInt(id.id),
                status: 1,
                media: file?.name ? file : "",
                option_id: optionsArr.map(el => el.id),
                option_text: optionsArr.map(el => el.option_text),
                points: optionsArr.map(el => el.points),
            }
            handleEditLevel(obj)
            props.onHide()
        }

        const addDescription = () => {
            setAdditionalDetailsArr(previousValue => {
                return [...previousValue, { value: "" }]
            })
        }
        const deleteDescription = () => {
            let tempArr = additionalDetailsArr.filter((el, index) => index != (additionalDetailsArr.length - 1))
            setAdditionalDetailsArr(tempArr)
        }

        const setDescriptionText = (val, index) => {
            setAdditionalDetailsArr(arr => [...arr.map((el, i) => i == index ? { ...el, value: val } : el)])
        }




        const addOption = () => {
            setOptionsArr(previousValue => {
                return [...previousValue, { id: "", moptionText: "", pointsAssigned: "" }]
            })
        }
        const deleteOption = () => {

            let tempArr = optionsArr.filter((el, index) => index != (optionsArr.length - 1))
            setOptionsArr(tempArr)
        }


        const setOptionText = (val, index) => {
            console.log(index)
            setOptionsArr(arr => [...arr.map((el, i) => i == index ? { ...el, option_text: val } : el)])
        }

        const setPointsAssigned = (val, index) => {
            setOptionsArr(arr => [...arr.map((el, i) => i == index ? { ...el, points: val } : el)])
        }

        useEffect(() => {
            setSerialNumber(selectedLevel?.s_no);
            setAdditionalDetailsArr(`${selectedLevel?.description}`.split("&&&").map(el => {
                let obj = {
                    value: el
                }
                return obj
            }));
            setName(selectedLevel?.name);
            setFile(selectedLevel?.media);
            setFileName(selectedLevel?.media);
            setOptionsArr(selectedLevel?.options);
        }, [])


        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className={styles.Modal} style={{ height: 610 }}>
                    <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                    <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                    <div className={styles.ModalInnerBox}>
                        <>
                            <div className={styles.modalHeading}>
                                Edit Level
                            </div>

                            <input type="text" value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} placeholder="Serial Number (Automated)" className={styles.modalTextInput} />

                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name of Level" className={styles.modalTextInput} />
                            <label className={`${styles.modalTextInputLikeBtn}`}>
                                <span className={`${styles.fileInputText}`}>
                                    Description
                                </span>
                                <div>
                                    <img onClick={() => { addDescription() }} style={{ marginRight: 10 }} src={plus} alt="" />
                                    <img onClick={() => { deleteDescription() }} src={minus} alt="" />
                                </div>
                            </label>
                            {
                                additionalDetailsArr && additionalDetailsArr.map((el, index) => {
                                    return (
                                        <div className={styles.flexRowBetween} key={index}>
                                            <input type="text" value={el?.value} onChange={(e) => setDescriptionText(e.target.value, index)} placeholder="Option Text" className={styles.modalTextInput} />
                                        </div>
                                    )
                                })
                            }
                            {/* <textarea type="text" value={additionalDetails} multiple={true} onChange={(e) => setAdditionalDetails(e.target.value)} placeholder="Level Description" className={styles.modalTextInput} style={{ height: 80 }} /> */}

                            {/* <input type="text" value={extraOptions} onChange={(e) => setExtraOptions(e.target.value)} placeholder="" className={styles.modalTextInput} /> */}
                            {/* <label className={`${styles.modalTextInputLikeBtn}`}>
                                <span className={`${styles.fileInputText}`}>
                                    Options
                                </span>
                                <div>
                                    <img onClick={() => { addOption() }} style={{ marginRight: 10 }} src={plus} alt="" />
                                    <img onClick={() => { deleteOption() }} src={minus} alt="" />
                                </div>
                            </label> */}
                            {/* {
                                optionsArr && optionsArr.map((el, index) => {
                                    return (
                                        <div className={styles.flexRowBetween} key={index}>
                                            <input type="text" disabled={el?.extra == "aready_learnt"} value={el.option_text} onChange={(e) => setOptionText(e.target.value, index)} placeholder="Option Text" className={styles.modalTextInputHalfWidth} />
                                            <input type="text" disabled={el?.extra == "aready_learnt"} value={el.points} onChange={(e) => setPointsAssigned(e.target.value, index)} placeholder="Points Assigned" className={styles.modalTextInputHalfWidth} />
                                        </div>
                                    )
                                })
                            } */}

                            <label htmlFor="fileInput" className={`${styles.modalTextInput} ${styles.cameraBackgroundRight}`}>
                                <span className={`${styles.fileInputText}`}>
                                    {fileName ? `${fileName.toString().slice(0, 25)}...` : 'Upload Interactive Video'}
                                </span>
                                <input type="file" id="fileInput" onChange={(e) => { setFile(e.target.files[0]); setFileName(e.target.files[0].name); }} placeholder="Upload Interactive Video" style={{ display: 'none' }} />
                            </label>
                        </>
                        <button onClick={() => HandleSubmit()} style={{ marginTop: 10, marginBottom: 30, borderRadius: 4 }} className="blueBtnRegister">
                            Update
                        </button>
                    </div>
                </Modal.Body>

            </Modal>
        );
    }


    ////////////////////////////////////////// Edit Acitivity ///////////////////////////////////////////////////////////////////////////////////////////////////////

    function MyVerticallyCenteredModal4({ activityObj, handleedit, ...props }) {
        const [serialNumber, setSerialNumber] = useState(1);
        const [name, setName] = useState("");
        const [additionalDetails, setAdditionalDetails] = useState("");
        const [extraOptions, setExtraOptions] = useState("");
        const [file, setFile] = useState();
        const [fileName, setFileName] = useState("");
        const [day, setDay] = useState("");
        const handleActivityEdit = () => {
            if (name == "") {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please Enter Activity Name`,
                });
            }
            else if (additionalDetails == '') {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please Enter Activity Description`,
                });
            }
            else if (day == '') {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please Enter Day`,
                });
            }
            else {
                if (file?.name) {
                    let obj = {
                        s_no: serialNumber,
                        name,
                        additionalDetails,
                        extraOptions,
                        file,
                        day: parseInt(day),
                        milestone_id: activityObj.milestone_id,
                    }
                    handleedit(obj);
                    setFile(null);
                    setAdditionalDetails('');
                    setExtraOptions('');
                    setName('');
                    setDay(0);
                    props.onHide()

                }
                else {
                    let obj = {
                        name,
                        s_no: serialNumber,
                        additionalDetails,
                        extraOptions,
                        day: parseInt(day),
                        milestone_id: activityObj.milestone_id,
                    }
                    handleedit(obj);
                    setFile(null);
                    setAdditionalDetails('');
                    setExtraOptions('');
                    setSerialNumber("");
                    setName('');
                    setDay(0);
                    props.onHide()

                }
            }
        }
        useEffect(() => {
            if (activityObj && activityObj.id) {
                setSerialNumber(activityObj.s_no);
                setName(activityObj.name);
                setAdditionalDetails(activityObj.description);
                setFileName(activityObj.media);
                setExtraOptions(activityObj.extra);
                setDay(parseInt(activityObj.day));
            }
        }, [activityObj])
        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className={styles.Modal} style={{ height: 640 }}>
                    <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                    <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                    <div className={styles.ModalInnerBox}>
                        <>

                            <div className={styles.modalHeading}>
                                Edit Activity
                            </div>

                            <input type="text" value={serialNumber} disabled onChange={(e) => setSerialNumber(e.target.value)} placeholder="Serial Number (Automated)" className={styles.modalTextInput} />
                            <input type="number" value={day} onChange={(e) => setDay(e.target.value)} placeholder="Set Day" className={styles.modalTextInput} />

                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Walking Through Complex Maze" className={styles.modalTextInput} />

                            <textarea type="text" value={additionalDetails} multiple={true} onChange={(e) => setAdditionalDetails(e.target.value)} placeholder="Walk through a simple 4 route maze." className={styles.modalTextInput} style={{ height: 80 }} />

                            <input type="text" value={extraOptions} onChange={(e) => setExtraOptions(e.target.value)} placeholder="Cardboard boxes, scissors" className={styles.modalTextInput} />

                            <label htmlFor="fileInput" className={`${styles.modalTextInput} ${styles.cameraBackgroundRight}`}>
                                <span className={`${styles.fileInputText}`}>
                                    {fileName ? `${fileName.toString().slice(0, 25)}...` : 'Upload Interactive Video'}
                                </span>
                                {/* setFile(e.target.files[0]) */}
                                <input type="file" id="fileInput" onChange={(e) => { setFile(e.target.files[0]); setFileName(e.target.files[0].name) }} placeholder="Upload Interactive Video" style={{ display: 'none' }} />
                            </label>
                        </>

                        <button onClick={() => handleActivityEdit()} style={{ marginTop: 10, marginBottom: 30, borderRadius: 4 }} className="blueBtnRegister">
                            Update
                        </button>

                    </div>
                </Modal.Body>

            </Modal>
        );
    }


    /////////////////////////////////////////// Delete Level ///////////////////////////////////////////////////////////////////////////////////////////////////////

    function MyVerticallyCenteredModal2({ handleDelete, ...props }) {

        // const [optionsActive, setOptionsActive] = useState(false);

        const deleteConfirmation = () => {
            handleDelete()
            props.onHide()
        }


        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className={styles.Modal} style={{ height: 410 }}>
                    <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                    <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                    <div className={`${styles.ModalInnerBox} pt-4`} style={{ height: 320 }}>
                        <>
                            <img src={DeleteActivity} className={styles.deleteModalIcon} alt="" />

                            <div className={styles.modalHeading} style={{ textAlign: "center" }}>
                                Delete Level
                            </div>



                            <div className={styles.ModalTxt}>
                                Are you sure you want to delete
                                this activity? The next activity will
                                take its place in the list.
                            </div>

                            <div className={`${styles.flexRowBetween} mt-4`}>


                                <button onClick={() => deleteConfirmation()} style={{ width: "45%", marginTop: 10, marginRight: "2%", marginBottom: 30, borderRadius: 4 }} className="blueBtnRegister">
                                    Yes
                                </button>
                                <button onClick={() => props.onHide()} style={{ width: "45%", marginTop: 10, marginBottom: 30, borderRadius: 4, backgroundColor: "#FF6F96" }} className="blueBtnRegister">
                                    No
                                </button>
                            </div>
                        </>
                    </div>
                </Modal.Body>

            </Modal>
        );
    }





















    useEffect(() => {
        getActivity()
        getLevelArr()
    }, [])

    return (
        <div>
            <div className={`${styles.header}`}>
                <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div>


                <div className={`${styles.headerHeading}`}>
                    Levels for  {activityObj?.name}
                </div>
                <div className={styles.btn} onClick={() => setEditActivityModalShow(true)}>
                    <img src={edit} alt="" />
                </div>
            </div>
            <div className={`${styles.containerdiv} container`}>

                <div className="row d-flex justify-content-center">
                    {
                        loading ?
                            <>
                                <div className="row mt-5 justify-content-center d-flex">
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Loader
                                            visible={true}
                                            type="TailSpin"
                                            color="#FF6F96"
                                            height={100}
                                            width={100}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className={`${styles.milestoneCard} col-lg-7 col-11`} >
                                    <div className={styles.milestonesHeading}>
                                        Level List
                                    </div>
                                    {
                                        levelArr && levelArr.length > 0 ?
                                            levelArr.map((el, index) => {
                                                return (
                                                    <div key={el.id} className={styles.milestonesCardItem}>
                                                        <div className={styles.milestonesCardItemTxt}>{index + 1}. {el?.name}</div>
                                                        <div>
                                                            <img onClick={() => { setSelectedLevel(el); setModalShow2(true) }} style={{ height: 25, width: 25, marginRight: 15 }} className={styles.milestonesCardItemIcon} src={deleteIcon} alt="" />
                                                            <img onClick={() => { setSelectedLevel(el); setEditLevelModalShow(true) }} style={{ height: 25, width: 25, marginRight: 15 }} className={styles.milestonesCardItemIcon} src={editIcon} alt="" />
                                                        </div>
                                                    </div>

                                                )
                                            })
                                            :
                                            <>
                                                <div className={styles.milestonesCardItem}>
                                                    <div className={styles.milestonesCardItemTxt}>No Levels found for this Activity</div>
                                                </div>
                                            </>
                                    }



                                </div>

                                <div className="col-12 d-flex justify-content-center mt-5">

                                    <button onClick={() => { setModalShow(true) }} className={styles.addBtn}>
                                        Add Level
                                    </button>
                                </div>
                            </>
                    }
                </div>

            </div>

            <MyVerticallyCenteredModal
                handleadd={handleLevelAdd}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

            <MyVerticallyCenteredModal2
                handleDelete={handleLevelDelete}
                show={modalShow2}
                onHide={() => setModalShow2(false)}
            />


            <MyVerticallyCenteredModal3
                handleEditLevel={handleLevelEdit}
                levelObj={selectedLevel}
                show={editLevelModalShow}
                onHide={() => setEditLevelModalShow(false)}
            />


            <MyVerticallyCenteredModal4
                handleedit={handleActivityEdit}
                activityObj={activityObj}
                show={editActivityModalShow}
                onHide={() => setEditActivityModalShow(false)}
            />

            <Adminhomepagefooter />
        </div>
    )
}
