import axios from "axios";
import { Authorisation, url } from "./Url";

const serverUrl = `${url}csm`;
const csmServerUrl = `${url}csmUser`;

let token = localStorage.getItem("ADMIN_AUTH_TOKEN");

const authorisation = `Bearer ${token}`;

export const addCSM = async (obj) => {
  try {
    let config = {
      headers: {
        Authorization: authorisation,
      },
    };
    let res = await axios.post(`${serverUrl}`, obj, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCsmChartDataByAge = async (id, Age) => {
  try {
    let res = await axios.get(
      `${csmServerUrl}/user/${id}/chart?age=${Age}`
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getCsmChartDataByMonth = async (id, Month) => {
  try {
    let res = await axios.get(
      `${csmServerUrl}/user/${id}/age-chart?month=${Month}`
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCSMUsersAge = async (id) => {
  try {
    let res = await axios.get(`${csmServerUrl}/user/${id}/age`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getCSM = async () => {
  try {
    let config = {
      headers: {
        Authorization: authorisation,
      },
    };
    let res = await axios.get(`${serverUrl}`, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCSMCustomers = async (id) => {
  try {
    let config = {
      headers: {
        Authorization: authorisation,
      },
    };
    let res = await axios.get(`${url}csm-user/${id}`, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getCSMCustomersFromCsm = async (id) => {
  try {
    let config = {
      headers: {
        Authorization: authorisation,
      },
    };
    let res = await axios.get(`${csmServerUrl}/myUser`, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getCSMCustomersFromCsmDetails = async (id) => {
  try {
    let config = {
      headers: {
        Authorization: authorisation,
      },
    };
    let res = await axios.get(`${url}csmUser/myUser/${id}/detail`, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getCSMProfile = async () => {
  try {
    let config = {
      headers: {
        Authorization: authorisation,
      },
    };
    let res = await axios.get(`${url}csmUser/my-profile`, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const updateCSMprofile = async (obj) => {
  try {
    let config = {
      headers: {
        Authorization: authorisation,
      },
    };
    let res = await axios.post(`${url}csmUser/profile-update`, obj, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const UpdateUserCsm = async (obj) => {
  try {
    let config = {
      headers: {
        Authorization: authorisation,
      },
    };
    let res = await axios.post(`${url}update-user-csm`, obj, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCsmUserChartData = async (id) => {
  try {
    let res = await axios.get(`${url}csmUser/user/${id}/chart`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCSMDetails = async (id) => {
  try {
    let config = {
      headers: {
        Authorization: authorisation,
      },
    };
    let res = await axios.get(`${url}csm/${id}`, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getMilestonesForCSM = async () => {
  try {
    let config = {
      headers: {
        Authorization: authorisation,
      },
    };
    let res = await axios.get(`${url}csmUser/milestone`, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCustomPlanFromCSM = async (id) => {
  try {
    let res = await axios.get(`${url}csmUser/custom-plan/${id}`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const setCustomPackageFromCSM = async (obj) => {
  try {
    let token = localStorage.getItem("ADMIN_AUTH_TOKEN");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let res = await axios.post(`${url}csmUser/custom-plan`, obj, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
