import React, { useRef, useEffect, useState, useContext } from 'react'


//////css
import './HomeScreen.css'

import Loader from "react-loader-spinner";

import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2';
///////bootstrap 
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Accordion from 'react-bootstrap/Accordion'
import "bootstrap/dist/css/bootstrap.min.css";


import { AuthContext, jwtContext } from '../../App';
import { userLogin, getOtpFromNumber, registerUser, handleOrderPlan, userLoginV2 } from '../../Services/User';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
///////images
import logo from '../../assets/img/logo.png'
import facebook from '../../assets/img/facebook.png'
import linkedIn from '../../assets/img/linkedin.png'
import twitter from '../../assets/img/twitter.png'
import freePlan from "../../assets/img/freePlan.png"
import paidPlan from "../../assets/img/paidPlan.png"
import youtube from '../../assets/img/youtube.png'
import favcyLogo from "../../assets/img/favcyLogo.png"
import baby from "../../assets/img/baby.png"
import whyCt1 from '../../assets/img/whyCompassTot.png'
import whyCt2 from '../../assets/img/whycompassTot1.png'
import whyCt3 from '../../assets/img/whyCompassTot2.png'
import featureImg1 from "../../assets/img/featureImg1.png"
import featureImg2 from "../../assets/img/featureImg2.png"
import featureImg3 from "../../assets/img/featureImg3.png"
import partnerWithUsImage1 from "../../assets/img/partnerWithUs1.png"
import partnerWithUsImage2 from "../../assets/img/partnerWithUs2.png"
import greenCheck from "../../assets/img/greenCheck.png"
import cancel from "../../assets/img/cancel.png"
import cloud from "../../assets/img/clouds.png"
import kid from "../../assets/img/kid.png"
import faqTopImg from "../../assets/img/faqTopImg.png"
import faqBottomLeftImg from "../../assets/img/faqBottomLeftImg.png"
import registerComplete from "../../assets/img/registerComplete.png"

import videoPlayBtn from '../../assets/img/videoPlayBtn.png';
import facebookLogo from '../../assets/img/facebookLogo.png'
import mobilephone from '../../assets/img/mobilephone.png'
import favcyLogo2 from "../../assets/img/favcyLogo2.png"


////////router
import { Link, useHistory } from "react-router-dom";
import { getAgeData } from '../../Services/Test';
import { getFreePackages, getPackages } from '../../Services/Packages';

export default function Homescreen() {


    const [modalShow, setModalShow] = useState(false);
    const [modalShowRegister, setModalShowRegister] = useState(false);
    const [modalShowSendOtp, setModalShowSendOtp] = useState(false);
    const [modalShowVerifyOtp, setModalShowVerifyOtp] = useState(false);
    const HowItWorksRef = useRef()
    const history = useHistory();
    const [mobile, setMobile] = useState("");
    const [gToken, setGToken] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState();
    const [stepcount, setStepcount] = useState(0);
    const [freePackIsSelected, setFreePackIsSelected] = useState(true);
    const [freePackageArr, setFreePackageArr] = useState([]);
    const [packagesArr, setPackagesArr] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({});
    const [selectedPlan, setSelectedPlan] = useState(0);
    const [registerObj, setRegisterObj] = useState({});
    const [favcyPaymentResponse, setFavcyPaymentResponse] = useState("");
    const [adminAuthToken, setAdminAuthToken] = useState('');
    const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
    const [decodedObj, setDecodedObj] = useContext(jwtContext);
    const [ageArr, setAgeArr] = useState([]);
    const whyCompassTotRef = useRef(null)
    const compassTotFeaturesRef = useRef(null)
    const partnerWithUsRef = useRef(null)
    const pricingRef = useRef(null)
    const FaqRef = useRef(null)
    const [selectedAge, setSelectedAge] = useState(0);
    const [selectedAgeDataObj, setSelectedAgeDataObj] = useState({});
    const [ageWiseDataArr, setAgeWiseDataArr] = useState([
        {
            startAge: 25,
            endAge: 36,
            dataArr: [
                "Helping your 2 to 3 year old develop skills is crucial, as there is so much going on during this phase. Children are expected to learn several important skills, such as standing on tiptoes, standing on one leg momentarily, walking upstairs, throwing underarm, kicking a ball, jumping down from small heights, turning single pages, building a small tower of blocks, learning about body parts, solving simple jigsaw puzzles, recognizing & naming objects, putting on simple items of clothing etc. These skills & many more, individually & jointly, facilitate achievement of several age-appropriate developmental milestones. ",
                "Also, Children in this phase may not always retain their immediate learnings. Thus, we need to build their skills over a period of time, for properly reinforcing them i.e. build stronger neural connections, by establishing a sturdy foundation for all learning, health & behaviour that follow.",
            ]
        },
        {
            startAge: 37,
            endAge: 48,
            dataArr: [
                "In this year, your child will really enjoy physical activities and is expected to learn several important skills, such as standing on one foot for a few seconds, walking downstairs, running confidently, broad jumping, kicking a ball with control, climbing, riding a tricycle, walking along a plank, throwing overarm, using a slide, skipping obstacles, hopping a few times, turning smaller knobs, joining pieces of puzzle, wiggling thumb, folding a paper, counting & sorting objects based on colours & shapes, playing pretend & fantasy play, forming ‘why’ questions etc. These skills & many more, individually & jointly, facilitate achievement of several age-appropriate developmental milestones.",
                "Thus, imparting these skills to your Child via fun, yet developmentally appropriate activities becomes important. Moreover, as per your Child’s unique developmental journey, curating & spacing out these activities in appropriate order of complexity, to give the necessary stimulation & exposure to your Child, becomes even more important.",
                "Also, Children in this phase may not always retain their immediate learnings. Thus, we need to build their skills over a period of time, for properly reinforcing them i.e. build stronger neural connections, by establishing a sturdy foundation for all learning, health & behaviour that follow.",
            ]
        },
        {
            startAge: 49,
            endAge: 60,
            dataArr: [
                "There is so much going on during this phase, as your child is expected to learn & fine tune several important skills, such as jumping over objects, hopping with control, skipping, jumping backwards, swinging from playground equipment, bouncing a ball, throwing a ball with control, catching a ball with control, counting objects, writing alphabets & numbers, cutting with scissors with control, drawing various shapes & pictures, understanding positional words (like above, below, between), understanding opposite words (like wet & dry, soft & hard), dressing independently, brushing teeth independently etc. These skills & many more, individually & jointly, facilitate achievement of several age-appropriate developmental milestones. ",
                "Thus, imparting these skills to your Child via fun, yet developmentally appropriate activities becomes important. Moreover, as per your Child’s unique developmental journey, curating & spacing out these activities in appropriate order of complexity, to give necessary stimulation & exposure to your Child, becomes even more important.",
                "Also, Children in this phase may not always retain their immediate learnings. Thus, we need to build their skills over a period of time, for properly reinforcing them i.e. build stronger neural connections, by establishing a sturdy foundation for all learning, health & behaviour that follow.",
            ]
        }
    ]);





    const scrollToWhyCompassTot = () => {
        whyCompassTotRef.current.scrollIntoView()
    }

    const getAge = async () => {
        try {
            let res = await getAgeData()
            if (res.status === 200 || res.status === 304) {
                setAgeArr([{ id: 0, age_group: "Select Age of Your Child to Know More" }, ...res.data])
                localStorage.setItem("age", "Select Age of Your Child to Know More")
            }
        }
        catch (err) {
            console.error(err)
            alert(err)
        }
    }

    const handleRedirectToDash = () => {
        setIsAuthorized(true)
        let role = localStorage.getItem("role")
        if (role) {

            if (role == "admin") {
                let path = `/Admin-Dashboard`;
                history.push(path);
            }
            else if (role == "user") {
                let path = `/Dashboard`;
                history.push(path);
            }
            else if (role == "csm") {
                let path = `/CSM-Manage-Children`;
                history.push(path);
            }
        }
    }






    useEffect(() => {
        getAge()
    }, [])




    const handleRedirect = async () => {
        let age = localStorage.getItem("age")
        console.log(age)
        if (age == "Select your child's age") {
            alert("please select an age")
        }
        else {
            let path = "test"
            history.push(path)
        }
    }





    const getOtp = async (obj) => {
        try {
            console.log("asd")
            setLoading(true)
            /////////////if user is not found then we will have to check status 201 if that's the case we will render register modal here
            if (obj.mobile == "9873103818") {
                let formData = new FormData()
                formData.append("mobile", parseInt("9873103818"))
                formData.append("country_code", parseInt(91))
                let res = await getOtpFromNumber(obj)
                console.log(res, "asda")
                if (res.data.token) {
                    localStorage.setItem("ADMIN_AUTH_TOKEN", res.data.token);
                    let tempObj = {
                        role: res?.data?.user?.roles[0]?.name
                    }
                    console.log(tempObj)
                    setDecodedObj(tempObj)
                    localStorage.setItem("role", res?.data?.user?.roles[0]?.name);
                    setIsAuthorized(true)
                    handleRedirectToDash()
                }
            }
            else {
                let res = await getOtpFromNumber(obj)
                console.log(res)
                if (res.status == 200 || res.status == 304) {
                    Swal.fire({
                        title: 'Success',
                        type: 'success',
                        text: `${res?.data?.data?.data?.message}`,
                    });
                    setGToken(res.data.guest_token)
                    setLoading(false)
                    setModalShowVerifyOtp(true);
                    setModalShowSendOtp(false);
                }
                else if (res.status == 201) {
                    Swal.fire({
                        title: 'User Not Found',
                        type: 'warning',
                        text: `Please Register yourself with Compasstot`,
                    });
                    setLoading(false);
                    console.log(res.data.data);
                    setModalShowSendOtp(false);
                    setModalShowRegister(true);
                }
                setMobile(obj.mobile)
                setPhoneNumber(obj.mobile)
            }

        }
        catch (err) {
            console.error(err?.response);
            if (err?.response?.data?.message) {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.data}`,
                });
                setLoading(false)

            }
            else {
                console.log(err.response)
                // alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)

            }
        }
    }


    const loginUser = async (obj) => {
        try {
            setLoading(true)

            let formData = new FormData()
            formData.append("mobile", parseInt(obj.mobile))
            formData.append("country_code", parseInt(91))
            formData.append("guest_token", obj.guest_token)
            formData.append("otp", obj.otp)

            let res = await userLogin(formData)
            console.log(res, "Login Res")
            if (res.status == 201 || res.status == 200 || res.status == 304) {
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.message}`,
                });
                setLoading(false)
                setModalShowVerifyOtp(false);
                localStorage.setItem("ADMIN_AUTH_TOKEN", res.data.token);
                localStorage.setItem("FAVCY_AUTH_TOKEN", res.data.auth_token);
                let tempObj = {
                    role: res?.data?.user?.roles[0]?.name
                }
                console.log(tempObj)
                setDecodedObj(tempObj)
                localStorage.setItem("role", res?.data?.user?.roles[0]?.name);
                if (res?.data?.user?.roles[0]?.name == "csm") {
                    setIsAuthorized(true)
                    handleRedirectToDash()
                }
                else {
                    if (res.data.valid_package == false) {
                        setModalShowRegister(true)
                        setStepcount(2)
                    }
                    else if (res.data.free_test == false) {
                        localStorage.setItem("userObj", JSON.stringify(res.data.user))
                        localStorage.setItem("age", res.data.user.age)
                        handleRedirectToDash()
                    }
                    else {
                        setIsAuthorized(true)
                        handleRedirectToDash()
                    }
                }
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.log(err)
                alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)

            }
        }
    }



    const loginUser2 = async (obj) => {
        try {
            setLoading(true)
            console.log(obj, "otp object")
            let formData = new FormData()
            formData.append("mobile", parseInt(obj.mobile))
            formData.append("country_code", parseInt(91))
            formData.append("guest_token", obj.guest_token)
            formData.append("otp", parseInt(obj.otp))

            let res = await userLogin(formData)
            console.log(res.data, "login Response")
            if (res.status == 201 || res.status == 200 || res.status == 304) {
                setLoading(false)
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.message}`,
                });
                setAdminAuthToken(res.data.token)
                localStorage.setItem("ADMIN_AUTH_TOKEN", res.data.token);
                localStorage.setItem("FAVCY_AUTH_TOKEN", res.data.auth_token);

                let tempObj = {
                    role: res?.data?.user?.roles[0]?.name
                }

                setDecodedObj(tempObj)
                localStorage.setItem("role", res?.data?.user?.roles[0]?.name);
                setStepcount(2)

            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                setLoading(false)

                console.log(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.log(err)
                setLoading(false)

                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });

            }
        }
    }





    const handleOrderCreate = async (couponcode) => {
        try {
            setLoading(true)
            console.log(selectedPackage.type)
            let favcyToken = localStorage.getItem("FAVCY_AUTH_TOKEN")
            let obj = {
                coupon_code: couponcode,
                package_id: selectedPackage.id,
                type: selectedPackage.type,
                auth_token: favcyToken,
            }
            console.log(obj, "object")
            console.log(adminAuthToken)
            let res = await handleOrderPlan(obj, adminAuthToken);
            console.log(res)
            if (res.status == 200 || res.status == 201 || res.status == 304) {
                if (selectedPackage.type == "free") {
                    setIsAuthorized(true)
                    handleRedirectToDash()
                }
                else {
                    console.log(res.data)
                    setLoading(false)
                    // handleFavcyPay(res.data)
                    localStorage.setItem("orderResponse", JSON.stringify(res.data))
                    history.push("/payments")
                }
            }

        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message);
                setLoading(false)

                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.log(err)
                setLoading(false)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });

            }
        }
    }


    function MyVerticallyCenteredModal3({ handleregisterwithotp, handleOrderCreateFunc, handleOtpEntry, ...props }) {

        const [childNameValue, setChildNameValue] = useState("");

        const [dob, setDob] = useState('');
        const [yourname, setYourname] = useState('');
        const [email, setEmail] = useState("");
        const [mobile, setMobile] = useState('');
        const [responseObj, setResponseObj] = useState("");//////register Response
        const [phone, setPhone] = useState(0);
        const [otp, setOtp] = useState("");
        const [couponCode, setCouponCode] = useState("");




        const handleOtpSubmit = () => {
            let obj = {
                mobile: registerObj.mobile,
                country_code: parseInt(91),
                guest_token: gToken,
                otp: otp,
            }
            console.log(obj, "otp submit")
            handleOtpEntry(obj)
        }




        const handleInput = async (obj) => {
            try {
                setLoading(true)

                if (mobile.length != 10) {
                    alert("Please Enter a valid Number")
                    setLoading(false)
                }
                else {
                    let obj = {
                        email,
                        dob: new Date(dob).toISOString().substring(0, 10),
                        mobile,
                        name: yourname,
                        child_name: childNameValue,
                        package_id: selectedPackage.id,
                        type: freePackIsSelected ? "free" : "paid"
                    }
                    let res = await registerUser(obj);
                    if (res.status == 200 || res.status == 201 || res.status == 304) {
                        setStepcount(1)
                        setGToken(res.data.guest_token)
                        setRegisterObj(obj)
                        setLoading(false)
                    }
                }

            }
            catch (err) {
                // console.log(obj, " sa object ")
                // console.log(JSON.stringify(err.response, null, 2))
                if (err?.response?.data) {
                    console.error(err?.response?.data);
                    let errStr = Object.values(err?.response?.data).flatMap(el => el).reduce((acc, el) => acc + el + ' ', '')
                    Swal.fire({
                        title: 'Error',
                        type: 'error',
                        text: `${errStr}`,
                    })
                    setLoading(false)
                }
                else {
                    console.error(err)
                    Swal.fire({
                        title: 'Error',
                        type: 'error',
                        text: `${err?.message}`,
                    });
                    setLoading(false)
                }
            }
        }





        // const handleInput = async () => {
        //     try {
        //                     }
        //     catch (err) {
        //         if (err?.response?.data?.message) {
        //             console.error(err?.response?.data?.message);
        //             Swal.fire({
        //                 title: 'Error',
        //                 type: 'error',
        //                 text: `${err?.response?.data?.message}`,
        //             });
        //         }
        //         else {
        //             console.error(err)
        //             Swal.fire({
        //                 title: 'Error',
        //                 type: 'error',
        //                 text: `${err?.message}`,
        //             });
        //         }
        //     }
        // }


        const handleOrderCreateInModal = () => {
            handleOrderCreateFunc(couponCode)
        }


        const checkSelectedPackage = (id) => {
            if (selectedPackage.id == id) {
                return true
            }
            else
                return false
        }
        const checkPaidPackage = () => {
            // if (freePackIsSelected == false) {
            setStepcount(3)
            // }
        }

        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="RegisterModal" style={{ height: stepcount === 0 ? 500 : stepcount === 1 ? 250 : 200, width: stepcount === 3 && 400 }}>
                    <div className="RegisterModalInnerBox">
                        {
                            loading ?
                                <>
                                    <div className="row justify-content-center d-flex">
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Loader
                                                visible={true}
                                                type="TailSpin"
                                                color="#FF6F96"
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                    </div>
                                </>
                                :
                                stepcount === 0
                                    ?

                                    <>
                                        <div className="RegisterModalHeading">Enter Your Child’s Details</div>
                                        <input type="text" onChange={(e) => setChildNameValue(e.target.value)} placeholder="Your Child’s Full Name" className="registerModalTextInput" />
                                        <input onChange={(e) => setDob(e.target.value)} className="registerModalTextInput" type="date" placeholder="Your Child’s Age (DOB) " />
                                        {/* <img alt="chevronDown" style={{ height: 10, width: 10 }} src={chevronDownwithoutBg} /> */}
                                        <input type="text" onChange={(e) => setYourname(e.target.value)} placeholder="Your Name" className="registerModalTextInput" />
                                        <input type="text" onChange={(e) => { setMobile(e.target.value) }} placeholder="Your Mobile Number" className="registerModalTextInput" />
                                        <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder="Your Email Address" className="registerModalTextInput" />
                                        <button style={{ marginTop: 30 }} onClick={() => { handleInput() }} className="blueBtnRegister">
                                            Next
                                        </button>
                                    </>

                                    :
                                    stepcount === 1 ?
                                        <>
                                            {
                                                loading ?
                                                    <>
                                                        <div className="row mt-5 justify-content-center d-flex">
                                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                <Loader
                                                                    visible={true}
                                                                    type="TailSpin"
                                                                    color="#FF6F96"
                                                                    height={100}
                                                                    width={100}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <p className="txtUpperCase mt-5">VERIFY YOUR MOBILE NUMBER</p>

                                                        <div className="ModalTxt" style={{ fontSize: 12 }}>Please enter the OTP you have received on your
                                                            mobile no. Edit phone no.
                                                        </div>
                                                        <input type="text" onChange={(e) => setOtp(e.target.value)} placeholder="ENTER OTP" className="ModalTextInput" />

                                                        <button className="LoginBtnBlue" onClick={() => { handleOtpSubmit() }}>LOGIN</button>

                                                    </>
                                            }
                                        </>
                                        :
                                        stepcount === 2 ?
                                            <>
                                                {
                                                    loading ?
                                                        <>
                                                            <div className="row mt-5 justify-content-center d-flex">
                                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <Loader
                                                                        visible={true}
                                                                        type="TailSpin"
                                                                        color="#FF6F96"
                                                                        height={100}
                                                                        width={100}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="RegisterModalHeading" style={{ fontSize: 17 }}>Select Your Preferred Package</div>

                                                            <div className="plancontainer pt-4">
                                                                <button onClick={() => { setFreePackIsSelected(true); setSelectedPackage(freePackageArr[0]) }} className={`${freePackIsSelected === true ? "selectedPlan" : "unSelectedPlan"}`}>
                                                                    <img alt="freePlan" className="planImage" src={freePlan} />
                                                                    FREE PACK
                                                                </button>
                                                                <button onClick={() => { setFreePackIsSelected(false); setSelectedPackage(packagesArr[0]) }} className={`${freePackIsSelected === false ? "selectedPlan" : "unSelectedPlan"}`}>
                                                                    <img alt="paidPlan" className="planImage" src={paidPlan} />
                                                                    PAID PACK
                                                                </button>
                                                            </div>
                                                            <button style={{ marginTop: 50, marginBottom: 30 }} onClick={() => checkPaidPackage()} className="blueBtnRegister">
                                                                Next
                                                            </button>
                                                        </>
                                                }
                                            </>
                                            :
                                            stepcount === 3 ?
                                                <>
                                                    {
                                                        loading ?
                                                            <>
                                                                <div className="row mt-5 justify-content-center d-flex">
                                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                        <Loader
                                                                            visible={true}
                                                                            type="TailSpin"
                                                                            color="#FF6F96"
                                                                            height={100}
                                                                            width={100}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
                                                                    <div className="RegisterModalHeading" style={{ flex: 1 }}>Choose Your Billing Plan</div>
                                                                    <div onClick={() => { setStepcount(2) }} style={{ color: "blue", cursor: "pointer", marginTop: 15 }}>
                                                                        Change
                                                                    </div>
                                                                </div>

                                                                <div className="planSelectionContainer">

                                                                    {

                                                                        freePackIsSelected == true ?
                                                                            freePackageArr.map(el => {
                                                                                return (
                                                                                    <div key={el.id} onClick={() => { setSelectedPackage(el); console.log(el) }} className="planSelectionText" >
                                                                                        <div className={`${checkSelectedPackage(el.id) ? "radioContainerSelected" : "radioContainerUnSelected"}`}>
                                                                                            <div className={`${checkSelectedPackage(el.id) ? "radioCircleSelected" : "radioCircleUnSelected"}`}></div>
                                                                                        </div>
                                                                                        {el.name}
                                                                                    </div>
                                                                                )
                                                                            })

                                                                            :

                                                                            packagesArr.map(el => {
                                                                                return (
                                                                                    <div key={el.id} onClick={() => setSelectedPackage(el)} className="planSelectionText" >
                                                                                        <div className={`${checkSelectedPackage(el.id) ? "radioContainerSelected" : "radioContainerUnSelected"}`}>
                                                                                            <div className={`${checkSelectedPackage(el.id) ? "radioCircleSelected" : "radioCircleUnSelected"}`}></div>
                                                                                        </div>
                                                                                        {el.name}
                                                                                    </div>
                                                                                )
                                                                            })

                                                                    }

                                                                </div>

                                                                <div className="worldImageContainer">
                                                                    <div className="worldImageText">{selectedPackage.package_type ? selectedPackage.package_type : "Anual"} Plan</div>
                                                                    <div className="worldImageHeading" style={{ textDecorationLine: selectedPackage.type == "free" ? "line-through" : "none" }}>{parseInt(selectedPackage.price / 12)}/month</div>
                                                                    <div className="worldImageText">Billed {selectedPackage.package_type ? selectedPackage.package_type : "Anually"}</div>
                                                                </div>

                                                                <input type="text" onChange={(e) => setCouponCode(e.target.value)} placeholder="Enter Discount Voucher Code" className="registerModalTextInput" style={{ marginTop: 0 }} />

                                                                <button style={{ marginTop: 20, marginBottom: 30 }} onClick={() => handleOrderCreateInModal()} className="blueBtnRegister">
                                                                    Continue to Payment
                                                                </button>
                                                            </>
                                                    }
                                                </>
                                                :
                                                stepcount === 4 &&
                                                <>
                                                    <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: 20, marginBottom: 10, }}>
                                                        <img src={registerComplete} style={{ height: 50, width: 50, marginTop: 10, marginBottom: 10 }} alt="" />
                                                    </div>

                                                    <div className="registerationcompleted">
                                                        Congratulations!
                                                    </div>
                                                    <div className="RegisterCompletedSubHeading" style={{ marginBottom: 30 }}>
                                                        Your Child’s CompassTot Profile
                                                        Has Been Setup.
                                                    </div>
                                                    <div className="RegisterCompletedSubHeading">
                                                        Get Started With Your CompassTot
                                                    </div>



                                                    <Link to="/Dashboard">
                                                        <button style={{ marginTop: 30, marginBottom: 30 }} className="blueBtnRegister">
                                                            {childNameValue}’s CompassTot
                                                        </button>
                                                    </Link>
                                                </>

                        }

                    </div>
                </Modal.Body>

            </Modal>
        );
    }

    const getPaidPackages = async () => {
        try {
            setLoading(true);
            let res = await getPackages()
            if (res.status == 200 || res.status == 201 || res.status == 304) {
                setLoading(false);
                setPackagesArr(res.data)
                setSelectedPackage(res.data[0])
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                setLoading(false);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.error(err)
                setLoading(false);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
            }
        }
    }

    const getFreePack = async () => {
        try {
            setLoading(true);
            let res = await getFreePackages()
            if (res.status == 200 || res.status == 201 || res.status == 304) {
                // console.log(res.data)
                setLoading(false)
                setFreePackageArr(res.data)
                setSelectedPackage(res.data[0])
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                setLoading(false);
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                setLoading(false);
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
            }
        }
    }


    const handleAgeSelect = (value) => {
        setSelectedAge(value)
        console.log(value)
        ageWiseDataArr.map(el => {
            if (parseInt(value) - 1 <= el.endAge && parseInt(value) - 1 >= el.startAge) {
                setSelectedAgeDataObj(el)
                console.log(el)
            }
        })

    }

    useEffect(() => {
        getPaidPackages()
        getFreePack()
    }, [])




    function MyVerticallyCenteredModal(props) {
        const [checked, setChecked] = useState(false);


        const handleRedirect = () => {
            console.log(checked)
            if (checked == true) {
                props.onHide();
                setModalShowSendOtp(true);
            }
        }


        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className={"modalHeaderContainer"}>
                    <Modal.Title className={`ModalHeaderPink`} id="contained-modal-title-vcenter">
                        <div className="text-white" style={{ fontSize: 18 }}>LOGIN</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {/* <button className="LoginBtnBlue" style={{ backgroundColor: "#395A93" }} onClick={() => { handleRedirect() }}> <img src={facebookLogo} style={{ height: 18, width: 10, marginRight: 5 }} alt="" /> LOGIN WITH FACEBOOK</button> */}
                    <button className="LoginBtnBlue" onClick={() => { handleRedirect() }}>
                        <img src={mobilephone} style={{ height: 18, width: 15, marginRight: 5 }} alt="mobile" />
                        LOGIN WITH OTP</button>
                    <div className="row mt-4">
                        <div className="col-1">
                            <input type="checkbox" onChange={() => { setChecked(!checked); }} value={checked} name="" id="" />
                        </div>
                        <div className="col-11">

                            <div style={{ marginTop: 0, padding: 0, textAlign: "left", fontSize: 13 }} className="ModalTxt"> I agree to the  <u><b> <Link style={{ color: "black" }} to="/terms">Terms & Conditions</Link>
                            </b></u> and &nbsp;
                                <b><u><Link style={{ color: "black" }} to="/privacy-policy">Privacy Policy</Link></u></b>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        );
    }




    function MyVerticallyCenteredModal1({ handleotp, ...props }) {
        const [phone, setPhone] = useState(0);

        const handleSendingOtp = () => {
            if (phone.length <= 12 && phone.length > 1) {
                let tempPhone = phone
                tempPhone = tempPhone.slice(2)
                setPhone(previousValue => previousValue.slice(2))
                let obj = {
                    mobile: tempPhone,
                    country_code: parseInt(91),
                }
                handleotp(obj)

            }
            else {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please Enter a valid Mobile Number`,
                });
            }
        }


        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {
                    !loading &&
                    <>
                        <Modal.Header className="modalHeaderContainer">
                            <Modal.Title className="ModalHeader" id="contained-modal-title-vcenter">
                                <img className="modalHeaderImg" alt="favcyLogo" src={favcyLogo2} />
                            </Modal.Title>
                        </Modal.Header>
                    </>
                }
                <Modal.Body>
                    {

                        loading ?
                            <>
                                <div className="row justify-content-center d-flex">
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Loader
                                            visible={true}
                                            type="TailSpin"
                                            color="#FF6F96"
                                            height={100}
                                            width={100}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <p className="txtUpperCase">VERIFY YOUR MOBILE NUMBER</p>
                                <PhoneInput
                                    inputExtraProps={{
                                        name: "phone",
                                        required: true,
                                        autoFocus: true
                                    }}
                                    country={'in'}
                                    countryCodeEditable={false}
                                    // disableCountryCode={true}
                                    inputClass="inputClass"
                                    buttonClass="btn"
                                    containerClass="phoneInputContainer"
                                    dropdownStyle={{ color: "black", border: "none" }}
                                    placeholder="Enter phone number"
                                    value={phone}
                                    onChange={setPhone} />

                                <button className="LoginBtnBlue" onClick={() => { handleSendingOtp() }}>SEND OTP</button>

                                <p className="ModalTxt" style={{ fontSize: 9 }}>By tapping "Send OTP" above, Favcy will send you a One Time Password (OTP) to confirm your number. Enter the received OTP on the next screen to Login into your account.</p>

                            </>
                    }
                </Modal.Body>
            </Modal>
        );
    }
    
    
    
    function MyVerticallyCenteredModal2({ handleloginwithotp, handleotp, ...props }) {
        const [phone, setPhone] = useState(0);
        const [otp, setOtp] = useState("");


        const handleSendingOtp = () => {
            // console.log(phone)
            if (!(phone.length < 12) && phone.length > 1) {
                let tempPhone = phone
                tempPhone = tempPhone.slice(2)
                setPhone(previousValue => previousValue.slice(2))
                let obj = {
                    mobile: tempPhone,
                    country_code: parseInt(91),
                }
                handleotp(obj)
                setModalShowVerifyOtp(true);
                // props.onHide();
            }
            else {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `Please Enter a valid Mobile Number`,
                });
            }
        }



        useEffect(() => {
            setPhone(`+91${mobile}`)

        }, [mobile])




        const handleOtpSubmit = () => {
            let obj = {
                mobile: phone.slice(3),
                country_code: parseInt(91),
                guest_token: gToken,
                otp: otp,
            }
            // console.log(obj, "otp submit")
            handleloginwithotp(obj)
            // props.onHide();
        }


        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {

                    loading ?
                        <>
                            <div className="row my-3 justify-content-center d-flex">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Loader
                                        visible={true}
                                        type="TailSpin"
                                        color="#FF6F96"
                                        height={100}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <Modal.Header className="modalHeaderContainer">
                                <Modal.Title className="ModalHeader" id="contained-modal-title-vcenter">
                                    <img className="modalHeaderImg" alt="favcyLogo" src={favcyLogo2} />
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="txtUpperCase">VERIFY YOUR MOBILE NUMBER</p>
                                <PhoneInput
                                    inputExtraProps={{
                                        name: "phone",
                                        required: true,
                                        autoFocus: true
                                    }}
                                    country={'in'}
                                    inputClass="inputClass"
                                    buttonClass="btn"
                                    containerClass="phoneInputContainer"
                                    dropdownStyle={{ color: "black", border: "none" }}
                                    placeholder="Enter phone number"
                                    value={phone}
                                    disabled
                                    onChange={setPhone} />

                                <div onClick={() => { setModalShowSendOtp(true); setModalShowVerifyOtp(false) }} style={{ display: "flex", justifyContent: "flex-end", width: "100%", alignItems: "center" }}>
                                    <div style={{ color: "blue" }}>Change</div>

                                </div>
                                <div className="ModalTxt" style={{ fontSize: 12 }}>Please enter the OTP you have received on your
                                    mobile no. Edit phone no.
                                </div>
                                {/* <div style={{ textDecoration: "underline", color: "#0698EC", cursor: "pointer" }}> */}

                                <input type="text" onChange={(e) => setOtp(e.target.value)} placeholder="ENTER OTP" className="ModalTextInput" />

                                {/* <button className="resendOtpBtn" onClick={() => { handleSendingOtp() }}>Resend OTP</button> */}
                                <button className="LoginBtnBlue" onClick={() => { handleOtpSubmit() }}>LOGIN</button>
                            </Modal.Body>
                        </>
                }
            </Modal>
        );
    }



    function MyVerticallyCenteredModal4({ handleotp, ...props }) {
        const [username, setUsername] = useState('');
        const [usernameError, setUsernameError] = useState(false);
        const [password, setPassword] = useState('');
        const [PSWInputType, setPSWInputType] = useState('password');
        
        const handleSendingOtp = async () => {
            if (username == "9873103818") {
                let formData = new FormData()
                formData.append("mobile", parseInt("9873103818"))
                formData.append("country_code", parseInt(91))
                let res = await getOtpFromNumber(formData)
                console.log(res, "asda")
                if (res.data.token) {
                    localStorage.setItem("ADMIN_AUTH_TOKEN", res.data.token);
                    let tempObj = {
                        role: res?.data?.user?.roles[0]?.name
                    }
                    console.log(tempObj)
                    setDecodedObj(tempObj)
                    localStorage.setItem("role", res?.data?.user?.roles[0]?.name);
                    setIsAuthorized(true)
                    handleRedirectToDash()
                }
            }
            else {
                let formData = new FormData()

                formData.append("username", username)
                formData.append("password", password)
                let res = await userLoginV2(formData)
                console.log(res)
                if (res.data.token) {
                    localStorage.setItem("ADMIN_AUTH_TOKEN", res.data.token);
                    let tempObj = {
                        role: res?.data?.user?.roles[0]?.name
                    }
                    console.log(tempObj)
                    setDecodedObj(tempObj)
                    localStorage.setItem("role", res?.data?.user?.roles[0]?.name);
                    setIsAuthorized(true)
                    handleRedirectToDash()
                }
            }
        }

        const checkPSWInput = (e) =>{
            if(PSWInputType === 'password'){
                setPSWInputType('text')
            }
            if(PSWInputType === 'text'){
                setPSWInputType('password')
            }
        }

        const checkInputType=()=>{
            const value = username;
            if (validateOnlyNumbers(value)) {
                if (value.length === 10 || value.length === 11) {
                    setUsernameError(false);
                // Format input acc to phone no
                } else {
                    setUsernameError(true);
                }
            } else {
                if (validateEmail(value)) {
                    setUsernameError(false);
                    // Format input acc to Email
                } else {
                    setUsernameError(true);
                }
            }
        }

        const validateEmail =(email) => {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
          }
          
          const validateOnlyNumbers=(phone) =>{
            return phone.match(/^\d+$/);
          }


        return (
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {
                    !loading &&
                    <>
                        <Modal.Header className="modalHeaderContainer">
                            <Modal.Title className="ModalHeader flex-column" id="contained-modal-title-vcenter">
                                <img className="modalHeaderImg" alt="Logo" src={logo} />
                            </Modal.Title>
                        </Modal.Header>
                    </>
                }
                <Modal.Body>
                    {

                        loading ?
                            <>
                                <div className="row justify-content-center d-flex">
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Loader
                                            visible={true}
                                            type="TailSpin"
                                            color="#FF6F96"
                                            height={100}
                                            width={100}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                {/* <p className="txtUpperCase">Login</p> */}
                                <label className='font-bold' >Email/Phone no.</label>
                                <input type='text' onBlur={checkInputType} placeholder='Email/Phone no.' className="loginModalTextInput"  onChange={(e)=>{setUsername(e.target.value)}} />
                                {
                                    usernameError ? <p className='m-0 text-danger small' >Please enter valid input.</p> : null
                                }
                                <label className='font-bold' >Password</label>
                                <input type={PSWInputType} placeholder='Password' className="loginModalTextInput"  onChange={(e)=>{setPassword(e.target.value)}} />
                                <label for="showPassword"> 
                                <input type='checkbox' name='showPassword' className='ShowPasswordCheckBox' onChange={(e)=>{checkPSWInput()}} />
                                <span>
                                Show Password
                                </span>
                                </label> 
                                
                                <button className="LoginBtnBlue" onClick={() => { handleSendingOtp() }}>SUBMIT</button>

                                {/* <p className="ModalTxt" style={{ fontSize: 9 }}>By tapping "Send OTP" above, Favcy will send you a One Time Password (OTP) to confirm your number. Enter the received OTP on the next screen to Login into your account.</p> */}

                            </>
                    }
                </Modal.Body>
            </Modal>
        );
    }





    return (
        <>
            <Navbar style={{ width: "100%", position: "sticky", top: 0, zIndex: 150 }} className="container-fluid bg-secondary" collapseOnSelect expand="lg" bg="light" variant="light">
                <Container className="col-lg-11 navbarContainer">
                    <Navbar.Brand href="#home" ><img src={logo} alt="logo" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ color: "#FF6F96", borderColor: "#FF6F96", borderRadius: 50, borderWidth: 2, height: 45, width: 45, marginTop: -20, padding: 0 }} />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-lg-auto bg-light px-2">
                            <Nav.Link style={{ display: 'flex', alignItems: 'center' }} onClick={scrollToWhyCompassTot}>Why CompassTot</Nav.Link >
                            <Nav.Link style={{ display: 'flex', alignItems: 'center' }} onClick={() => compassTotFeaturesRef.current.scrollIntoView()}>CompassTot Features</Nav.Link>
                            {/* <Nav.Link style={{ display: 'flex', alignItems: 'center' }} onClick={() => partnerWithUsRef.current.scrollIntoView()}>Partner With Us</Nav.Link> */}
                            <Nav.Link style={{ display: 'flex', alignItems: 'center' }} onClick={() => FaqRef.current.scrollIntoView()}>FAQs</Nav.Link>
                            <Nav.Link ><button onClick={() => setModalShow(true)} className="pinkButton">Login</button></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </Navbar>



            <div className="container-fluids imageContainer">
                <div className="pageHeaading">
                    Take the Guesswork
                    Out in Raising Your
                    Toddler
                </div>

                <div className="pageSubHeadings">
                    CompassTot offers neuroscience based, hyper-personalized, screen-free interactive activities for Your Toddler, to promote holistic (cognitive & physical) development and build a solid foundation for lifetime.
                    <div style={{ paddingTop: 15 }}></div>
                    Essentially, CompassTot is a Personalized Compass for Parents, to assist & guide them do The Right Thing, at The Right Time, in The Right Way.

                </div>

                <button className="pinkBtn" onClick={() => setModalShowRegister(true)}>Take a Free Trial</button>

                <div className="selectInputContainer col-10 mx-auto">

                    <div className="selectInputInnercontainer">
                        <select className="selectInput" onChange={(e) => { console.log(e.target.value); localStorage.setItem("age", e.target.value); handleAgeSelect(e.target.value); }}>

                            {
                                ageArr.map((el, index) => {
                                    return (
                                        index == 0 ?
                                            <option key={index} value={el.age_group}>{el.age_group}</option>
                                            :
                                            <option key={index} value={el.age_group}>{el.age_group} Months</option>
                                    )
                                })
                            }

                        </select>
                    </div>

                </div>

            </div>

            {
                selectedAgeDataObj.startAge &&

                <section className="activity col-10  mx-auto mt-5">
                    <div className="row">
                        <div className="col-lg-3 col-12 d-flex justify-content-center">
                            <img src={baby} style={{ height: 300, width: 300 }} alt="Baby" />
                        </div>
                        <div className="col-lg-9">

                            {
                                selectedAgeDataObj.startAge && selectedAgeDataObj.startAge == 25 ?
                                    <div className="activityHeading">

                                        Expected Milestones During Age 25-36 Months
                                    </div>
                                    :
                                    selectedAgeDataObj.startAge && selectedAgeDataObj.startAge == 37 ?
                                        <div className="activityHeading">
                                            Expected Milestones During Age 37-48 Months
                                        </div>
                                        :
                                        selectedAgeDataObj.startAge && selectedAgeDataObj.startAge == 49 ?
                                            <div className="activityHeading">

                                                Expected Milestones During Age 49-60 Months
                                            </div>
                                            :
                                            <></>
                            }
                            <div className="activityDetails">
                                {
                                    selectedAgeDataObj.dataArr && selectedAgeDataObj.dataArr.map((el, index) => {
                                        return (

                                            <div key={index} style={{ marginBottom: 15, textAlign: "justify" }}>
                                                {el}
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>

                        <button onClick={() => handleRedirect()} className="activityBtn">
                            KNOW MORE WITH A FREE TEST
                        </button>
                    </div>
                </section>
            }



            <section className="col-lg-10 col-12 mx-lg-auto " ref={whyCompassTotRef}>
                <div className="whyCompassTot">
                    <span style={{ color: "#32355D" }}>Why</span>
                    <span style={{ color: "#FF6F96", marginLeft: 5 }}>Compass</span>
                    <span style={{ color: "#32355D" }}>Tot</span>
                </div>

                <div className="whyCompassTotMainContainer">

                    <div className="whyCompassTotcardsContainer">


                        <div className="col-lg-4 col-8 whyCompassTotcard">
                            <img className="whyCompasstotImage" src={whyCt1} alt="why" />


                            <div className="whyCompassTotHeading">Customized Program</div>
                            <div className="whyCompassTotTxt">CompassTot provides you with a series of neuroscience-based, screen free, play activities, that are not just age-appropriate, but are also <b>developmentally appropriate for Your Child</b> . It is not merely a standard, age-based program, that moves forward as per calendar days, without any reference to how Your Child is progressing.
                                <div style={{ marginTop: 15 }}>

                                    CompassTot designs the entire program after thoroughly assessing Your Child’s current skill levels in various domains of development and it moves forward closely mirroring Your Child’s actual developmental progression, <b>in order to realize Your Child’s maximum learning potential, without putting any undue pressure</b>. Moreover, once you start using it, if you feel the need, we will customize the program further based on how Your Child is responding. Thus, <b>CompassTot is a hyper-personalized program crafted specifically for Your Child</b>, to maximize the learning potential in critical domains of development – Cognitive Skills & Physical Skills (Gross Motor Skills & Fine Motor Skills).
                                </div>
                            </div>


                        </div>

                        <div className="col-lg-4 col-8 whyCompassTotcard">
                            <img className="whyCompasstotImage" alt="why" style={{ marginTop: 25 }} src={whyCt2} />


                            <div className="whyCompassTotHeading">Neuroscience Simplified</div>
                            <div className="whyCompassTotTxt">Our mission is to democratize neuroscience & make every parent a neuroscientist…! CompassTot assimilates & brings to Parents learnings gained over <b>50,000+ one-on-one Child developmental sessions, over last 25+ years of professional career & stellar academic background</b>. Our program Research & Development Director is a University of Mumbai Rank-holder Pediatric Doctor, with additional specialization from University of Southern California. All our activities are carefully designed in ways Children and Parents enjoy it together.
                                <div style={{ marginTop: 15 }}>
                                    <b>

                                        This is crucial, because Children in early years learn through playful interactions with caregivers. As Children trust their Parents more than anyone else, carefully timed & developmentally appropriate play-based activities result in much better learning outcomes for Children, when they do it with Parents, as compared to with anyone else – even playschool teachers</b>. Hence as a Parent, you are the “Primary Caregiver” and should spend some screen-free, quality interactive playtime with Your Child.
                                </div>
                            </div>


                        </div>
                        <div className="col-lg-4 col-8 whyCompassTotcard">
                            <img className="whyCompasstotImage" alt="why" src={whyCt3} style={{ marginTop: -22 }} />


                            <div className="whyCompassTotHeading">Peace of Mind</div>
                            <div className="whyCompassTotTxt">CompassTot saves you substantial amount of time, efforts, energy, anxiety & exhaustion from constantly having to search & filter through the online information overload, only to find generic information like “10 Best Activities for Children….”. <b> Wouldn’t you rather know what is specifically relevant & beneficial for Your Child, Today, and on an ongoing basis, without constantly having to search for it…?</b>
                                <div style={{ marginTop: 15 }}>

                                    Wouldn’t it be better if you could use all this time & energy more productively, like to <b>spend more time with Your Child, improve the quality of interactive playtime with Your Child and even to have some “Guilt-free Me Time”…?</b> Furthermore, CompassTot enables you to remain in control of Your Child’s learning & development journey, helps you course correct when you need to and share Your Child’s progress with you on an ongoing basis, so you don’t have to anxious about missing out on something important.
                                </div>
                            </div>


                        </div>



                    </div>
                </div>
            </section>




            <section className="features col-12" ref={compassTotFeaturesRef}>
                <img src={cloud} className="featuresTopImage" alt="" />
                <img src={kid} className="featuresBottomImage" alt="" />
                <div className="featuresHeading">
                    <span style={{ color: "#FF6F96" }}>Compass</span>
                    <span style={{ color: "#32355D", marginRight: 5 }}>Tot</span>
                    <span style={{ color: "#FF6F96" }}>Features</span>
                </div>

                <div style={{ margin: 0, padding: 0 }} className="row d-flex justify-content-center">
                    <div className="col-lg-3 col-12 featuresCard">
                        <img src={featureImg1} className='featuresImage' alt="" />
                        <div className="featureHeading">CompassTot Curriculum</div>
                        <div className="featureTxt">In the first few years of life, more than 1 million new neural connections are formed every second. After this period of rapid proliferation, connections are reduced through a process called “Pruning”, so that brain circuits become more efficient and more complex brain circuits can be built upon earlier, simpler circuits.
                            <div style={{ marginTop: 15 }}>
                                That’s why early experiences affect the quality of this architecture by establishing either a sturdy or a fragile foundation for all of the learning, health and behavior that follow. Therefore, CompassTot Curriculum is carefully crafted to combine incrementally advancing milestones with reinforcement of skills learnt in the past, to make sure that while Your Child is learning & improving new skills, skills learnt in the past are retained & reinforced too. <b> As the foundation gets sturdier with reinforcement-based learning, it in turn improves not just the pace of Your Child’s skill development & milestone achievement, but also the quality of those skills learnt & milestones achieved.</b>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-12 featuresCard">
                        <img src={featureImg2} className='featuresImage  featuresImage1' alt="" />
                        <div className="featureHeading">CompassTot Journal</div>
                        <div className="featureTxt">CompassTot Journal helps you document Your Child’s personalized learning journey over a period of time, under each of the critical domains of development – Cognitive, Gross Motor & Fine Motor. For each of these domains, you can see which skills Your Child has already mastered over a period of time, which skills Your Child is currently learning & the level of mastery achieved so far and which skills Your Child is supposed to be learning going forward. You can also see if Your Child is progressing well in all domains of development or is falling behind in any particular domain. All of this gives you an all-round view of Your Child’s learning & skill development –
                            <div style={{ marginTop: 15 }}>
                                <b>
                                    where Your Child was at the beginning of the program, how much have you helped Your Child achieve since then and what is next to come – so that you can look back proudly, plan ahead with clarity and are aways well-informed & well-prepared.
                                </b>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-12 featuresCard">
                        <img src={featureImg3} className='featuresImage' alt="" />
                        <div className="featureHeading">CompassTot Progress Monitor</div>
                        <div className="featureTxt">Studies tracking student learning outcomes clearly demonstrate that Children who start out late, tend to stay behind throughout their school years.

                            <div style={{ marginTop: 15 }}>

                                Thus, CompassTot brings to you <b>a first of its kind Developmental Assessment & Progress Monitor</b>. First, doing a simple, yet thorough assessment of Your Child’s skills helps you find out <b>whether Your Child is likely to be behind the developmental curve, on the developmental curve or ahead of the developmental curve</b> at the beginning of the program. Post that, as you start moving forward with your personalized program, the progress monitor shows you <b>how well Your Child is progressing developmentally on an ongoing basis</b>. This helps you understand if what you are doing is actually helping Your Child and how much. <b>
                                    This is critical feedback for you as a Parent, so that you always remain in control of Your Child’s learning & skill development and can promptly course correct as & when needed.
                                </b>

                            </div>
                        </div>
                    </div>
                </div>

            </section>




            <section className="pricing" ref={pricingRef}>
                <div className="pricingHeading">
                    <span style={{ color: "#32355D", marginRight: 5 }}>Pricing</span>
                </div>

                <div className="pricingCardContainer">

                    <div className="pricingCard">
                        <div className="priceCardInnerContainer">

                            <div className="pricingNamePink" style={{ borderBottomColor: "#FF6F96", borderBottomWidth: 2, fontSize: 20 }}>
                                Personalized Plan
                            </div>

                            <div className="pricePink">
                                <div style={{ fontSize: 15, fontWeight: "700", marginTop: 10 }}>
                                    Early Access Offer: 33.33% Off…!!!

                                </div>
                                <div style={{ fontSize: 16, marginTop: 10 }}>
                                    12 Months:  <s>₹ 14,999/-</s> ₹ 9,999/-


                                </div>
                                <div style={{ fontSize: 16, marginTop: 10 }}>
                                    6 Months:  <s>₹ 8,999/-</s> ₹ 5,999/-
                                </div>
                                <div style={{ fontSize: 16, marginTop: 10 }}>
                                    3 Months:  <s>₹ 5,249/-</s> ₹ 3,499/-
                                </div>
                            </div>

                        </div>


                        <ul className="pricingPointsContainer">
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, width: 20, marginRight: 5 }} alt="" />
                                Free Developmental Assessment Test & Detailed Report – No Credit Card Required…!
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, width: 20, marginRight: 5 }} alt="" />
                                1 Month Free Trial (Personalized Plan) – No Credit Card Required…!
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, width: 20, marginRight: 5 }} alt="" />
                                Personalized Plan – As Per Overall Developmental Age Across All Domains of Development
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, width: 20, marginRight: 5 }} alt="" />
                                CompassTot Curriculum
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, marginRight: 5 }} alt="" />
                                CompassTot Journal
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={cancel} style={{ height: 20, marginRight: 5 }} alt="" />
                                <span>

                                    CompassTot Progress Monitor <br /> (<img src={greenCheck} style={{ height: 17 }} alt="" /> Included During Early Access Only…!!!)
                                </span>
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={cancel} style={{ height: 20, marginRight: 5 }} alt="" />
                                Hyper-Personalized Plan – Tailored to Developmental Age in Each Domain of Development
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={cancel} style={{ height: 20, marginRight: 5 }} alt="" />
                                Expert Consultation & Detailed Progress Review Every 3 Months
                            </li>


                        </ul>


                        <button className="getStartedBlue" onClick={() => setModalShowRegister(true)}>
                            GET STARTED
                        </button>
                    </div>
                    <div className="pricingCardBlue">
                        <div className="priceCardInnerContainer">

                            <div className="pricingNameBlue" style={{ borderBottomColor: "#FF6F96", borderBottomWidth: 2, fontSize: 20 }}>
                                Hyper-Personalized Plan
                            </div>

                            <div className="priceBlue">
                                <div style={{ fontSize: 15, fontWeight: "700", marginTop: 10 }}>
                                    Early Access Offer: 40% Off…!!!

                                </div>
                                <div style={{ fontSize: 16, marginTop: 10 }}>
                                    12 Months:  <s>₹ 24,999/-</s> ₹ 14,999/-


                                </div>
                                <div style={{ fontSize: 16, marginTop: 10 }}>
                                    6 Months:  <s>₹ 14,249/-</s> ₹ 8,549/-
                                </div>
                                <div style={{ fontSize: 16, marginTop: 10 }}>
                                    3 Months:  <s>₹ 8,249/-</s> ₹ 4,949/-
                                </div>
                            </div>

                        </div>
                        <ul className="pricingPointsContainer">
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, marginRight: 5 }} alt="" />
                                Free Developmental Assessment Test & Detailed Report – No Credit Card Required…!
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, marginRight: 5 }} alt="" />
                                1 Month Free Trial (Personalized Plan) – No Credit Card Required…!
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, marginRight: 5 }} alt="" />
                                Personalized Plan (During Free Trial) – As Per Overall Developmental Age Across All Domains of Development
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, marginRight: 5 }} alt="" />
                                CompassTot Curriculum
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, marginRight: 5 }} alt="" />
                                CompassTot Journal
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, marginRight: 5 }} alt="" />
                                CompassTot Progress Monitor
                                <br />
                                <br />
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, marginRight: 5 }} alt="" />
                                Hyper-Personalized Plan – Tailored to Developmental Age in Each Domain of Development
                            </li>
                            <li style={{ marginTop: 5, display: "flex", flexDirection: "row" }}>
                                <img src={greenCheck} style={{ height: 20, marginRight: 5 }} alt="" />
                                Expert Consultation & Detailed Progress Review Every 3 Months
                            </li>


                        </ul>


                        <button className="getStartedWhite" onClick={() => setModalShowRegister(true)}>
                            GET STARTED
                        </button>
                    </div>
                </div>



            </section>




            {/* <section className="partnerwithUs" ref={partnerWithUsRef}>
                <div className="partneerWithUsHeading">
                    <span style={{ color: "#32355D", marginRight: 5 }}> Partner With</span>
                    <span style={{ color: "#FF6F96" }}>Us</span>
                </div>
                <div className="partnerWithUsDescription">
                    We are always on the lookout for trusted partners who can drive the CompassTot initiative further. If you are looking for a platform that offers an all-round growth and tracking experience for the young ones, then you have come to the right place.
                </div>




                <div className="partnerWithUsContainer">
                    <img alt="partner" className="partnerWithUsImage" src={partnerWithUsImage1} />
                    <img alt="partner" className="partnerWithUsImage" src={partnerWithUsImage2} />
                    <img alt="partner" className="partnerWithUsImage" src={partnerWithUsImage4} />
                    <img alt="partner" className="partnerWithUsImage" src={partnerWithUsImage3} />
                </div>


                <button className="startNowBtn" onClick={() => setModalShowRegister(true)}>START NOW</button>

            </section> */}





            <section className="faqContainer" ref={FaqRef}>
                <img src={faqTopImg} className="faqTopBgImage" alt="" />
                <img src={faqBottomLeftImg} className="faqBottomLeftBgImage" alt="" />
                <div className="faqHeading">
                    <span style={{ color: "#32355D", marginRight: 5 }}>FAQ's</span>
                </div>
                <Accordion className="accordianContainer">
                    <Accordion.Item eventKey="0" className="accordianItem">
                        <Accordion.Header>What does CompassTot do..?</Accordion.Header>
                        <Accordion.Body>
                            CompassTot takes the guesswork out in raising your Child. We are a platform that offers developmentally appropriate & curated activities for your Child, to build a solid foundation for lifetime. We assimilate & bring to Parents learnings of our co-founders, gained over 50,000+ one-on-one Child developmental sessions, over last 25+ years. Essentially, we assist & guide you do The Right Thing, at The Right Time, in The Right Way.
                            <br />
                            <br />
                            Our neuroscience-based, developmentally appropriate, play-based fun activities are specifically tailored to each Child’s unique & evolving growth path. We also help Parents track developmental progression, so they may see for themselves how their Child is faring in each core skillset.
                            <br />
                            <br />
                            All our activities are designed in ways Children and Parents enjoy it, which improves quality of time Parents spend with their Child and builds an even stronger bond between them. This is crucial, because children, especially in early years, learn and explore feelings through play.
                            <br />
                            <br />
                            We work hard, so you don’t have to get exhausted by having to keep wasting a lot of time, efforts & money for finding out age-appropriate activities, specifically beneficial for your Child, on an ongoing basis. You can rather use that energy, time & efforts productively, i.e. by spending quality time with your Child, which significantly benefits their developmental progression.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className="accordianItem">
                        <Accordion.Header>What is development..? Which core areas of development does CompassTot focus on..?</Accordion.Header>
                        <Accordion.Body>
                            The learning processes your child goes through as they grow and become an adult is called development. Skills are learnt and then combined to develop more complex tasks such as walking, talking, playing, critical reasoning, social interacting etc.
                            <br />
                            <br />
                            CompassTot focuses on following key areas of development:
                            <br />
                            1. Gross Motor Skills, for example crawling, jumping or running.
                            <br />
                            2. Fine Motor Skills, such as writing and drawing .
                            <br />
                            3. Cognitive and Intellectual Skills, such as counting or identifying shapes.
                            <br />
                            4. Social and Emotional Skills, such as playing with other children.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className="accordianItem">
                        <Accordion.Header>Why are the early years so important..? </Accordion.Header>
                        <Accordion.Body>
                            The quality of nurturing & support provided in the early years influences a child’s ability to learn, their behaviour, their ability to control emotions and their risks for disease later in life.
                            <br />
                            <br />
                            Children experience the greatest rate of development during their early years and the first 6 years of life are a critically important time in brain development. Over 85% of cumulative brain development is completed prior to age 6. While neural connections in the brain are made throughout life, the rapid pace at which our brains develop in these first 6 years is never repeated.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className="accordianItem">
                        <Accordion.Header>But isn’t IQ fixed at birth..?</Accordion.Header>
                        <Accordion.Body>
                            IQ is NOT fixed at birth. In the first few years of life, 10,00,000+ new neural connections are formed in the Brain, Every Second..! The stimulation a child receives in the first few years, improves the brain.
                            <br />
                            <br />
                            But parents get limited amount of time to make it count. 85% of cumulative brain development is completed prior to age 6, thus, Ages 0-6 are called Golden Window of Opportunity.
                            <br />
                            <br />
                            Brain is most flexible or “plastic” early in life, but its capacity for change decreases with age. As maturing brain becomes more specialized to assume more complex functions, it’s less capable of reorganizing & adapting to new or unexpected challenges. <b>The timing is genetic, but early experiences determine whether the neural circuits are strong or weak.</b>Thus, it’s easier & more effective to influence Baby’s developing brain architecture early (0-6 Ages), than to rewire parts of its circuitry later.
                            <br />
                            <br />
                            <b>Early experiences affect quality of Child’s brain architecture by establishing either a sturdy or a fragile foundation for all learning, health behaviour that follow.</b>
                            Studies tracking student learning outcomes clearly demonstrate that Children who start out late, tend to stay behind throughout their school years.
                            <br />
                            <br />
                            However, generic age-based activity lists do half-hearted job.
                            <b> The crux lies in adapting to your Child’s needs, based on the unique developmental path (quality & pace of skill development in each of the Core Skillsets) of your Child, on an ongoing basis. </b>
                            For us at CompassTot, this has been and will always remain the singular focal point & an obsession. Anything that we do, has to rigorously satisfy this. Nothing else matters to us.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" className="accordianItem">
                        <Accordion.Header>How much screen time will my Child be exposed to..?  </Accordion.Header>
                        <Accordion.Body>
                            While it is easy to engage young Children with screen & keep them hooked on to it, so Parents keep renewing the subscription as they get false sense of satisfaction that my Child is learning, CompassTot recommends & forces <b> No Screen Time </b> on your Child.
                            <br />
                            <br />
                            In today’s tech and media-driven world, many parents use screens to keep young children entertained or distracted while they juggle other needs. It works. Screens captivate children’s attention in a way almost nothing else does, allowing parents a bit of a breather. But have you considered what is the true impact of screens on young brains..? Screen time severely affects the developing & vulnerable brains of young Children and compromises their physical, social & cognitive developmental potential.
                            <br />
                            <br />
                            According to UNICEF & WHO, the negative effects of screen time for babies & toddlers range from shorter attention span to lower empathy & social skills, to frustration & reduced ability to control impulses, to hindering imagination & motivation, to childhood obesity & associated diseases later in life, to poor mental health & wellbeing, and so on.
                            <br />
                            <br />
                            What is even more scary is, neuroscientists are still not sure if these are the only negative effects of screen time and their ongoing research with thousands of Children each year keeps uncovering scarier & scarier data & hard truths every now & then. For e.g. a landmark National Institutes of Health, USA study that began in 2018 found, that children with exposure to screen-time activities score lower on language & thinking tests, and may even experience thinning of the brain’s cortex, the area of the brain related to critical thinking and reasoning.
                            <br />
                            <br />
                            That is why, as per the new guidelines on physical activity & sedentary behaviour issued by the World Health Organization in 2019, for children under 5 years of age, WHO recommends no screen time for babies under 2 and no more than one hour of screen time a day for those aged 2 to 5, with a clear emphasis on “less is better” and “when sedentary, engaging in reading and storytelling with a caregiver is encouraged”. WHO further recommends replacing sedentary screen time with quality sedentary time spent in interactive non-screen-based activities with a caregiver, such as reading, storytelling, singing and puzzles, as it is very important for child development.
                            <br />
                            <br />
                            As per these guidelines issued by WHO, at a minimum, children aged 2 to 5 should spend at least 180 minutes in a variety of types of physical activities at any intensity, of which at least 60 minutes is moderate to vigorous intensity physical activities, spread throughout the day; more is better.
                            <br />
                            <br />
                            These new guidelines were developed by a WHO panel of experts after assessing the effects on young children of time spent sitting watching screens. They also reviewed evidence around the benefits of increased activity levels.
                            <br />
                            <br />
                            Even UNICEF clearly states, exposure to screens reduces babies’ ability to read human emotion and control their frustration. It also detracts from activities that help boost their brain power, like play and interacting with other children. In absence of fully developed language skills, Children depend heavily on looking at a face and deriving meaning from that face. Is this person happy with me, or are they upset at me? That two-way interaction between children and adult caregivers is critically important for brain development. But screentime negatively affects child’s ability to give & sustain eye contact with other people. Thus, the benefits of limiting and even eliminating screen time in these early moments will last a lifetime. But if you have to rely on screens at certain moments, just make sure to control the quality of what they see and engage with them while they’re watching.
                            <br />
                            <br />
                            Most screen-time based addictive apps continue using this last line in their defense, that they are good and hence it’s okay to expose your Child to screen time for their app. But we look at it a little differently. Well, if you anyway should sit with your Child during screen time & engage with them when they are watching, wouldn’t it be far, far better to use the same time for developmentally appropriate non-screen activities that actually add to your Child’s development, rather than use it for screen time, giving false sense of satisfaction (that you are using more advanced resources to teach your child) or momentary relief..?
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" className="accordianItem">
                        <Accordion.Header>What sets CompassTot apart from other parenting guidance offerings..? / Is CompassTot a parenting guidance offerings..?
                        </Accordion.Header>
                        <Accordion.Body>
                            CompassTot is NOT a parenting guidance offering. It does not promote/discourage specific parenting styles, nor does it try to shape the personality of a Child.
                            <br />
                            <br />
                            CompassTot singularly focuses on helping Parents make the best possible use of the Golden Window of core skills development (0-6 Ages), so Children can discover their maximum potential when they step out in the real world.
                            <br />
                            <br />
                            The quality of Core Skills Children learn during formative years reverberate throughout their entire life – be it School or College or Office, be it Personal Life or Professional Life, be it Academics or Sports or Performing Arts. For e.g. when a Child learns the skill of being in one place & maintaining focus for extended period, it comes in handy irrespective of the goal – Maths, Cricket, Guitar, Coding or Balance Sheet.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6" className="accordianItem">
                        <Accordion.Header>Is CompassTot trying to replace play schools & pre-schools..?
                        </Accordion.Header>
                        <Accordion.Body>
                            CompassTot is <b>NOT</b> trying to replace play schools & pre-schools. In fact, we understand that certain skills are best learnt in a group setting and encourage parents to send their children to play schools & pre-schools.
                            <br />
                            <br />
                            Having said this, Children form stronger neural connections from interaction with Primary Caregivers (Parents & Family). CompassTot simply aims to leverage it, by bringing the knowledge of neuroscience based developmentally appropriate activities to parents, so they can confidently help their children realize maximum potential.
                            <br />
                            <br />
                            All our activities are designed in ways Children and Parents enjoy it, which improves quality of time Parents spend with their Child & builds an even stronger bond between them. This is crucial, because children, especially in early years, learn and explore feelings through play.
                            <br />
                            <br />
                            CompassTot also aims to ensure that Children of those Parents who do not have access to or cannot afford sending Child to play/pre-schools do not lag behind & lose out for no fault of theirs.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7" className="accordianItem">
                        <Accordion.Header>Do we need to keep buying additional material from CompassTot..?
                        </Accordion.Header>
                        <Accordion.Body>
                            We <b>DO NOT</b> force you to buy any material from us and we never will. In fact, we try to be inclusive in our approach, so our activities do not require expensive, unnecessary material anyway.
                            <br />
                            <br />
                            Even in case an activity requires some material, it can be easily found at your neighborhood stores or e-comm websites. In such cases, we also try to suggest alternative inexpensive material that you can design/make it at home.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </section>



            <div className="footerContainer">
                <div className="row bg-dark d-flex justify-content-center" style={{ margin: 0, padding: 0 }}>
                    <div className="col-lg-10 col-11">
                        <div className="row">

                            <div className="col-lg-3 col-12 text-white">

                                <img src={favcyLogo} alt="" className="logo" />

                                <div className="description">
                                    CompassTot is hosted on Favcy, 'The No Download' Engagement OS!
                                </div>

                                <b>Address:</b>

                                <p>4th Floor, The Circle
                                    Huda City Center Metro Station,
                                    Sector 29,
                                    Gurugram, Haryana 122002
                                </p>


                                <p className="details"><b>Phone:</b> +91-98330-18380</p>

                                <p className="details"><b>Email:</b> admin@compasstot.com</p>
                            </div>
                            <div className="col-lg-3 col-12 text-white mt-lg-5 pt-lg-3 ">

                                <div className="goToHeading">GO TO</div>
                                <ul className="footerList" style={{ margin: 0, paddingLeft: 15 }}>
                                    <li onClick={scrollToWhyCompassTot} className="listItem">Why CompassToT</li>
                                    <li onClick={() => compassTotFeaturesRef.current.scrollIntoView()} className="listItem">CompassToT Features</li>
                                    <li onClick={() => pricingRef.current.scrollIntoView()} className="listItem">Pricing</li>
                                    {/* <li onClick={() => partnerWithUsRef.current.scrollIntoView()} className="listItem">Partner With Us</li> */}
                                    <li onClick={() => FaqRef.current.scrollIntoView()} className="listItem">FAQs</li>
                                </ul>
                            </div>
                            <div className="col-lg-6 d-flex justify-content-center col-12 text-white lastColumn">
                                {/* <div className="row">

                                    <div className="col-6  mb-4 my-lg-0 d-flex align-items-center justify-content-center">
                                        <img alt="linkedIn" src={linkedIn} style={{ height: 50, width: 50 }} />
                                        <div className="ml-2">
                                            Like us
                                            on Linkedin
                                        </div>
                                    </div>

                                    <div className="col-6 mb-4 my-lg-0 d-flex align-items-center justify-content-center">
                                        <img alt="facebook" src={facebook} style={{ height: 50, width: 50 }} />
                                        <div className="ml-2">
                                            Like us
                                            on Facebook
                                        </div>

                                    </div>
                                    <div className="col-6 mb-4 my-lg-0 d-flex align-items-center justify-content-center">
                                        <img alt="twitter" src={twitter} style={{ height: 50, width: 50 }} />
                                        <div className="ml-2">
                                            Like us
                                            on Twitter
                                        </div>
                                    </div>


                                    <div className="col-6 mb-4 my-lg-0 d-flex align-items-center justify-content-center">
                                        <img alt="youtube" src={youtube} style={{ height: 50, width: 50 }} />
                                        <div className="ml-2">
                                            Like us
                                            on Youtube
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center copyRight text-white">
                                Copyrights © 2021 All Rights Reserved by CompassToT
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col-12 d-flex justify-content-center copyRight text-white">
                                Terms of Use / Privacy Policy
                            </div>

                        </div>
                    </div>
                </div>
            </div >
            <MyVerticallyCenteredModal3
                // handleregisterwithotp={handleUserRegister}
                handleOtpEntry={loginUser2}
                handleOrderCreateFunc={handleOrderCreate}
                show={modalShowRegister}
                onHide={() => setModalShowRegister(false)}
            />
            <MyVerticallyCenteredModal4
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

            <MyVerticallyCenteredModal1
                show={modalShowSendOtp}
                phone={phoneNumber}
                handleotp={getOtp}
                onHide={() => setModalShowSendOtp(false)}
            />

            <MyVerticallyCenteredModal2
                show={modalShowVerifyOtp}
                handleotp={getOtp}
                handleloginwithotp={loginUser}
                onHide={() => setModalShowVerifyOtp(false)}
            />
        </>

    )
}
