import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";
import styles from "./UserPersonalDetails.module.css";
import back from "../../assets/img/back.png";
import { getUserAges } from '../../Services/Activity';
import { updateUserProfile } from '../../Services/User';


export default function UserPersonalDetails() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");
    const [child_name, setChild_name] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [childDetails, setChildDetails] = useState({});
    let id = useParams()
    const handleBackNavigation = () => {
        history.goBack();
    }

    const getUserDetails = async () => {
        try {
            let res = await getUserAges()
            if (res.status == 200 || res.status == 201 || res.status == 304) {
                console.log(res.data)
                setChildDetails(res.data)
                setMobile(res.data.user.mobile)
                setEmail(res.data.user.email)
                setName(res.data.user.name)
                setChild_name(res.data.user.child_name)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
            }
        }
    }


    const handleUpdateUserProfile = async () => {
        try {
            let obj = {
                email,
                name,
                child_name
            }
            let res = await updateUserProfile(obj)
            if (res.status == 200 || res.status == 201 || res.status == 304) {
                alert(res.data.message)
                console.log(res.data)
                getUserDetails()
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
            }
        }
    }


    useEffect(() => {
        getUserDetails()
    }, [])




    return (
        <div>


            <div className={`${styles.header}`}>
                <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div>
                <div className={`${styles.headerHeading}`}>
                    Parent Details
                </div>
            </div>
            <div className={`${styles.containerdiv} container`}>
                {
                    loading ?
                        <>
                            <div className="row mt-5 justify-content-center d-flex">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Loader
                                        visible={true}
                                        type="TailSpin"
                                        color="#FF6F96"
                                        height={100}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </>
                        :
                        <div className="row">
                            <div className="mx-auto col-lg-5 col-11">
                                <div className={`${styles.label}`}>Parent Name</div>
                                <input value={name} onChange={(e) => setName(e.target.value)} className={`${styles.txtInput}`} placeholder="Nishant Chawla" />
                                <div className={`${styles.label}`}>Child Name</div>
                                <input value={child_name} onChange={(e) => setChild_name(e.target.value)} className={`${styles.txtInput}`} placeholder="Nishant Chawla" />
                                <div className={`${styles.label}`}>Phone Number</div>
                                <input value={mobile} disabled className={`${styles.txtInput}`} placeholder="XXXXXXXXXX" />
                                <div className={`${styles.label}`}>Email Address</div>
                                <input value={email} onChange={(e) => setEmail(e.target.value)} className={`${styles.txtInput}`} placeholder="eg. example@example.com" />
                                <button className={styles.blueBtn} onClick={() => handleUpdateUserProfile()}>Submit</button>
                            </div>
                        </div>
                }
            </div>

        </div>
    )
}
