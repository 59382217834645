import React,{useState,useEffect} from 'react'
import DashboardFooter from '../Dashboard/DashboardFooter/DashboardFooter'
import back from '../../assets/img/back.png'
import { useHistory } from 'react-router-dom'
import styles from './UpcomingActivities.module.css'
import { getUpcomingActivities } from '../../Services/Activity'
export default function UpcomingActivities() {

    const [skillsArr, setSkillsArr] = useState([]);

    const history = useHistory()

    const getActivities = async () => {
        try {
            let tempFinalArr = [];
            const { data: res } = await getUpcomingActivities();
            console.log(res,"asd")
            if (res) {
                let tempArr = [].concat.apply([], res.data)
                for (let el of tempArr) {
                    for (let ele of el.levels) {
                        let obj = {
                            levelObj: { ...ele },
                            ...el,
                        }
                        tempFinalArr.push(obj)
                    }
                }

                tempFinalArr = tempFinalArr.filter(el => el.levelObj.complete_status != true)
                setSkillsArr([...tempFinalArr])
            }
            console.log(JSON.stringify(tempFinalArr, null, 2))
        } catch (error) {
            console.error(error)
        }
    }

    const handleActivityRedirect = (id, obj) => {
        let path = `/Activity-Center-Detail/${id}`;
        history.push(path)
        localStorage.setItem("SelectedActivity", obj)
    }


    useEffect(() => {
        getActivities()
    }, [])

    return (
        <div>
            <div className="headerProfile">
                <img onClick={() => history.push("Profile")} src={back} className="shareImg" style={{ marginLeft: 15, }} alt="" />
                <div className="headerHeadings" style={{ color: "#fff", marginLeft: 0, paddingLeft: 0 }}>
                    Activities Upcoming
                </div>
            </div>
            <div className={styles.activityCardContainer}>
                {
                    skillsArr.length > 0 ?
                        skillsArr.map(el => {
                            return (

                                <div className={styles.activityCard} style={{ borderLeft: "solid 5px #B1D44E" }}>
                                    <p className={styles.activityCardHeading}>{el?.milestone?.skill?.name} : Day {el?.day}</p>
                                    <div className={styles.activityCardTxtBold}>
                                        {el?.name}
                                    </div>
                                    <div className={styles.activityCardTxt}>
                                        <span className={styles.activityCardTxtBold}>{el?.levelObj?.s_no}</span> : {el?.levelObj?.name}
                                    </div>
                                    {
                                        el?.levelObj?.complete_status == true ?
                                            <button className={styles.bluebutton}>Completed</button>
                                            :
                                            <button onClick={() => handleActivityRedirect(el.levelObj.id, el.levelObj)} className={styles.pinkbutton}>Go to Level</button>
                                    }

                                </div>

                            )
                        }) :
                        <>
                            <div className={styles.activityCard} style={{ borderLeft: "solid 5px #4985B2" }}>
                                <p className={styles.activityCardHeading}>No Data Found </p>
                            </div>
                        </>
                }
            </div>
            <DashboardFooter />
        </div>
    )
}
