import axios from "axios";
import { Authorisation, url } from "./Url";

const serverUrl = `${url}auth/`;

export const getOtpFromNumber = async (obj) => {
  try {
    let res = await axios.post(`${serverUrl}admin_login_otp`, obj);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const adminLogin = async (obj) => {
  try {
    let res = await axios.post(`${serverUrl}admin_login`, obj);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};



export const getUserChartDataByMonthForUser = async (id, Month) => {
  try {
    let res = await axios.get(
      `${url}user/${id}/age-chart?month=${Month}`
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getUserChartDataByAgeForUser = async (id, Age) => {
  try {
    let res = await axios.get(
      `${url}user/${id}/chart?age=${Age}`
    );
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUsersAge = async (id) => {
  try {
    let res = await axios.get(`${url}user/${id}/age`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getAdminCustomersDetails = async (id) => {
  try {
    let token = localStorage.getItem("ADMIN_AUTH_TOKEN");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let res = await axios.get(`${url}myUser/${id}/detail`, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllCustomers = async () => {
  try {
    let res = await axios.get(`${url}users`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getCustomPlanFromAdmin = async (id) => {
  try {
    let res = await axios.get(`${url}custom-plan/${id}`);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const setCustomPackageFromAdmin = async (obj) => {
  try {
    let token = localStorage.getItem("ADMIN_AUTH_TOKEN");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let res = await axios.post(`${url}custom-plan`, obj, config);
    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
