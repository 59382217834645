import React from 'react'

export default function PrivacyPolicy() {
    return (
        <>
            <div className="container">
                <div className="rowd-flex justify-content-center">

                    <div style={{ fontWeight: "bold", marginTop: 70 }}>Privacy Policy
                        <br />
                        <br />
                        Childwise Private Limited (CompassTot) Privacy Policy
                        <br />
                        <br />
                        PLEASE READ THE FOLLOWING CAREFULLY BEFORE INSTALLING AND/OR USING CHILDWISE
                        PVT. LTD.’S WEBSITE<br />
                        <br /> AND OR MOBILE SOFTWARE APPLICATION.
                    </div>

                    <div style={{ marginTop: 20 }}>
                        PLEASE READ THE FOLLOWING CAREFULLY BEFORE INSTALLING AND/OR USING CHILDWISE PVT.
                        LTD.’S WEBSITE AND/OR MOBILE SOFTWARE APPLICATION AND/OR PRODUCTS AND/OR
                        SERVICES.
                    </div>
                    <div style={{ marginTop: 20 }}>
                        This is the privacy policy (“Privacy Policy”) that governs how we, Childwise Pvt. Ltd. (“Childwise”,
                        “CompassTot”, “we”, “our” or “us”), use Personal Information (defined below) that we collect,
                        receive and store about individuals in connection with the use of the CompassTot website
                        and/or mobile software application (the “App”) and our products and services.
                    </div>
                    <div style={{ marginTop: 20 }}>
                        We do not ourselves host the App, all hosting is done by third party service providers that we
                        engage (such as Amazon Web Services). This means that data you provide us or that we collect
                        from you through the App (including any Personal Information, as defined below) – as further
                        described in this Privacy Policy – is hosted with such third party service providers on servers that
                        they own or control. When we contract third party servers and third party hosting services, we
                        do our best efforts to use credible service providers. Such third parties and their servers may be
                        located anywhere in the world. Your data may even be replicated across multiple servers
                        located in multiple countries. We may selectively share Personal Information with our affiliates
                        and/or partners and/or vendors and/or service providers, to bring certain offers/benefits to you
                        and other users. So please be aware that if you use any of our products and/or services, you are
                        consenting to your data being transferred to our affiliates and/or partners and/or vendors
                        and/or service providers around the world and that your consent is the legal basis for the
                        national and/or international transfer of any of your Personal Information. In addition to this
                        Privacy Policy, we may make specific disclosures to you and/or obtain specific consents from
                        you from time to time, which we encourage you to carefully read at all times, since all of these
                        disclosures and/or consents shall also form part of this Privacy Policy.
                    </div>
                    <div style={{ marginTop: 20 }}>
                        CompassTot reserves the right to change these terms from time to time as it sees fit and your
                        continued use of the website and/or App shall signify your acceptance of any modifications to
                        these terms. We, therefore, recommend that you re-read this statement on a regular basis.
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <b>Introduction:</b>
                        <br />
                        <br />
                        We have implemented this Privacy Policy because your privacy, and the privacy of other users, is
                        important to us. This Privacy Policy explains our online information practices and the choices
                        you can make about the way your Personal Information is collected and used in connection with
                        the App. “Personal Information” means any information that personally identify an individual or
                        that may be used, either alone or in combination with other information, to personally identify
                        an individual, including, but not limited to, a first and last name, a personal profile, an email
                        address, a home or other physical address, a phone number or other contact information.
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <b>EULA:</b>
                        <br />
                        <br />
                        This Privacy Policy forms part of our App’s End User License Agreement which can be viewed
                        from within the App and which you must expressly accept prior to downloading, installing
                        and/or using the App for the first time (“EULA”). Any capitalized but undefined term in this
                        Privacy Policy shall have the meaning given to it in the EULA.
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <b>Consent and Modification:</b>
                        <br />
                        <br />
                        You are not legally obligated to provide us Personal Information and/or to use our App, and you
                        hereby confirm that providing us Personal Information and using our App is at your own free
                        will. By ticking the relevant box and, in any event, by using our App, you consent to the terms of
                        this Privacy Policy and to our collection, processing and sharing of Personal Information for the
                        purposes and in the way set forth herein. If you do not agree to this Privacy Policy, please do not
                        access or otherwise use our App. We reserve the right, at our discretion, to change this Privacy
                        Policy at any time. Such change will be effective upon posting of the revised Privacy Policy on
                        our website, and your continued use of our website and/or App thereafter or, when applicable,
                        your consent to such changes means that you accept those changes. Please check this Privacy
                        Policy periodically to inform yourself of any changes.
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <b>What Personal Information We Collect and How We Collect It:</b>
                        <br />
                        <br />
                        We receive and/or collect Personal Information from you in the following ways:
                        <br />
                        <br />
                        <b>
                            Account:
                        </b>
                        <br />
                        In order to use some or all of the App features, you are required to create an account
                        (“Account”). If you create an Account, you will be required to provide us with certain
                        information, including but not limited to, your name, email address, mobile phone number as
                        well as a password that you will use for your Account (“Account Information”), your children’s
                        names, their gender, and their age. You may be required to provide additional Personal
                        Information as part of the Account registration process and in connection with your ongoing use
                        of your Account. We may send you a text message to your mobile phone or an e-mail to the e-
                        mail address you submit to confirm your registration and/or to inform you of new product and
                        service offerings. If you choose not to create an Account, you may not be able to access or use
                        some or all of the App features.
                        <br />
                        <br />
                        <b>
                            Log Files:
                        </b>
                        <br />
                        We may make use of log files. The information inside the log files includes internet protocol (IP)
                        addresses, type of browser, Internet Service Provider (ISP), date/time stamp, referring/exit
                        pages, clicked pages and any other information your browser may send to us. We may use such
                        information to analyse trends, track users’ movement, gather demographic and/or statistical
                        information and such other reasons that may help Us improve Our products and/or services and
                        create new products and/or services offerings.
                        <br />
                        <br />
                        <b>
                            Cookies and Other Tracking Technologies:
                        </b>
                        <br />
                        Our App may utilize “cookies”, anonymous identifiers and other tracking technologies in order
                        for us to operate our App and present you with information that is customized for you. A
                        “cookie” is a small text file that may be used, for example, to collect information about activity
                        on the App. Certain cookies and other technologies may serve to recall Personal Information,
                        such as an IP address, previously indicated by a user. Most browsers allow you to control
                        cookies, including whether or not to accept them and how to remove them. You may set most
                        browsers to notify you if you receive a cookie, or you may choose to block cookies with your
                        browser. “Cookie” is used to confirm registered user.
                        <br />
                        <br />
                        <b>
                            Location Data:
                        </b>
                        <br />
                        We may provide certain location-based services (“Location Services”) in connection with the
                        App that are dependent on data related to the geographic location of your mobile device on
                        which the App is installed (“Location Data”). If you choose to participate in these Location
                        Services, you agree that we may collect or obtain your Location Data. You may be able to use
                        the settings on your mobile device to turn off location-sharing features.
                        <br />
                        <br />
                        <b>
                            Mobile Device Data:
                        </b>
                        <br />
                        We may collect limited information from your mobile device in order to provide the App. Such
                        information may include your mobile device type, mobile device id, and date and time stamps of
                        App use. In addition, we may deploy tracking technologies within the App to help us gather
                        aggregate.
                        <br />
                        <br />
                        <b>
                            Google Analytics and Analytics Tools:
                        </b>
                        <br />
                        The App may use analytics tools, such as “Google Analytics”, to collect information about use of
                        the App. Google Analytics collects information such as how often users visit this site, what pages
                        they visit when they do so, and what other sites they used prior to using our App. We use the
                        information we get from Google Analytics to maintain and improve our App, our products and
                        our services. Google’s ability to use and share information collected by Google Analytics about
                        your visits to the App is regulated by the Google Analytics Terms of Service, available at
                        https://marketingplatform.google.com/about/analytics/terms/us/
                        Policy, available at https://policies.google.com/privacy. You may prevent your data from being
                        used by Google Analytics by downloading and installing the Google Analytics Opt-out Browser
                        Add-on, available at https://tools.google.com/dlpage/gaoptout/.
                        <br />
                        <br />
                        <b>
                            The Way We Use Personal Information:
                        </b>
                        <br />
                        If you submit and/or We collect Personal Information through our App, then We may use such
                        Personal Information in the following ways, via any applicable communication channel, means,
                        support or format, including e-mail and electronic communications, etc. We will use your
                        Personal Information to provide and improve our App, to contact you in connection with the
                        App and certain programs, features or offerings that you may have registered for, and to
                        identify and authenticate your access to the parts of the App that you are authorized to access.
                        We may use your designated email address and/or mobile phone number to: (i) send you
                        updates or news regarding our App, our products and our services; and/or (ii) respond to a
                        “Contact Us” or a request (for example, to change your password); and/or (iii) inform you of
                        various product and service offerings of ours and/or our partners; and/or (iv) providing
                        customer service and sending confirmations about your account. Notwithstanding your
                        telephone/mobile is registered with Customer Preference Registration Facility (CPRF) and/or
                        notwithstanding the Telecom Regulatory Authority of India [TRAI] restrictions/guidelines on
                        unsolicited tele-calls or guidelines/regulations on commercial communications or other TRAI
                        Regulations/guidelines, or any other Regulations/guidelines of any
                        governmental/quasi-judicial/judicial authorities, whether in India or anywhere in the world, by
                        you accessing the website/App of CompassTot and or by logging in with User ID and password
                        and/or by you using the website/App of CompassTot and/or leaving CompassTot’s website/App
                        half way enquiring/verifying/registering for website/App of CompassTot as to products/Services
                        of CompassTot and/or CompassTot’s affiliates whether specifically disclosed to you or not,
                        and/or by making a request to call you back, it is deemed that you have expressly authorized
                        CompassTot to provide your phone number/mobile number, email ID and other contact
                        details/personal details of you and details as to Services availed/product purchased by you from
                        CompassTot to CompassTot’s affiliates and/or partners and/or vendors and/or service providers and
                        CompassTot can at its sole discretion provide/share your phone number/mobile number,
                        email ID and other contact details/personal details to CompassTot’s affiliates and/or partners
                        and or vendors and or service providers, and in this regard you are hereby authorizing
                        CompassTot and its affiliates and/or partners and/or vendors and/or service providers to call
                        you or send you text messages/electronic communications, for solicitation and
                        procurement/cross selling of various products and/or services of CompassTot and/or various
                        products and services of CompassTot’s affiliates and/or partners and/or vendors and/or service
                        providers, including the upgrading and/or renewal of services availed/product purchased by you
                        from CompassTot and/or CompassTot’s affiliates and/or partners and/or vendors and/or service
                        providers. In this regard you shall be deemed to have provided your consent to CompassTot and
                        CompassTot’s affiliates and/or partners and/or vendors and/or service providers, as per TRAI
                        guidelines/regulations and accordingly you shall not have any complaint on whatever grounds
                        nor complain to TRAI or any other governmental/quasi-judicial/judicial authorities, whether in
                        India or anywhere in the world, as to any alleged unsolicited calls/messages/ communication by
                        CompassTot and/or CompassTot’s affiliates and/or partners and/or vendors and/or service
                        providers to you.” We may transfer your Personal Information to our local and/or foreign
                        subsidiaries and CompassTot’s affiliates and/or partners and/or vendors and/or service
                        providers for the purposes of storing or processing such information on our behalf and/or
                        assisting us with our business and/or technical operations and/or to provide our App and/or to
                        inform you and other users of various products and services offerings. Such information may be
                        transferred to any country in the world and you hereby consent to such international transfer.
                        We use commercially reasonable efforts to engage or interact with third party service providers
                        and partners that protect, respect and honor privacy and data protection rights and/or offer an
                        equivalent or similar level of protection as the one we offer. We may disclose your Personal
                        Information or any information you submitted via the App if we have a good faith belief that
                        disclosure of such information is helpful, covered by a legitimate reason, or reasonably
                        necessary to: (i) comply with any applicable law, regulation, legal process or governmental
                        request; (ii) enforce our EULA, including investigations of potential violations thereof; (iii)
                        detect, prevent, or otherwise address fraud, security issues and violations of applicable laws or
                        third party rights; or (iv) protect against harm to the rights, property or safety of CompassTot,
                        our App, our website, our products, our services, our users, our employees, our investors,
                        yourself or the public.
                        <br />
                        <br />
                        <b>
                            Use of Anonymous Information:
                        </b>
                        <br />
                        We may use Anonymous Information (as defined below) or disclose it to third party service
                        providers in order to improve our App, our products and our services and enhance your
                        experience with our App, our products and our services. We may also disclose Anonymous
                        Information (with or without compensation) to third parties, including advertisers and partners.
                        “Anonymous Information” means information which does not identify or enable identification of
                        an individual user, such as aggregated information about the use of our App.
                        <br />
                        <br />
                        <b>
                            Access/Accuracy:
                        </b>
                        <br />
                        To the extent that you do provide us with Personal Information, we wish to maintain accurate
                        Personal Information. If you would like to update any of your Personal Information that we may
                        be storing, you may use the tools that we make available on the App and/or website and/or
                        contact our representative.
                        <br />
                        <br />
                        <b>
                            Links to and Interaction with Third Party Products:
                        </b>
                        <br />
                        Our App may enable you to interact with or contain links to other third party websites, mobile
                        software applications and services or content that are not owned or controlled by us (each a
                        “Third Party Service”). We are not responsible for the privacy practices or the content of such
                        Third Party Services. Please be aware that such Third Party Services may collect Personal
                        Information from you. Accordingly, we encourage you to read the terms and conditions and
                        privacy policy of each Third Party Service that you choose to use or interact with.
                        <br />
                        <br />
                        <b>
                            Security:
                        </b>
                        <br />
                        The security of Personal Information is important to us. We follow generally accepted industry
                        standards, including the use of appropriate administrative, physical and technical safeguards, to
                        protect the Personal Information submitted to us. However, no method of transmission over the
                        Internet, or method of electronic storage, is 100% secure. Therefore, while we strive to use
                        reasonable acceptable means to protect your Personal Information, we cannot guarantee its
                        absolute security or confidentiality.
                        <br />
                        <br />
                        <b>
                            Merger, Sale or Bankruptcy:
                        </b>
                        <br />
                        In the event that we are acquired by or merged with a third party entity, or in the event of
                        bankruptcy or a comparable event, we reserve the right to transfer or assign Personal
                        Information in connection with the foregoing events.
                    </div>

                </div>
            </div>
        </>
    )
}
