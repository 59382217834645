import React, { useEffect, useState } from 'react'

import Adminhomepagefooter from '../AdminHomepageFooter/Adminhomepagefooter';
import styles from "./ManageCSM.module.css";
import back from "../../../assets/img/back.png";
import { useHistory } from 'react-router-dom';
import Loader from "react-loader-spinner";
import { getCSM } from '../../../Services/CSM';
import Swal from 'sweetalert2';
export default function ManageCSM() {
    const history = useHistory();

    const handleBackNavigation = () => {
        let path = `Admin-Dashboard`;
        history.push(path);
    }
    const [csmArr, setCsmArr] = useState([]);
    const [loading, setLoading] = useState(false);



    const getAllCSM = async () => {
        try {
            setLoading(true)
            let res = await getCSM()
            if (res.status == 200 || res.status == 304 || res.status == 202) {
                console.log("activity", res.data.data)
                setCsmArr(res.data)
                setLoading(false)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }
    }





    useEffect(() => {
        getAllCSM()
    }, [])



    return (
        <div>
            <div className={`${styles.header}`}>
                <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div>


                <div className={`${styles.headerHeading}`}>
                    Customer Success Managers
                </div>
                <div className={styles.headerBtn} onClick={() => history.push("Add-CSM")}>
                    Add CSM
                </div>
            </div>
            <div className={`${styles.pageHeading}`}>Detailed List</div>
            <div className={`${styles.containerdiv} container`}>
                <div className="row">
                    <div className="mx-auto col-lg-10 col-11">
                        {
                            loading ?
                                <>
                                    <div className="row mt-5 justify-content-center d-flex">
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Loader
                                                visible={true}
                                                type="TailSpin"
                                                color="#FF6F96"
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="table-responsive">
                                    <table className="table table-borderless">
                                        <thead className="thead-Bg">
                                            <tr>
                                                <th scope="col">S.No.</th>
                                                <th scope="col">Name Of CSM</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone Number</th>
                                                <th scope="col">List of Children Assigned</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {

                                                csmArr.length > 0 ? csmArr.map((el, index) => {
                                                    return (

                                                        <tr key={el.id}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{el.name}</td>
                                                            <td>{el.email}</td>
                                                            <td>{el.mobile}</td>
                                                            <td>
                                                                <button onClick={() => history.push(`CSM-Customers/${el.id}`)} className={`${styles.pinkBtn}`}>View</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                    :
                                                    <tr >
                                                        <td colSpan={4}>
                                                            <div className={styles.milestonesCardItem}>
                                                                <div className={styles.milestonesCardItemTxt}>No CSM Found</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                            }

                                        </tbody>
                                    </table>


                                </div>
                        }
                    </div>
                </div>
            </div>
            <Adminhomepagefooter />
        </div>
    )
}
