import React, { useState, useContext, useEffect } from 'react'
import styles from "./Settings.module.css"
import DashboardFooter from '../Dashboard/DashboardFooter/DashboardFooter'
import Modal from "react-bootstrap/Modal";
import back from "../../assets/img/back.png";

import { Line } from 'react-chartjs-2';


////////images
// import share from "../../assets/img/share.png";
import userIcon from "../../assets/img/userIcon.png";
import PaymentHistory from "../../assets/img/PaymentHistory.png";
import LogoutIcon from "../../assets/img/LogoutIcon.png";

import logout from '../../assets/img/logout.png';
import activitiesCompleted from '../../assets/img/activitiesCompleted.png';
import { useHistory } from 'react-router-dom'

import { AuthContext, jwtContext } from '../../App';
import { getUserchart, getUserProfile } from '../../Services/User';
export default function Settings() {
    const history = useHistory();
    const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
    const [decodedJwt, setDecodedJwt] = useContext(jwtContext);
    const [userdata, setUserdata] = useState({});
    const [chartMonthsArr, setChartMonthsArr] = useState([]);

    const handleGoBack = () => {
        history.goBack();
    }


    const handleLogout = () => {
        localStorage.removeItem('FAVCY_AUTH_TOKEN')
        localStorage.removeItem('userObj')
        localStorage.removeItem('ADMIN_AUTH_TOKEN')
        localStorage.removeItem('role');
        setIsAuthorized(false)
        setDecodedJwt({ role: '' })
        history.push('/')
    }


    useEffect(() => {
    }, [])

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        min: 0,
                        beginAtZero: true,
                        scaleBeginAtZero: true,
                    },
                },
            ],
        },
    };
    return (
        <>
            <div className="headerProfile px-3">
                <img onClick={() => handleGoBack()} src={back} className="shareImg" alt="" />
                <div className="headerHeadings" style={{ color: "#fff" }}>
                    Settings
                </div>
            </div>
            <div className={styles.mainProfileContainer}>
                <div className={styles.cardContainer} onClick={() => history.push('/User-Personal-Details')}>
                    <img src={userIcon} className={styles.leftIcon} alt="" />
                    <div className={styles.textContainer}>
                        <div className={styles.optionsTextHeading}>
                            Personal Details
                        </div>
                        <div className={styles.optionsText}>
                            Edit Details
                        </div>
                    </div>
                </div>
                <div className={styles.cardContainer} style={{ borderLeftWidth: 5, borderLeftColor: "#FF6F96" }} onClick={() => history.push('/User-Payments')}>
                    <img src={PaymentHistory} className={styles.leftIcon} alt="" />
                    <div className={styles.textContainer}>
                        <div className={styles.optionsTextHeading}>
                            My Payment Details
                        </div>
                        <div className={styles.optionsText}>
                            View your payments
                        </div>
                    </div>
                </div>
                <div className={styles.cardContainer} style={{ borderLeftWidth: 5, borderLeftColor: "#FFC66C" }} onClick={() => handleLogout()}>
                    <img src={LogoutIcon} className={styles.leftIcon} alt="" />
                    <div className={styles.textContainer}>
                        <div className={styles.optionsTextHeading}>
                            Logout
                        </div>
                        <div className={styles.optionsText}>
                            Securely logout.
                        </div>
                    </div>
                </div>

            </div>

            <DashboardFooter />
        </>
    )

}
