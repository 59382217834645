import axios from "axios";
import { Authorisation, url } from "./Url";

const serverUrl = `${url}level`

let token = localStorage.getItem("ADMIN_AUTH_TOKEN")

const authorisation = `Bearer ${token}`

export const addlevel = async (obj) => {
    try {
        let config = {

            headers: {
                Authorization: authorisation,
            }
        }
        let res = await axios.post(`${serverUrl}`, obj, config)
        return res
    }
    catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getlevels = async () => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${serverUrl}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const deletelevels = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.delete(`${serverUrl}/${id}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const editlevels = async (id, obj) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.post(`${serverUrl}/${id}`, obj, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getlevelsById = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${serverUrl}/${id}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getOnelevelById = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${url}single-level/${id}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}
export const submitLevel = async (obj) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.post(`${url}level-submit`, obj, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

