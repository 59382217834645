import axios from "axios";
import { url } from "./Url";

const serverUrl = `${url}milestone`

let token = localStorage.getItem("ADMIN_AUTH_TOKEN")

const authorisation = `Bearer ${token}`


export const addMilestone = async (obj) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        console.log(obj, "final obj")
        let res = await axios.post(`${serverUrl}`, obj, config)
        return res
    }
    catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getMilestones = async () => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${serverUrl}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const deleteMilestones = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.delete(`${serverUrl}/${id}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const editMilestones = async (id, obj) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.put(`${serverUrl}/${id}`, obj, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getMilestoneById = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${serverUrl}/${id}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

