import React, { useEffect, useState } from 'react'
import styles from './AdminActivity.module.css'
import Modal from "react-bootstrap/Modal";
import S3 from 'react-aws-s3';
import { config } from '../../../Services/BucketConfig';

import Adminhomepagefooter from "../AdminHomepageFooter/Adminhomepagefooter"
import edit from "../../../assets/img/edit.png";
import deleteIcon from "../../../assets/img/deleteIcon.png";
import back from "../../../assets/img/back.png";
import chevronRightBlack from "../../../assets/img/chevronRightBlack.png";
import DeleteActivity from "../../../assets/img/DeleteActivity.png";
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";
import { useHistory } from 'react-router-dom';

import helicopterLeft from "../../../assets/img/helicopter_cloud_modal_left.png";
import cloudRight from "../../../assets/img/cloud_modal_right.png";
import { useLocation, useParams } from 'react-router';
import { getSkillById } from '../../../Services/Skill';
import { getActivity, deleteActivity, addActivity } from '../../../Services/Activity';
import { editMilestones, getMilestoneById } from '../../../Services/Milestone';
import { getAgeData } from '../../../Services/Test';
const ReactS3Client = new S3(config);


/////////////////////////////////////////// Add Acitivity ///////////////////////////////////////////////////////////////////////////////////////////////////////

function MyVerticallyCenteredModal({ activityArr, handleadd, ...props }) {
    const [serialNumber, setSerialNumber] = useState(1);
    const [name, setName] = useState("");
    const [additionalDetails, setAdditionalDetails] = useState("");
    const [extraOptions, setExtraOptions] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [day, setDay] = useState("");


    const handleActivityCreate = () => {

        if (name == "") {
            Swal.fire({
                title: 'Error',
                type: 'error',
                text: `Please Enter Activity Name`,
            });
        }
        else if (additionalDetails == '') {
            Swal.fire({
                title: 'Error',
                type: 'error',
                text: `Please Enter Activity Description`,
            });
        }
        else if (day == '') {
            Swal.fire({
                title: 'Error',
                type: 'error',
                text: `Please Enter a Day `,
            });
        }
        else {
            let obj = {
                s_no: serialNumber,
                name,
                additionalDetails,
                extraOptions,
                file: file?.name ? file : "",
                day: parseInt(day)
            }
            handleadd(obj);
            setFile(null);
            setAdditionalDetails('');
            setExtraOptions('');
            setName('');
            setDay(0);
            setFileName("");
            props.onHide()
        }
    }


    useEffect(() => {
        if (activityArr && activityArr.length > 0) {
            let serialNo = activityArr[activityArr.length - 1].s_no;
            if (serialNo) {
                let tempSerialNo = parseInt(serialNo.slice(1))
                setSerialNumber(`A${tempSerialNo + 1}`)
            }
        }
        else {
            setSerialNumber(`A1`)
        }
    }, [activityArr])
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className={styles.Modal} style={{ height: 640 }}>
                <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                <div className={styles.ModalInnerBox}>
                    <>

                        <div className={styles.modalHeading}>
                            Add Activity
                        </div>

                        <input type="text" value={serialNumber} disabled onChange={(e) => setSerialNumber(e.target.value)} placeholder="Serial Number (Automated)" className={styles.modalTextInput} />

                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Walking Through Complex Maze" className={styles.modalTextInput} />
                        <input type="number" value={day} onChange={(e) => setDay(e.target.value)} placeholder="set Day" className={styles.modalTextInput} />

                        <textarea type="text" value={additionalDetails} multiple={true} onChange={(e) => setAdditionalDetails(e.target.value)} placeholder="Walk through a simple 4 route maze." className={styles.modalTextInput} style={{ height: 80 }} />

                        <input type="text" value={extraOptions} onChange={(e) => setExtraOptions(e.target.value)} placeholder="Cardboard boxes, scissors" className={styles.modalTextInput} />

                        <label htmlFor="fileInput" className={`${styles.modalTextInput} ${styles.cameraBackgroundRight}`}>
                            <span className={`${styles.fileInputText}`}>
                                {fileName ? fileName : 'Upload Interactive Video'}
                            </span>
                            {/* setFile(e.target.files[0]) */}
                            <input type="file" id="fileInput" onChange={(e) => { setFile(e.target.files[0]); setFileName(e.target.files[0].name); }} placeholder="Upload Interactive Video" style={{ display: 'none' }} />
                        </label>
                    </>

                    <button onClick={() => handleActivityCreate()} style={{ marginTop: 10, marginBottom: 30, borderRadius: 4 }} className="blueBtnRegister">
                        Add
                    </button>

                </div>
            </Modal.Body>

        </Modal>
    );
}





/////////////////////////////////////////// Edit Milestone ///////////////////////////////////////////////////////////////////////////////////////////////////////

function MyVerticallyCenteredModal2({ milestoneObj, handleedit, ...props }) {
    const [milestoneId, setMilestoneId] = useState(0);
    const [milestoneName, setMilestoneName] = useState("");
    const [milestoneDescription, setMilestoneDescription] = useState("");
    const [otherInputs, setOtherInputs] = useState("");
    const [ageArr, setAgeArr] = useState([]);
    const [ageGroup, setAgeGroup] = useState("");
    const [ageGroupIdName, setAgeGroupIdName] = useState("");

    const handleMilestoneEdit = () => {
        if (milestoneName == "") {
            Swal.fire({
                title: 'Error',
                type: 'error',
                text: `Please Enter MileStone Name`,
            });
        }
        else if (milestoneDescription == '') {
            Swal.fire({
                title: 'Error',
                type: 'error',
                text: `Please Enter MileStone Description`,
            });
        }
        else if (ageGroup == '') {
            Swal.fire({
                title: 'Error',
                type: 'error',
                text: `Please Select Age Group`,
            });
        }
        else {
            let obj = {
                s_no: milestoneId,
                name: milestoneName,
                description: milestoneDescription,
                age_group_id: ageGroup,
                other: otherInputs,
                skill_id: milestoneObj.skill_id,
                status: 1,
            }
            handleedit(obj)
            props.onHide()
            setMilestoneName("");
            setMilestoneDescription("");
            setOtherInputs("");
            setAgeGroup("");
            setAgeGroupIdName("");
        }
    }



    const getAge = async () => {
        try {
            let res = await getAgeData()
            if (res.status === 200 || res.status === 304) {
                setAgeArr([...res.data])
            }
        }
        catch (err) {
            console.error(err)
            alert(err)
        }
    }



    useEffect(() => {
        getAge()
        console.log(milestoneObj, "milestone Obj")
        if (milestoneObj) {
            setMilestoneId(milestoneObj.s_no);
            setMilestoneName(milestoneObj.name ? milestoneObj?.name : "")
            setMilestoneDescription(milestoneObj.description ? milestoneObj?.description : "")
            setOtherInputs(milestoneObj.other ? milestoneObj?.other : "")
            setAgeGroup(milestoneObj?.age_group_id)
            // setAgeGroupIdName(parseInt(milestoneObj?.age_group_id)-1)

        }
    }, [milestoneObj])

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className={styles.Modal} style={{ height: 620 }}>
                <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                <div className={styles.ModalInnerBox}>
                    <>

                        <div className={styles.modalHeading}>
                            Edit Milestone
                        </div>
                        <input type="text" disabled value={milestoneId} onChange={(e) => setMilestoneId(e.target.value)} placeholder="Serial Number (Automated)" className={styles.modalTextInput} />
                        <input type="text" value={milestoneName} onChange={(e) => setMilestoneName(e.target.value)} placeholder="Milestone Name " className={styles.modalTextInput} />
                        <select onChange={(e) => setAgeGroup(e.target.value)} value={ageGroup} className={styles.modalTextInput}>
                            <option value="">Select Age Group</option>
                            {
                                ageArr.map(el => {
                                    return (
                                        <option key={el.age} value={el.id}>{el.age_group}</option>
                                    )
                                })
                            }
                        </select>
                        <textarea type="text" value={milestoneDescription} multiple={true} onChange={(e) => setMilestoneDescription(e.target.value)} placeholder="Milestone Description" className={styles.modalTextInput} style={{ height: 80 }} />
                        <textarea type="text" value={otherInputs} onChange={(e) => setOtherInputs(e.target.value)} placeholder="Other Input Options for the 
Milestone Format" className={styles.modalTextInput} />

                    </>

                    <button onClick={() => handleMilestoneEdit()} style={{ marginTop: 10, marginBottom: 30, borderRadius: 4 }} className="blueBtnRegister">
                        Update
                    </button>

                </div>
            </Modal.Body>

        </Modal>
    );
}


//////////////////////////////////// delete Activity //////////////////////////////////////////////////////////////////////////////////
function MyVerticallyCenteredModal3({ handledelete, ...props }) {

    const handleMilestoneDelete = () => {
        handledelete()
        props.onHide()
    }



    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className={styles.Modal} style={{ height: 400 }}>
                <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                <div className={styles.ModalInnerBox} style={{ height: 300 }}>
                    <>
                        <img src={DeleteActivity} className={styles.deleteModalIcon} alt="" />

                        <div className={styles.modalHeading} style={{ textAlign: "center" }}>

                            Delete Activity
                        </div>



                        <div className={styles.ModalTxt}>
                            Are you sure you want to delete
                            this activity? The next activity will
                            take its place in the list.
                        </div>

                        <div className={`${styles.flexRowBetween} mt-4`}>


                            <button onClick={() => handleMilestoneDelete()} style={{ width: "45%", marginTop: 10, marginRight: "2%", marginBottom: 30, borderRadius: 4 }} className="blueBtnRegister">
                                Yes
                            </button>
                            <button onClick={() => props.onHide()} style={{ width: "45%", marginTop: 10, marginBottom: 30, borderRadius: 4, backgroundColor: "#FF6F96" }} className="blueBtnRegister">
                                No
                            </button>
                        </div>
                    </>
                </div>
            </Modal.Body>

        </Modal>
    );
}

export default function AdminActivity() {
    const history = useHistory();
    let id = useParams();
    const [modalShow, setModalShow] = useState(false);
    const [editMilestoneModalShow, setEditMilestoneModalShow] = useState(false);
    const [deleteActivityModalShow, setDeleteActivityModalShow] = useState(false);
    const [activityArr, setActivityArr] = useState([]);
    const [activityIdforDeletion, setActivityIdforDeletion] = useState("");

    const [milestoneObj, setMilestoneObj] = useState({});
    const params = useParams()
    const [loading, setLoading] = useState(false);
    const handleBackNavigation = () => {
        history.goBack();
    }

    const handleRedirect = (id) => {
        let path = `/Admin-Level/${id}`;
        history.push(path);
    }










    const handleActivityAdd = async (obj) => {
        try {
            if (obj?.file) {

                ReactS3Client
                    .uploadFile(obj?.file, obj?.file?.name)
                    .then(async (data) => {
                        setLoading(true)
                        let formData = new FormData();
                        formData.append("s_no", obj.s_no);
                        formData.append("name", obj.name);
                        formData.append("media", data.location);
                        formData.append("description", obj.additionalDetails);
                        formData.append("extra", obj.extraOptions);
                        formData.append("status", 1);
                        formData.append("milestone_id", milestoneObj.id);
                        formData.append("day", obj.day);
                        let res = await addActivity(formData)
                        if (res.status == 200 || res.status == 304 || res.status == 202) {
                            console.log(res.data)
                            Swal.fire({
                                title: 'Success',
                                type: 'success',
                                text: `${res?.data?.message}`,
                            });
                            getMilestone()
                            setLoading(false)
                        }
                    })
                    .catch(err => console.error(err))
            }
            else {
                let formData = new FormData();
                formData.append("s_no", obj.s_no);
                formData.append("name", obj.name);
                formData.append("media", "");
                formData.append("description", obj.additionalDetails);
                formData.append("extra", obj.extraOptions);
                formData.append("status", 1);
                formData.append("milestone_id", milestoneObj.id);
                formData.append("day", obj.day);
                let res = await addActivity(formData)
                if (res.status == 200 || res.status == 304 || res.status == 202) {
                    console.log(res.data)
                    Swal.fire({
                        title: 'Success',
                        type: 'success',
                        text: `${res?.data?.message}`,
                    });
                    getMilestone()
                    setLoading(false)
                }
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }



    }


    const handleMilestoneEdit = async (obj) => {
        try {

            setLoading(true)
            let res = await editMilestones(id.id, obj)
            if (res.status == 200 || res.status == 304 || res.status == 202) {
                console.log(res.data)
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.message}`,
                });
                getMilestone()
                setLoading(false)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)
            }
        }

    }
    const getMilestone = async () => {
        try {
            setLoading(true)
            let res = await getMilestoneById(id.id)
            if (res.status == 200 || res.status == 304 || res.status == 202) {
                // console.log(res.data, "milestones")
                setMilestoneObj(res.data.data);
                setActivityArr(res.data.data.activities)
                setLoading(false)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }

    }
    // const getAct = async () => {
    //     try {
    //         setLoading(true)
    //         let res = await getActivity()
    //         if (res.status == 200 || res.status == 304 || res.status == 202) {
    //             setLoading(false)
    //             console.log(res.data)
    //             setActivityArr(res.data)
    //         }
    //     }
    //     catch (err) {
    //         if (err?.response?.data?.message) {
    //             console.error(err?.response?.data?.message);
    //             Swal.fire({
    //                 title: 'Error',
    //                 type: 'error',
    //                 text: `${err?.response?.data?.message}`,
    //             });
    //         }
    //         else {
    //             console.error(err)

    //             Swal.fire({
    //                 title: 'Error',
    //                 type: 'error',
    //                 text: `${err?.message}`,
    //             });

    //         }
    //     }

    // }
    const handleActivityDelete = async () => {
        try {
            setLoading(true)
            let res = await deleteActivity(activityIdforDeletion)
            if (res.status == 200 || res.status == 304 || res.status == 202) {
                setLoading(false)
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res.data.message}`,
                });
                getMilestone()
                // console.log(res.data)
                // setActivityArr(res.data)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)

                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }

    }

    useEffect(() => {
        getMilestone()
    }, [])


    return (
        <div>
            <div className={`${styles.header}`}>
                <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div>


                <div className={`${styles.headerHeading}`}>
                    Activities for {milestoneObj?.name}
                </div>
                <div className={styles.btn} onClick={() => setEditMilestoneModalShow(true)} >
                    <img src={edit} alt="" />
                </div>
            </div>
            <div className={`${styles.containerdiv} container`}>

                <div className="row d-flex justify-content-center">
                    {
                        loading ?
                            <>
                                <div className="row mt-5 justify-content-center d-flex">
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Loader
                                            visible={true}
                                            type="TailSpin"
                                            color="#FF6F96"
                                            height={100}
                                            width={100}
                                        />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className={`${styles.milestoneCard} col-lg-7 col-11`} >
                                    <div className={styles.milestonesHeading}>
                                        Activities List - ({milestoneObj?.name})
                                    </div>

                                    {
                                        activityArr && activityArr.length > 0 ?
                                            activityArr.map((el, index) => {
                                                return (
                                                    <div key={el.id} className={styles.milestonesCardItem} onClick={() => handleRedirect(el.id)}>
                                                        <div className={styles.milestonesCardItemTxt}>{index + 1}. {el?.name}</div>
                                                        <div>
                                                            {/* handleActivityDelete(el.id) */}
                                                            {/*  */}
                                                            <img onClick={(e) => { e.stopPropagation(); setDeleteActivityModalShow(true); setActivityIdforDeletion(el.id) }} style={{ height: 25, width: 25, marginRight: 15 }} className={styles.milestonesCardItemIcon} src={deleteIcon} alt="" />
                                                            <img className={styles.milestonesCardItemIcon} src={chevronRightBlack} alt="" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <>
                                                <div className={styles.milestonesCardItem}>
                                                    <div className={styles.milestonesCardItemTxt}>No Activities found for this Milestone</div>
                                                </div>
                                            </>
                                    }

                                </div>

                                <div className="col-12 d-flex justify-content-center mt-5">

                                    <button onClick={() => { setModalShow(true) }} className={styles.addBtn}>
                                        Add Activity
                                    </button>
                                </div>
                            </>
                    }
                </div>

            </div>

            <MyVerticallyCenteredModal
                handleadd={handleActivityAdd}
                show={modalShow}
                activityArr={activityArr}
                onHide={() => setModalShow(false)}
            />

            <MyVerticallyCenteredModal2
                handleedit={handleMilestoneEdit}
                show={editMilestoneModalShow}
                milestoneObj={milestoneObj}
                onHide={() => setEditMilestoneModalShow(false)}
            />
            <MyVerticallyCenteredModal3
                handledelete={handleActivityDelete}
                show={deleteActivityModalShow}
                onHide={() => setDeleteActivityModalShow(false)}
            />







            <Adminhomepagefooter />
        </div>
    )
}
