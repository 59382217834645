import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import DashboardFooter from '../Dashboard/DashboardFooter/DashboardFooter'
import styles from './ActivityCenterDetails.module.css';
//////video
import video from "../../assets/videos/video.mp4";
import videoPlayBtn from '../../assets/img/videoPlayBtn.png';
import back from '../../assets/img/back.png';
import language from '../../assets/img/language.png';
import whyIsThisImportant from '../../assets/img/whyIsThisImportant.png';
import { getOnelevelById, submitLevel } from '../../Services/Level';
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";


export default function ActivityCenterDetails() {
    let id = useParams();

    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState({});
    const [levelDetailsObj, setLevelDetailsObj] = useState({});
    const [activityObj, setActivityObj] = useState();
    const history = useHistory()
    const [isMaxValueSelected, setIsMaxValueSelected] = useState(false);
    const handleRedirect = () => {
        history.goBack()
    }

    const getLevel = async () => {
        try {
            setLoading(true)
            let tempActivity = localStorage.getItem("SelectedActivity")
            setActivityObj(tempActivity)
            console.log(activityObj)
            let res = await getOnelevelById(id.id)
            if (res.data.data) {
                setLoading(false)
                console.log(res.data.data)
                setLevelDetailsObj(res.data.data)
                handleOptionSelectOnInit(res.data.data.anwser, res.data.data)
            }
        }
        catch (err) {
            console.log(JSON.stringify(err?.response), null, 2);
            if (err?.response?.data?.message) {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.log(err.response)
                // alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)

            }
        }
    }



    const handleSubmit = async () => {
        try {
            if (!selectedOption.created_at) {
                alert("Please Select an option to submit")
            }
            else {

                let obj = {
                    activity_id: levelDetailsObj.activity_id,
                    level_id: selectedOption.level_id,
                    option_id: selectedOption.id,
                }
                console.log(obj)
                let res = await submitLevel(obj)
                if (res.data.status == 200 || res.data.status == 304) {
                    setLoading(false)
                    Swal.fire({
                        title: 'Success',
                        type: 'success',
                        text: `${res.data.message}`,
                    });
                    handleRedirect()
                }
            }
        }
        catch (err) {
            console.log(JSON.stringify(err?.response), null, 2);
            if (err?.response?.data?.message) {
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.log(err.response)
                // alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)

            }
        }
    }


    const checkOptionActive = (id) => {
        if (selectedOption.id == id) {
            return true
        }
        else {
            return false
        }
    }


    const handleOptionSelectOnInit = (obj, data) => {
        console.log(levelDetailsObj.options)
        let points = data?.options.map(el => parseInt(el?.points))
        let maxPoint = Math.max(...points)
        if (obj?.points == maxPoint) {
            setIsMaxValueSelected(true)
        }
        else {
            setIsMaxValueSelected(false)
        }
        if (obj) {
            obj['id'] = obj.level_option_id
            console.log(obj)
            // let tempObj = {...obj, obj.id: obj.level_option_id}
            // console.log(tempObj)
            setSelectedOption(obj)
        }

    }


    const handleOptionSelect = (obj) => {
        let points = levelDetailsObj.options.map(el => parseInt(el.points))
        let maxPoint = Math.max(...points)
        if (obj.points == maxPoint) {
            setIsMaxValueSelected(true)
        }
        else {
            setIsMaxValueSelected(false)
        }
        console.log(levelDetailsObj.options)
        setSelectedOption(obj)
    }




    useEffect(() => {
        getLevel()
    }, [])


    return (
        <>
            <div className={styles.Header}>
                <img onClick={() => handleRedirect()} className={styles.FilterIcon} alt="filter-icon" src={back} />
                <span className={styles.headerHeading}>{levelDetailsObj.name}</span>
            </div>
            {
                levelDetailsObj?.media &&
                <div className={styles.introVideo}>
                    <div className={styles.videoOverlay}>
                        <img src={videoPlayBtn} alt="" style={{ marginBottom: 15 }} />
                        Intro Video of What to Expect For The Age Group
                        <div className={styles.overlayBtnContainer}>
                            <img style={{ height: 50, width: 120 }} src={language} alt="overlay-btn" />
                            <img style={{ height: 50, width: 180 }} src={whyIsThisImportant} alt="overlay-btn" />
                        </div>
                    </div>
                    <video src={video} controls />
                </div>
            }

            <div className={styles.container}>
                <div className={styles.pageHeading}>Level Details</div>



                <div className={styles.questionContainer} style={{ textAlign: "justify" }}>
                    <p style={{ textAlign: "justify" }}>
                        {levelDetailsObj.name}
                    </p>
                    <p style={{ textAlign: "justify" }}>
                        {`${levelDetailsObj?.description}`.split("&&&")?.map(el => {
                            return (

                                <p style={{ textAlign: "justify" }} key={el.id}>
                                    {el}
                                </p>
                            )
                        })}
                    </p>

                </div>
                {
                    levelDetailsObj.options && levelDetailsObj.options.length > 0 && levelDetailsObj.options.map(el => {
                        return (
                            <div style={{ textAlign: "justify" }} key={el?.id} onClick={() => handleOptionSelect(el)} className={checkOptionActive(el.id) ? styles.selectedOption : styles.answerContainer}><span>{el?.option_text}</span>
                                {/* <div className="ms-auto d-flex align-items-center"> */}
                                {/* <input name="fav_language" id="yes" type="radio" /> */}
                                {/* <label style={{ marginLeft: 10, marginRight: 20 }} htmlFor="yes">Yes</label> */}
                                {/* <input name="fav_language" id="no" type="radio" /> */}
                                {/* <label style={{ marginLeft: 10, marginRight: 20 }} htmlFor="no">No</label> */}
                                {/* </div> */}
                            </div>
                        )
                    })
                }

                <button className={styles.btn} onClick={() => handleSubmit()}>{isMaxValueSelected ? "FINAL SUBMIT" : "SUBMIT PROGRESS"}</button>



            </div>
            <DashboardFooter />
        </>
    )
}
