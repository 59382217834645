import React, { useState, useEffect } from 'react'
import styles from "./CSMChildrenList.module.css";
import back from "../../../assets/img/back.png";
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";
import CSMFooter from "../CSMFooter/CSMFooter"
import { getCSMCustomersFromCsm } from '../../../Services/CSM';
export default function CSMChildrenList() {
    const history = useHistory();

    const [userArr, setUserArr] = useState([]);
    const [loading, setLoading] = useState(false);










    const getCsmUsers = async () => {
        try {
            setLoading(true)
            let res = await getCSMCustomersFromCsm()
            console.log(res.data.data)
            if (res.status == 200 || res.status == 304) {
                setLoading(false)
                let temparr = []
                res.data.data.users.forEach(el => {
                    if (el.user.length > 0) {
                        temparr.push(el.user[0])
                    }
                });
                setUserArr(temparr)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)

                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }
    }




    const handleBackNavigation = () => {
        // let path = `/Manage-CSM`;
        history.goBack();
    }





    useEffect(() => {
        getCsmUsers()
    }, [])



    return (
        <div>
            <div className={`${styles.header}`}>
                {/* <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div> */}

                <div className={`${styles.headerHeading}`}>
                    Children Profiles
                </div>
                {/* <div className={styles.headerBtn} >
                    Actions
                </div> */}
            </div>
            <div className={`${styles.pageHeadingLarge}`}>Detailed List</div>
            <div className={`${styles.containerdiv} container`}>
                {
                    loading ?
                        <>
                            <div className="row mt-5 justify-content-center d-flex">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Loader
                                        visible={true}
                                        type="TailSpin"
                                        color="#FF6F96"
                                        height={100}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </>
                        :
                        <div className="row">
                            <div className="mx-auto col-lg-10 col-11">
                                <div className="table-responsive">
                                    <table className="table table-borderless">
                                        <thead className="thead-Bg">
                                            <tr>
                                                <th scope="col">Name of Child</th>
                                                <th scope="col">Name of Parent’s</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone Number</th>
                                                <th scope="col">View Profile</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {

                                                userArr && userArr.length > 0 && userArr.map(el => {
                                                    return (
                                                        <tr key={el.id}>
                                                            <td>{el?.child_name}</td>
                                                            <td>{el?.name}</td>
                                                            <td>{el?.email}</td>
                                                            <td>{el?.mobile}</td>
                                                            <td>
                                                                <button onClick={() => history.push(`CSM-Child-Profile/${el.id}`)} className={`${styles.pinkBtn}`}>View</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>


                                </div>
                            </div>
                        </div>
                }
            </div>
            <CSMFooter />
        </div >
    )
}
