import axios from "axios";
import { Authorisation, url } from "./Url";

const serverUrl = `${url}activity`

let token = localStorage.getItem("ADMIN_AUTH_TOKEN")

const authorisation = `Bearer ${token}`

export const addActivity = async (obj) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation,
                Accept: "application/json"
            }
        }
        let res = await axios.post(`${serverUrl}`, obj, config)
        return res
    }
    catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getActivity = async () => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${serverUrl}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const deleteActivity = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.delete(`${serverUrl}/${id}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const editActivity = async (id, obj) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation,
                Accept: "application/json"
            }
        }
        let res = await axios.post(`${serverUrl}/${id}`, obj, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getActivityById = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${serverUrl}/${id}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}


export const getActivityByDay = async () => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${url}activity-calendar`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}
export const getActivityByDayActivityId = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${url}day-activity/${id}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}


export const getCompletedActivities = async () => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${url}completed-activity`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getOngoingActivities = async () => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${url}ongoing-activity`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getUpcomingActivities = async () => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${url}upcoming-activity`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}




export const getCurrentProgressForAdminByUserId = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${url}user/${id}/progress`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}


export const getCurrentProgressForCsmByUserId = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${url}csmUser/user/${id}/progress`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getCurrentProgressForUser = async () => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${url}user-progress`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}


export const getUserAges = async () => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${url}user-age`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}


