import axios from "axios";
import { Authorisation, url } from "./Url";

const serverUrl = `${url}skill`
const userServerUrl = `${url}skills`

let token = localStorage.getItem("ADMIN_AUTH_TOKEN")

const authorisation = `Bearer ${token}`

export const addSkill = async (obj) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.post(`${serverUrl}`, obj, config)
        return res
    }
    catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getSkills = async () => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${serverUrl}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const deleteSkills = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.delete(`${serverUrl}/${id}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const editSkills = async (id, obj) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.put(`${serverUrl}/${id}`, obj, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getSkillById = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${serverUrl}/${id}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}



export const getSkillForUser = async (id) => {
    try {
        let config = {
            headers: {
                Authorization: authorisation
            }
        }
        let res = await axios.get(`${userServerUrl}`, config)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}

