import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";
import styles from "./UserPayments.module.css";
import back from "../../assets/img/back.png";
import { getUserAges } from '../../Services/Activity';

export default function UserPayments() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [paymentHistory, setPaymentHistory] = useState({});
    const handleBackNavigation = () => {
        history.goBack();
    }

    const [userPackageArr, setUserPackageArr] = useState([]);



    const getUserDetails = async () => {
        try {
            setLoading(true)
            let res = await getUserAges()
            if (res.status == 200 || res.status == 201 || res.status == 304) {
                console.log(res.data)
                setPaymentHistory(res.data.user.user_package)
                setUserPackageArr(res.data.user.user_packages)
                setLoading(false)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                setLoading(false)
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                setLoading(false)
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
            }
        }
    }



    useEffect(() => {
        getUserDetails()
    }, [])




    return (
        <div>
            <div className={`${styles.header}`}>
                <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div>
                <div className={`${styles.headerHeading}`}>
                    Payment History
                </div>
            </div>
            <div className={`${styles.containerdiv} container`}>
                {
                    loading ?
                        <>
                            <div className="row mt-5 justify-content-center d-flex">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Loader
                                        visible={true}
                                        type="TailSpin"
                                        color="#FF6F96"
                                        height={100}
                                        width={100}
                                    />
                                </div>
                            </div>
                        </>
                        :

                        userPackageArr && userPackageArr.map((el, i) => {
                            return (
                                <div className="row" key={i}>
                                    <div className="mx-auto col-lg-8 col-11">
                                        <div className={styles.cardContainer}>
                                            <div className={styles.textContainer}>
                                                <div className={styles.optionsTextHeading}>
                                                    Package Name : {el?.package?.name}
                                                </div>
                                                <div className={styles.optionsText}>
                                                    Package Type : {el?.package?.package_type}
                                                </div>
                                                <div className={styles.optionsText}>
                                                    Package Duration : From {`${new Date(el?.start_date).getDate()}/${new Date(el?.start_date).getMonth() + 1}/${new Date(el?.start_date).getFullYear()}`} To  {`${new Date(el?.end_date).getDate()}/${new Date(el?.end_date).getMonth() + 1}/${new Date(el?.end_date).getFullYear()}`}
                                                </div>
                                                <div className={styles.optionsText} style={{textDecorationLine:el.package.type == "free" ? "line-through" : "none"}}>
                                                            Price : ₹ {el?.package?.price} {el.type}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                }
            </div>

        </div>
    )
}
