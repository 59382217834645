import React, { useState } from 'react'
import styles from './CSMCustomPlans.module.css';
import back from "../../../assets/img/back.png";
import { useHistory,useParams } from 'react-router-dom';
import CSMFooter from '../CSMFooter/CSMFooter';
import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";

///////// pink section
import bear from "../../../assets/img/bear.png";
import abc from "../../../assets/img/abcd.png";
import helicopter from "../../../assets/img/a2c55372587e78209084471290349e64 1.png";
import helicopterLeft from "../../../assets/img/helicopter_cloud_modal_left.png";
import cloudRight from "../../../assets/img/cloud_modal_right.png";
import chevronRightBlack from "../../../assets/img/chevronRightBlack.png";

///////// blue section
import kite from "../../../assets/img/activityCenterTopLeft.png";
import blueSectionBottomBar from "../../../assets/img/blueSectionBottomBar.png";
import KidOnly from "../../../assets/img/KidOnly.png";
import { getMilestones } from '../../../Services/Milestone';
import { getCustomPlanFromAdmin, setCustomPackageFromAdmin } from '../../../Services/Admin';

/////////////////////////////////////////// Select Custom Plan  ///////////////////////////////////////////////////////////////////////////////////////////////////////
function MyVerticallyCenteredModal({handleSubmit,milestoneArr, ...props}) {
    const [milestonesArr, setMilestonesArr] = useState(milestoneArr);
    const [selectedActivityArr, setSelectedActivityArr] = useState([]);

    const handleActivityClick = (milestoneId, activityId) => {
        let index = selectedActivityArr.findIndex(el => el.milestoneId == milestoneId && el.activityId == activityId);
        if (index == -1) {
            setSelectedActivityArr(prevState => [...prevState, { milestoneId, activityId }])
        }
        else {
            setSelectedActivityArr(prevState => [...prevState.filter((el, i) => i != index)])
        }
    }


    const checkIsSelected = (milestoneId, activityId) => {
        return selectedActivityArr.some(el => el.milestoneId == milestoneId && el.activityId == activityId)
    }
    const checkIsMilestoneSelected = (milestoneId) => {
        return selectedActivityArr.some(el => el.milestoneId == milestoneId)
    }
    const selectMilestone = (milestoneId) => {
        let milestoneObj = milestonesArr.find(el => el.id == milestoneId);
        if (milestoneObj) {
            let nonExistArr = milestoneObj.activitiesArr.filter(el => !selectedActivityArr.some(ele => ele.milestoneId == milestoneId && el.id == ele.activityId));
            console.log(nonExistArr)
            if (nonExistArr.length) {
                setSelectedActivityArr(prevState => [...prevState, ...nonExistArr.map(el => ({ milestoneId, activityId: el.id }))])
            }
            else {
                setSelectedActivityArr(prevState => [...prevState.filter((el) => el.milestoneId != milestoneId)])

            }
        }
        // setSelectedActivityArr()
    }


    const handleMilestonesubmit = () => {
        let arr = []
        arr = milestonesArr.reduce((acc, el) => {
            let tempArr = el.activities.filter(elx => selectedActivityArr.some(ele => ele.activityId == elx.id))
            return [...acc, ...tempArr]


        }, [])

        handleSubmit(arr)

        props.onHide()
    }

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className={styles.Modal} style={{ height: 660 }}>
                <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                <div className={styles.ModalInnerBox}>
                    <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                    <div className={styles.modalHeading}>
                        Gross Motor Skill
                    </div>
                    <div className={styles.modalSubHeading}>
                        Select Range
                    </div>
                    <div className={styles.milestonesArrContainer}>
                        {
                            milestonesArr.map((el, index) => {
                                return (
                                    <div key={el.id}>
                                        <div className={`${checkIsMilestoneSelected(el.id) ? styles.selectedBg : ""}`}>
                                            <div className={`${styles.milestoneHeadingContainer}`} onClick={() => selectMilestone(el.id)}>
                                                <div className={`${styles.milestoneHeading} `}>

                                                    {index + 1}. {el.name}
                                                </div>
                                                <img className={styles.chevron} src={chevronRightBlack} alt="" />
                                            </div>
                                        </div>
                                        {
                                            el.activitiesArr.map((ele, ind) => {
                                                return (
                                                    <div className={`${checkIsSelected(el.id, ele.id) ? styles.selectedBg : ""}`}>
                                                        <div onClick={() => handleActivityClick(el.id, ele.id)} className={`${styles.activitiesHeadingContainer} `}>{ind + 1}. {ele.name}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>

                    <button onClick={() => handleMilestonesubmit()} style={{ marginTop: 10, marginBottom: 30, borderRadius: 4 }} className={`${styles.pinkBtn} blueBtnRegister`}>
                        Save
                    </button>
                </div>
            </Modal.Body>

        </Modal>
    );
}
export default function CSMCustomPlans() {
    const [loading, setLoading] = useState(false);

    const [modalShow, setModalShow] = useState(false);
    const [selectedCustomPlanArr, setSelectedCustomPlanArr] = useState();
    const [allMilestoneArr, setAllMilestoneArr] = useState([]);
    const history = useHistory();
    let id = useParams()
    const handleBackNavigation = () => {
        // let path = `/CSM-Child-Profile`;
        history.goBack();
    }

    const getcustomPlans = async () => {
        try {
            setLoading(true)
            let res = await getCustomPlanFromAdmin(id.id)
            if (res.status == 200 || res.status == 304) {
                setLoading(false)
                console.log(res.data.data)
                setSelectedCustomPlanArr(res.data.data)
                getMilestone()
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)

                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }
    }


    const getMilestone = async () => {
        try {
            setLoading(true)
            let res = await getMilestones()
            if (res.status == 200 || res.status == 304 || res.status == 202) {

                res.data.forEach(el => {
                    el.selected = false
                    el.activities.forEach(ele => {
                        if (selectedCustomPlanArr.some(elx => elx == ele.id)) {
                            ele.selected = true
                        }
                        else {
                            ele.selected = false
                        }
                    })
                });
                let tempArr = res.data
                setAllMilestoneArr(tempArr);
                setLoading(false)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }

    }
    const handleCustomPlanSubmit = async (selectedArr) => {
        try {
            setLoading(true)
            let arr = selectedArr.map(el => el.id)
            console.log(arr)
            let obj = {
                user_id: id.id,
                activities: arr
            }
            let res = await setCustomPackageFromAdmin(obj)
            if (res.status == 200 || res.status == 304 || res.status == 202) {
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.message}`,
                });
                setLoading(false)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }

    }


    return (
        <div>
            <div className={`${styles.header}`}>
                <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div>
                <div className={`${styles.headerHeading}`}>
                    Current Progress
                </div>
            </div>
            <div className={`${styles.containerdiv} container-fluid ${styles.blueContainer}`}>
                <img src={kite} className={`${styles.blueContainerImage1}`} alt="" />
                <img src={blueSectionBottomBar} className={`${styles.blueContainerImage2}`} alt="" />
                <img src={KidOnly} className={`${styles.blueContainerImage3}`} alt="" />



                <div className={`row pt-5`} >
                    <div className="mx-auto col-lg-8 col-11">
                        <div className={`${styles.blueSectionContaienerCard}`}>
                            <div className={`${styles.cardHeading}`}>
                                Gross Motor skill
                            </div>
                            {/* ////////progress-Bar section//////// */}
                            <div className={`${styles.blueSectionProgressBarBorder}`}><div className={`${styles.blueSectionProgressBarContainer}`}><div className={`${styles.blueSectionProgressBarInnerContainer}`}></div></div></div>
                            {/* ////////progress-Bar section//////// */}

                            <div className={`${styles.cardHeading}`}>
                                Current Plan Details
                            </div>
                            <div onClick={() => setModalShow(true)} className={`${styles.blueSectionSelectInput}`}>
                                Cognitive SKU_12_20

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.containerdiv} container-fluid ${styles.pinkContainer}`}>
                <img src={bear} className={`${styles.pinkContainerImage1}`} alt="" />
                <img src={helicopter} className={`${styles.pinkContainerImage2}`} alt="" />
                <img src={abc} className={`${styles.pinkContainerImage3}`} alt="" />

                <div className={`row pt-5`} >
                    <div className="mx-auto pt-4 col-lg-8 col-11">
                        <div className={`${styles.pinkSectionContaienerCard}`}>
                            <div className={`${styles.cardHeading}`}>
                                Gross Motor skill
                            </div>
                            {/* ////////progress-Bar section//////// */}
                            <div className={`${styles.pinkSectionProgressBarBorder}`}><div className={`${styles.pinkSectionProgressBarContainer}`}><div className={`${styles.pinkSectionProgressBarInnerContainer}`}></div></div></div>
                            {/* ////////progress-Bar section//////// */}

                            <div className={`${styles.cardHeading}`}>
                                Current Plan Details
                            </div>

                            <div onClick={() => setModalShow(true)} className={`${styles.pinkSectionSelectInput}`}>
                                Cognitive SKU_12_20

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <CSMFooter />
            <MyVerticallyCenteredModal
                milestoneArr={allMilestoneArr}
                show={modalShow}
                handleSubmit={handleCustomPlanSubmit}
                onHide={() => setModalShow(false)}
            />
        </div>
    )
}
