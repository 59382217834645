import axios from "axios";
import { url } from "./Url";




export const getAgeData = async () => {
    try {
        let res = await axios.get(`${url}site/age_group`)
        return res
    }
    catch (error) {
        console.error(error)
        throw (error)
    }
}

export const getTests = async (obj) => {
    try {
        let res = await axios.post(`${url}site/free_test`, obj)
        return res

    } catch (error) {
        console.error(error)
        throw (error)
    }
}
