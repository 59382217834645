import React, { useEffect, useState } from 'react'
import Adminhomepagefooter from '../AdminHomepageFooter/Adminhomepagefooter';
import back from "../../../assets/img/back.png";
import { useHistory } from 'react-router-dom';
import { getCSMCustomers, getCSMCustomersFromCsmDetails, getCSMDetails } from '../../../Services/CSM';
import { useParams } from 'react-router';
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";
import styles from './AdminCsmCustomerList.module.css';
import { getCsmUserfromAdmin, getUserDetailfromAdmin, getUserfromAdmin } from '../../../Services/User';
import { getAllCustomers } from '../../../Services/Admin';
export default function AdminCsmCustomerList() {
    const history = useHistory();
    const [userArr, setUserArr] = useState([]);
    const [loading, setLoading] = useState(false);
    const [csmObj, setCsmObj] = useState({});

    const handleBackNavigation = () => {
        history.goBack();
    }

    let id = useParams()
    // console.log(id.id)

    const getCustomers = async () => {
        try {
            setLoading(true)
            let res = await getAllCustomers()
            console.log(res.data,"asda")
            if (res.data) {

                setLoading(false)
                let temparr = []
                // res.data.data.users.forEach(el => {
                //     if (el.user.length > 0) {
                //         temparr.push(el.user[0])
                //     }
                // });
                // console.log(temparr)
                setUserArr(res.data.data)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }
    }


    const getCsmDetails = async () => {
        try {
            setLoading(true)
            let res = await getCSMDetails(id.id)
            if (res.data) {
                setLoading(false)
                // console.log(res.data.data, "CSM")
                setCsmObj(res.data.data)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)

            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)


            }
        }
    }


    useEffect(() => {
        getCustomers()
        getCsmDetails()
    }, [])

    return (
        <div>
            <div className={`${styles.header}`}>
                <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div>

                <div className={`${styles.headerHeading}`}>
                    Profile
                </div>
                <div className={styles.headerBtn}>
                </div>
            </div>
            <div className={`${styles.pageHeading}`}>Child Profile</div>
            <div className={`${styles.containerdiv} container`}>
                <div className="row">
                    <div className="mx-auto col-lg-10 col-11">
                        {
                            loading ?
                                <>
                                    <div className="row mt-5 justify-content-center d-flex">
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Loader
                                                visible={true}
                                                type="TailSpin"
                                                color="#FF6F96"
                                                height={100}
                                                width={100}
                                            />
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="table-responsive">
                                    <table className="table table-borderless">
                                        <thead className="thead-Bg">
                                            <tr>
                                                <th scope="col">View Details</th>
                                                <th scope="col">Name of Child</th>
                                                <th scope="col">Name of Parent’s</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Phone Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>



                                            {
                                                userArr && userArr.length > 0 ? userArr.map(el => {
                                                    return (
                                                        <tr key={el?.id}>
                                                            <td>
                                                                <button onClick={() => history.push(`/Admin-Child-Profile/${el.id}`)} className={`${styles.pinkBtn}`}>View</button>
                                                            </td>
                                                            <td>{el?.child_name}</td>
                                                            <td>{el?.name}</td>
                                                            <td>{el?.email}</td>
                                                            <td>{el?.mobile}</td>
                                                        </tr>

                                                    )
                                                })
                                                    :
                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div className={styles.milestonesCardItem}>
                                                                <div className={styles.milestonesCardItemTxt}>No Users found </div>

                                                            </div>
                                                        </td>
                                                    </tr>
                                            }



                                        </tbody>
                                    </table>


                                </div>
                        }
                    </div>
                </div>
            </div>
            <Adminhomepagefooter />
        </div>
    )
}
