import React, { useState, useRef, useEffect, useContext } from 'react'
import styles from './AdminHomepage.module.css'


import Loader from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import Swal from 'sweetalert2';

///////images
import chevronDownwithoutBg from "../../../assets/img/chevronDownwithoutBg.png";
import rightChevron from "../../../assets/img/right-chevron.png";
import trashBin from "../../../assets/img/trashBin.png";
import helicopterLeft from "../../../assets/img/helicopter_cloud_modal_left.png";
import cloudRight from "../../../assets/img/cloud_modal_right.png";

import { useHistory } from 'react-router-dom';


import Adminhomepagefooter from "../AdminHomepageFooter/Adminhomepagefooter"
import { addSkill, deleteSkills, getSkills } from '../../../Services/Skill';

import { jwtContext, AuthContext } from '../../../App'
/////////////////////////////////////////// Add Skill ///////////////////////////////////////////////////////////////////////////////////////////////////////

function MyVerticallyCenteredModal({ handleadd, ...props }) {
    const colorRef = useRef();
    const [skillName, setSkillName] = useState("");
    const [serialCode, setSerialCode] = useState("");
    const [selectedColor, setSelectedColor] = useState("");
    const [selectedColorValue, setSelectedColorValue] = useState("");
    const [selectColorIsActive, setSelectColorIsActive] = useState(false);
    const [borderColorValue, setBorderColorValue] = useState("");
    const [customColorSelected, setCustomColorSelected] = useState("");
    const [order, setOrder] = useState("asc");
    const handleSkillAddModal = () => {
        if (serialCode === "")
            alert("Please Enter Serial Code")
        if (skillName === "")
            alert("Please Enter Skill Name")

        if (selectedColor === "")
            alert("Please Enter Skill Color")

        else {
            let obj = {
                s_no: serialCode,
                skillName: skillName,
                colorValue: selectedColorValue,
                color: selectedColor,
                border: borderColorValue,
                order:order,
            }
            handleadd(obj)
            setSkillName("")
            setSerialCode("")
            setSelectedColorValue("")
            setSelectedColor("")
        }
    }


    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className={styles.RegisterModal} style={{ height: selectColorIsActive ? customColorSelected ? 550 : 500 : 450 }}>
                <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                <div className="RegisterModalInnerBox">
                    <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                    {
                        selectColorIsActive === false
                            ?
                            <>
                                <div className={styles.modalHeading}>
                                    Add Skill
                                </div>
                                <input type="text" value={serialCode} onChange={(e) => setSerialCode(e.target.value)} placeholder="Serial Code" className="registerModalTextInput" />
                                <input type="text" value={skillName} onChange={(e) => setSkillName(e.target.value)} placeholder="Name of Skill" className="registerModalTextInput" />
                                <select type="text" onChange={(e) => setOrder(e.target.value)} value={order} style={{ height: 50, color: "#FF6F96" }} className="registerModalTextInput" >
                                    {/* <option value=""></option> */}
                                    <option value="asc">Ascending Order</option>
                                    <option value="desc">Descending Order</option>
                                </select>

                                <div className="registerModalTextInput" onClick={() => { setSelectColorIsActive(true); }}>
                                    {
                                        selectedColor === ""
                                            ?
                                            <>
                                                <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Select Color</div>
                                                <img alt="chevronDown" style={{ height: 10, width: 10 }} src={chevronDownwithoutBg} />
                                            </>
                                            :
                                            <>
                                                <div style={{ border: "none", outline: "none", color: "#FF6F96" }}> Color will be {selectedColor}</div>
                                                <div style={{ backgroundColor: selectedColorValue, height: 30, width: 30, borderRadius: 5 }}></div>
                                            </>
                                    }
                                </div>

                                <button style={{ marginTop: 20, marginBottom: 30, borderRadius: 4 }} onClick={() => { handleSkillAddModal(); props.onHide() }} className="blueBtnRegister">
                                    Add
                                </button>
                            </>
                            :
                            <>
                                <div className={styles.modalHeading}>
                                    Select a color
                                </div>

                                <div className={selectedColor === "Green" ? styles.ModalSelectedItem : styles.ModalUnselectedItem} onClick={() => { setSelectedColor("Green"); setSelectedColorValue("rgba(177,212,78,0.28)"); setBorderColorValue("#83B102"); setCustomColorSelected(false); }}>
                                    <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Green</div>
                                    <div style={{ backgroundColor: 'rgba(177,212,78,0.28)', height: 30, width: 30, borderRadius: 5 }}></div>
                                </div>

                                <div className={selectedColor === "Blue" ? styles.ModalSelectedItem : styles.ModalUnselectedItem} onClick={() => { setSelectedColor("Blue"); setSelectedColorValue("rgba(229,242,252,1)"); setBorderColorValue("#98CCF4"); setCustomColorSelected(false); }}>
                                    <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Blue</div>
                                    <div style={{ backgroundColor: 'rgba(229,242,252,1)', height: 30, width: 30, borderRadius: 5 }}></div>
                                </div>

                                <div className={selectedColor === "Pink" ? styles.ModalSelectedItem : styles.ModalUnselectedItem} onClick={() => { setSelectedColor("Pink"); setSelectedColorValue("rgba(255,111,150,0.28)"); setBorderColorValue("#FF6F96"); setCustomColorSelected(false); }}>
                                    <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Pink</div>
                                    <div style={{ backgroundColor: 'rgba(255,111,150,0.28)', height: 30, width: 30, borderRadius: 5 }}></div>
                                </div>


                                <div className={selectedColor === "Custom" ? styles.ModalSelectedItem : styles.ModalUnselectedItem} onClick={() => { setCustomColorSelected(true) }}>
                                    {
                                        customColorSelected ?
                                            <>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <div>
                                                        <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Select background color</div>
                                                        <input type="color" ref={colorRef} onChange={(val) => { setSelectedColorValue(val.target.value); setSelectedColor("Custom"); }} style={{ height: 30, width: "100%", borderRadius: 5, outline: "none", border: "none" }} />
                                                    </div>
                                                    <div>
                                                        <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Select border color</div>
                                                        <input type="color" ref={colorRef} onChange={(val) => { setBorderColorValue(val.target.value); setSelectedColor("Custom"); }} style={{ height: 30, width: "100%", borderRadius: 5, outline: "none", border: "none" }} />
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                                <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Custom</div>
                                                <input type="color" ref={colorRef} onChange={(val) => { setSelectedColorValue(val.target.value); setSelectedColor("Custom"); }} style={{ height: 30, width: 30, borderRadius: 5, outline: "none", border: "none" }} />
                                            </>
                                    }
                                </div>

                                <button onClick={() => setSelectColorIsActive(false)} style={{ marginTop: 10, marginBottom: 30, borderRadius: 4 }} className="blueBtnRegister">
                                    Select
                                </button>
                            </>
                    }
                </div>
            </Modal.Body>

        </Modal>
    );
}





///////////////////////////////////////////////// delete skill /////////////////////////////////////////////////////////////////////////////////////////////////////////
function MyVerticallyCenteredModal3({ handledelete, skillname, ...props }) {
    const [skillName, setSkillName] = useState("");
    // console.log(props)
    const handleSkillDeleteModal = () => {
        if (skillName === "")
            alert("Please Enter Skill Name")
        else {
            let obj = {
                skillName: skillName,
            }
            handledelete(obj)
            props.onHide()
        }
    }
    useEffect(() => {
        // console.log("skill name", skillName)
        if (skillname)
            setSkillName(skillname)
    }, [skillname])
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className={styles.RegisterModal} style={{ height: 470 }}>
                <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                <div className="RegisterModalInnerBox">
                    <>
                        <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                        <div className={styles.modalHeading}>
                            Manage Skill
                        </div>
                        <input type="text" disabled
                            value={skillName} onChange={(e) => setSkillName(e.target.value)} placeholder="Name of Skill" className="registerModalTextInput" />
                        <div className={`${styles.modalHeading}`} style={{ fontSize: 18, textAlign: "center" }}>
                            Delete Skill
                        </div>
                        <img src={trashBin} className={styles.trashIcon} alt="" />
                        <div className={styles.lightTxt}>
                            Are you sure you want to delete
                            this skill?  This will remove all the
                            stored data as well.

                        </div>
                        <div className={styles.flexRow} style={{ justifyContent: "space-between" }}>
                            <button onClick={() => handleSkillDeleteModal()} style={{ marginTop: 10, marginBottom: 0, borderRadius: 4, width: "49%" }} className={styles.blueBtn}>
                                Yes
                            </button>
                            <button onClick={() => props.onHide()} style={{ marginTop: 10, marginBottom: 0, borderRadius: 4, width: "49%" }} className={styles.pinkBtn}>
                                No
                            </button>
                        </div>
                    </>
                </div>
            </Modal.Body>

        </Modal >
    );
}





export default function AdminHomepage() {
    const [skillsArr, setSkillsArr] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [skillIdforDeletion, setSkillIdforDeletion] = useState("");
    const [skillNameforDeletion, setSkillNameforDeletion] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
    const [decodedJwt, setDecodedJwt] = useContext(jwtContext);

    const handleSkillAdd = async (obj) => {
        try {
            setLoading(true)
            let dataObject = {
                s_no: obj.s_no,
                name: obj.skillName,
                color: obj.colorValue,
                border_color: obj.border,
                order:obj.order,
                status: 1,
            }
            let res = await addSkill(dataObject)
            if (res.status == 200 || res.status == 304) {
                // console.log(res.data);
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.message}`,
                });
                getSkillArr()
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)
            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)
            }
        }

    }



    const getSkillArr = async () => {
        try {
            setLoading(true)
            let res = await getSkills()
            if (res.status == 200 || res.status == 304) {
                setSkillsArr(res.data)
                setLoading(false)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)
            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)
            }
        }

    }


    const handleSkillDelete = async () => {
        try {
            // console.log(skillIdforDeletion)
            let res = await deleteSkills(skillIdforDeletion)
            if (res.status === 200 || res.status === 304 || res.status === 202) {
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.message}`,
                });
                getSkillArr()
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)
            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)
            }
        }

    }

    useEffect(() => {
        // console.log("skillNameforDeletion", skillNameforDeletion)
    }, [skillNameforDeletion])

    const handleRedirect = (id) => {
        let path = `/Admin-Milestones/${id}`;
        history.push(path);
    }

    const handleLogout = () => {
        localStorage.removeItem('FAVCY_AUTH_TOKEN')
        localStorage.removeItem('ADMIN_AUTH_TOKEN')
        localStorage.removeItem('role');
        setIsAuthorized(false)
        setDecodedJwt({ role: '' })
        history.push('/')
    }




    useEffect(() => {
        getSkillArr()
    }, [])


    return (
        <div>
            <div className={`${styles.header}`}>
                <div className={`${styles.headerHeading}`}>
                    Welcome to CompassTot
                </div>
                <div className={`${styles.headerLogout}`} onClick={() => handleLogout()} >
                    logout
                </div>
            </div>
            <div className={`${styles.containerdiv} container`}>


                {
                    loading == false
                        ?
                        <div className="row d-flex justify-content-center">
                            {
                                skillsArr.length > 0
                                    ? skillsArr.map((el, index) => {
                                        return (
                                            <div key={index} className={`${styles.skillsCard} col-lg-7 col-11`} style={{ backgroundColor: el.color, border: `solid 1px ${el.border_color}`, borderWidth: 2, }}
                                            >
                                                {


                                                    <div className="row">
                                                        <div className={styles.skillCardHeadingContainer} style={{ borderBottom: `solid 1px ${el.border_color}` }}>
                                                            <div className={styles.skillCardHeading} >{el?.name}</div>

                                                        </div>
                                                        <div onClick={() => handleRedirect(el.id)} className={styles.skillCardItemContainer} style={{ borderBottom: `solid 1px ${el.border_color}`, }}>
                                                            <div className={styles.skillCardItemName}>
                                                                Content Library
                                                            </div>
                                                            <img src={rightChevron} style={{ height: 15, width: 15 }} alt="" />
                                                        </div>
                                                        <div className={styles.skillCardItemContainer} onClick={() => { setDeleteModalShow(true); setSkillIdforDeletion(el.id); setSkillNameforDeletion(el.name) }}>
                                                            <div className={styles.skillCardItemName}>
                                                                Manage Skill
                                                            </div>
                                                            <img src={rightChevron} style={{ height: 15, width: 15 }} alt="" />
                                                        </div>
                                                    </div>

                                                }
                                            </div>
                                        )
                                    }
                                    )
                                    :
                                    <>
                                        <div className={styles.skillCardHeadingContainer} style={{ borderBottom: `solid 1px rgba(229,242,252,1)` }}>
                                            <div className={styles.skillCardHeading} >No Skills found at the moments</div>

                                        </div>
                                    </>
                            }
                            <div className="col-12 d-flex justify-content-center mt-5">

                                <button onClick={(e) => { setModalShow(true); e.stopPropagation(); }} className={styles.addSkillsBtn}>
                                    Add Skill
                                </button>
                            </div>
                        </div>
                        :
                        <div className="row mt-5 justify-content-center d-flex">
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Loader
                                    visible={true}
                                    type="TailSpin"
                                    color="#FF6F96"
                                    height={100}
                                    width={100}
                                // timeout={18000} //3 secs
                                />
                            </div>
                        </div>
                }



            </div>



            <MyVerticallyCenteredModal
                handleadd={handleSkillAdd}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />


            <MyVerticallyCenteredModal3
                handledelete={handleSkillDelete}
                show={deleteModalShow}
                skillname={skillNameforDeletion}
                onHide={() => setDeleteModalShow(false)}
            />





            <Adminhomepagefooter />
        </div>
    )
}
