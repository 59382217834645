import React, { useState } from 'react'
import styles from './AdminMilestone.module.css'
import Modal from "react-bootstrap/Modal";

import Adminhomepagefooter from "../AdminHomepageFooter/Adminhomepagefooter"
import edit from "../../../assets/img/edit.png";
// import editIcon from "../../../assets/img/editIcon.png";
import deleteIcon from "../../../assets/img/deleteIcon.png";
import back from "../../../assets/img/back.png";
import chevronRightBlack from "../../../assets/img/chevronRightBlack.png";
import { useHistory } from 'react-router-dom';
import DeleteActivity from "../../../assets/img/DeleteActivity.png";
import helicopterLeft from "../../../assets/img/helicopter_cloud_modal_left.png";
import cloudRight from "../../../assets/img/cloud_modal_right.png";

import chevronDownwithoutBg from "../../../assets/img/chevronDownwithoutBg.png";
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import Loader from "react-loader-spinner";
import { editSkills, getSkillById, getSkills } from '../../../Services/Skill';
import { useParams } from 'react-router';
import { addMilestone, deleteMilestones, editMilestones, getMilestones } from '../../../Services/Milestone';
import { getAgeData } from '../../../Services/Test';

/////////////////////////////////////////// Add Milestone ///////////////////////////////////////////////////////////////////////////////////////////////////////

function MyVerticallyCenteredModal({ skillObj, handleadd, ...props }) {

    const [milestoneId, setMilestoneId] = useState(1);
    const [milestoneName, setMilestoneName] = useState("");
    const [milestoneDescription, setMilestoneDescription] = useState("");
    const [otherInputs, setOtherInputs] = useState("");
    const [ageArr, setAgeArr] = useState([]);
    const [ageGroup, setAgeGroup] = useState("");
    const handleMilestoneAdd = () => {
        if (milestoneName == "") {
            Swal.fire({
                title: 'Error',
                type: 'error',
                text: `Please Enter MileStone Name`,
            });
        }

        else if (milestoneDescription == '') {
            Swal.fire({
                title: 'Error',
                type: 'error',
                text: `Please Enter MileStone Description`,
            });
        }
        else if (ageGroup == '') {
            Swal.fire({
                title: 'Error',
                type: 'error',
                text: `Please Select Age Group`,
            });
        }
        else {
            let obj = {
                s_no: milestoneId,
                name: milestoneName,
                description: milestoneDescription,
                // description2: "",
                age_group_id: ageGroup,
                other: otherInputs,
                skill_id: skillObj.id,
                status: 1,
            }



            console.log(obj, "milestone obj")




            handleadd(obj)
            props.onHide()
            setMilestoneName("");
            setMilestoneDescription("");
            setOtherInputs("");
            setAgeGroup("");
        }

    }

    const getAge = async () => {
        try {
            let res = await getAgeData()
            if (res.status === 200 || res.status === 304) {
                console.log(res.data, "age group")
                setAgeArr([...res.data])
            }
        }
        catch (err) {
            console.error(err)
            alert(err)
        }
    }

    useEffect(() => {
        getAge()
    }, [])



    useEffect(() => {

        if (skillObj?.milestones && skillObj?.milestones.length > 0) {
            let serialNo = skillObj?.milestones[skillObj?.milestones.length - 1].s_no;
            if (serialNo) {
                let tempSerialNo = parseInt(serialNo.slice(1))
                let txt = `M${tempSerialNo + 1}`
                setMilestoneId(txt)
            }
        }
        else {
            setMilestoneId("M1")
        }
    }, [skillObj])



    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className={styles.Modal} style={{ height: 620 }}>
                <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                <div className={styles.ModalInnerBox}>
                    <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                    <>
                        <div className={styles.modalHeading}>
                            Add Milestone
                        </div>
                        <input type="text" disabled value={milestoneId} onChange={(e) => setMilestoneId(e.target.value)} placeholder="Serial Number (Automated)" className={styles.modalTextInput} />
                        <input type="text" value={milestoneName} onChange={(e) => setMilestoneName(e.target.value)} placeholder="Milestone Name " className={styles.modalTextInput} />
                        <select onChange={(e) => { console.log(e.target.value); setAgeGroup(e.target.value) }} className={styles.modalTextInput}>
                            <option value="">Select Age Group</option>
                            {
                                ageArr.map(el => {
                                    return (
                                        <option key={el.age} value={el.id}>{parseInt(el.age_group)}</option>
                                    )
                                })
                            }
                        </select>
                        <textarea type="text" value={milestoneDescription} multiple={true} onChange={(e) => setMilestoneDescription(e.target.value)} placeholder="Milestone Description" className={styles.modalTextInput} style={{ minHeight: 80 }} />
                        <textarea type="text" value={otherInputs} onChange={(e) => setOtherInputs(e.target.value)} placeholder="Other Input Options for the 
Milestone Format" className={`${styles.modalTextInput}`} style={{ minHeight: 80, marginTop: 4 }} />
                    </>
                    <button onClick={() => handleMilestoneAdd()} style={{ marginTop: 10, marginBottom: 30, borderRadius: 4 }} className="blueBtnRegister">
                        Add
                    </button>
                </div>
            </Modal.Body>

        </Modal>
    );
}

/////////////////////////////////////////// Edit Milestone ///////////////////////////////////////////////////////////////////////////////////////////////////////

function MyVerticallyCenteredModal3(props) {

    const [skillName, setSkillName] = useState("");

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className={styles.Modal} style={{ height: 520 }}>
                <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                <div className={styles.ModalInnerBox}>
                    <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                    <>

                        <div className={styles.modalHeading}>
                            Edit Milestone
                        </div>

                        <input type="text" value={skillName} onChange={(e) => setSkillName(e.target.value)} placeholder="Serial Number (Automated)" className={styles.modalTextInput} />
                        <input type="text" value={skillName} onChange={(e) => setSkillName(e.target.value)} placeholder="Running Leaning Forward" className={styles.modalTextInput} />
                        <textarea type="text" value={skillName} multiple={true} onChange={(e) => setSkillName(e.target.value)} placeholder="Run while you lean forward, with
a definitive arms swing" className={styles.modalTextInput} style={{ height: 80 }} />
                        <textarea type="text" value={skillName} onChange={(e) => setSkillName(e.target.value)} placeholder="Other Input Options for the 
Milestone Format" className={styles.modalTextInput} />




                    </>

                    <button onClick={() => props.onHide()} style={{ marginTop: 10, marginBottom: 30, borderRadius: 4 }} className="blueBtnRegister">
                        Update
                    </button>

                </div>
            </Modal.Body>

        </Modal>
    );
}
/////////////////////////////////////////// Delete Milestone ///////////////////////////////////////////////////////////////////////////////////////////////////////

function MyVerticallyCenteredModal4({ handledelete, ...props }) {
    const handleMilestoneDelete = () => {
        handledelete()
        props.onHide()
    }
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className={styles.Modal} style={{ height: 410 }}>
                <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                <div className={`${styles.ModalInnerBox} pt-4`}>
                    <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                    <>
                        <img src={DeleteActivity} className={styles.deleteModalIcon} alt="delete-activity" />

                        <div className={styles.modalHeading} style={{ textAlign: "center" }}>
                            Delete Level
                        </div>



                        <div className={styles.ModalTxt}>
                            Are you sure you want to delete
                            this activity? The next activity will
                            take its place in the list.
                        </div>

                        <div className={`${styles.flexRowBetween} mt-4`}>


                            <button onClick={() => handleMilestoneDelete()} style={{ width: "45%", marginTop: 10, marginRight: "2%", marginBottom: 30, borderRadius: 4 }} className="blueBtnRegister">
                                Yes
                            </button>
                            <button onClick={() => props.onHide()} style={{ width: "45%", marginTop: 10, marginBottom: 30, borderRadius: 4, backgroundColor: "#FF6F96" }} className="blueBtnRegister">
                                No
                            </button>
                        </div>
                    </>
                </div>
            </Modal.Body>

        </Modal>
    );
}

/////////////////////////////////////////// Edit skills ///////////////////////////////////////////////////////////////////////////////////////////////////////

function MyVerticallyCenteredModal2({ handleEdit, skillObj, ...props }) {
    const [skillName, setSkillName] = useState('');
    const [serialCode, setSerialCode] = useState("");
    const [selectedColor, setSelectedColor] = useState("");
    const [selectedColorValue, setSelectedColorValue] = useState("");
    const [selectColorIsActive, setSelectColorIsActive] = useState(false);
    const [borderColorValue, setBorderColorValue] = useState("");
    const [customColorSelected, setCustomColorSelected] = useState("");
    const [order, setOrder] = useState("");

    const handleSkillAddModal = () => {
        if (serialCode === "")
            alert("Please Enter Serial Code")
        if (skillName === "") {
            alert("Please Enter Skill Name")
        }
        if (selectedColor === "") {
            alert("Please Enter Skill Color")
        }
        else {
            let obj = {
                s_no: serialCode,
                skillName: skillName,
                colorValue: selectedColorValue,
                color: selectedColor,
                border: borderColorValue,
                order: order,
            }
            handleEdit(obj)
            setSkillName("")
            setSelectedColorValue("")
            setSelectedColor("")
        }
    }

    useEffect(() => {
        console.log(skillObj, "skill Obj")
        if (skillObj?.name) {
            setSkillName(skillObj?.name)
            if (skillObj?.color == "rgba(177,212,78,0.28)") {
                setSelectedColor("Green")
            }
            else if (skillObj?.color == "rgba(229,242,252,1)") {

                setSelectedColor("Blue")
            }
            else if (skillObj?.color == "rgba(255,111,150,0.28)") {
                setSelectedColor("Pink");
            }
            else {
                setSelectedColor("Custom")
            }
            setSelectedColorValue(skillObj?.color)
            setBorderColorValue(skillObj?.border_color);
            setSerialCode(skillObj?.s_no);
            setOrder(skillObj?.skill_order); console.log(skillObj.order)
        }
    }, [skillObj])


    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className={styles.Modal} style={{ height: selectColorIsActive ? customColorSelected ? 550 : 500 : 450 }}>
                <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
                <div className={styles.ModalInnerBox} style={{ height: "80%" }}>
                    <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />
                    {
                        selectColorIsActive === false
                            ?
                            <>
                                <div className={styles.modalHeading}>
                                    Edit Skill
                                </div>
                                <input type="text" value={serialCode} onChange={(e) => setSerialCode(e.target.value)} placeholder="Serial Code" className="registerModalTextInput" />
                                <input type="text" value={skillName} onChange={(e) => setSkillName(e.target.value)} placeholder="Name of Skill" className="registerModalTextInput" />
                                <select type="text" onChange={(e) => setOrder(e.target.value)} value={order} style={{ height: 50, color: "#FF6F96" }} className="registerModalTextInput" >
                                    {/* <option value=""></option> */}
                                    <option value="asc">Ascending Order</option>
                                    <option value="desc">Descending Order</option>
                                </select>
                                <div className="registerModalTextInput" onClick={() => { setSelectColorIsActive(true); }}>
                                    {
                                        selectedColor === ""
                                            ?
                                            <>
                                                <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Select Color</div>
                                                <img alt="Chevron-down" style={{ height: 10, width: 10 }} src={chevronDownwithoutBg} />
                                            </>
                                            :
                                            <>
                                                <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Selected Color is {selectedColor}</div>
                                                <div style={{ backgroundColor: selectedColorValue, height: 30, width: 30, borderRadius: 5 }}></div>
                                            </>
                                    }
                                </div>

                                <button style={{ marginTop: 20, marginBottom: 30, borderRadius: 4 }} onClick={() => { handleSkillAddModal(); props.onHide() }} className="blueBtnRegister">
                                    Update
                                </button>
                            </>
                            :
                            <>
                                <div className={styles.modalHeading}>
                                    Select a color
                                </div>

                                <div className={selectedColor === "Green" ? styles.ModalSelectedItem : styles.ModalUnselectedItem} onClick={() => { setSelectedColor("Green"); setSelectedColorValue("rgba(177,212,78,0.28)"); setBorderColorValue("#83B102"); setCustomColorSelected(false); }}>
                                    <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Green</div>
                                    <div style={{ backgroundColor: 'rgba(177,212,78,0.28)', height: 30, width: 30, borderRadius: 5 }}></div>
                                </div>

                                <div className={selectedColor === "Blue" ? styles.ModalSelectedItem : styles.ModalUnselectedItem} onClick={() => { setSelectedColor("Blue"); setSelectedColorValue("rgba(229,242,252,1)"); setBorderColorValue("#98CCF4"); setCustomColorSelected(false); }}>
                                    <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Blue</div>
                                    <div style={{ backgroundColor: 'rgba(229,242,252,1)', height: 30, width: 30, borderRadius: 5 }}></div>
                                </div>

                                <div className={selectedColor === "Pink" ? styles.ModalSelectedItem : styles.ModalUnselectedItem} onClick={() => { setSelectedColor("Pink"); setSelectedColorValue("rgba(255,111,150,0.28)"); setBorderColorValue("#FF6F96"); setCustomColorSelected(false); }}>
                                    <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Pink</div>
                                    <div style={{ backgroundColor: 'rgba(255,111,150,0.28)', height: 30, width: 30, borderRadius: 5 }}></div>
                                </div>


                                <div className={selectedColor === "Custom" ? styles.ModalSelectedItem : styles.ModalUnselectedItem} onClick={() => { setCustomColorSelected(true) }}>
                                    {
                                        customColorSelected ?
                                            <>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <div>
                                                        <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Select background color</div>
                                                        <input type="color" onChange={(val) => { setSelectedColorValue(val.target.value); setSelectedColor("Custom"); }} style={{ height: 30, width: "100%", borderRadius: 5, outline: "none", border: "none" }} />
                                                    </div>
                                                    <div>
                                                        <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Select border color</div>
                                                        <input type="color" onChange={(val) => { setBorderColorValue(val.target.value); setSelectedColor("Custom"); }} style={{ height: 30, width: "100%", borderRadius: 5, outline: "none", border: "none" }} />
                                                    </div>

                                                </div>
                                            </>
                                            :
                                            <>
                                                <div style={{ border: "none", outline: "none", color: "#FF6F96" }}>Custom</div>
                                                <input type="color" onChange={(val) => { setSelectedColorValue(val.target.value); setSelectedColor("Custom"); }} style={{ height: 30, width: 30, borderRadius: 5, outline: "none", border: "none" }} />
                                            </>
                                    }
                                </div>

                                <button onClick={() => setSelectColorIsActive(false)} style={{ marginTop: 10, marginBottom: 30, borderRadius: 4 }} className="blueBtnRegister">
                                    Select
                                </button>
                            </>
                    }
                </div>
            </Modal.Body>

        </Modal>
    );
}



export default function AdminMilestone() {
    const history = useHistory();
    let id = useParams()
    const [modalShow, setModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [editMilestoneModalShow, setEditMilestoneModalShow] = useState(false);
    const [deleteMilestoneModaShow, setDeleteMilestoneModaShow] = useState(false);
    const [skillObj, setSkillObj] = useState({});
    const [mileStonesArr, setMileStonesArr] = useState([]);
    const [MilestoneIdforDeletion, setMilestoneIdforDeletion] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleBackNavigation = () => {
        let path = `/Admin-Dashboard`;
        history.push(path);
    }

    const handleRedirect = (MilestoneId) => {
        let path = `/Admin-Activity/${MilestoneId}`;
        history.push(path);
    }


    const getSkill = async () => {
        try {
            setLoading(true)
            let res = await getSkillById(id.id)
            if (res.status == 200 || res.status == 304 || res.status == 202) {
                setSkillObj(res.data.data[0])
                console.log(res.data.data[0].milestones.sort(function (a, b) {
                    return parseFloat(a.age_group_id) - parseFloat(b.age_group_id);
                }))
                setMileStonesArr(res.data.data[0].milestones.sort(function (a, b) {
                    return parseFloat(a.price) - parseFloat(b.price);
                }))
                setLoading(false)
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.log(err)
                alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });

            }
        }

    }






    const handleSkillEdit = async (obj) => {
        try {
            setLoading(true)
            let dataObject = {
                s_no: obj.s_no,
                name: obj.skillName,
                color: obj.colorValue,
                border_color: obj.border,
                order: obj.order,
                status: 1,
            }




            let res = await editSkills(skillObj.id, dataObject);

            if (res.status == 200 || res.status == 304) {
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.message}`,
                });
                getSkill();
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
                setLoading(false)
            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
                setLoading(false)
            }
        }
    }


    const handleMilestoneAdd = async (obj) => {
        try {
            setLoading(true)
            console.log(obj, "semi final milestone obj")
            let res = await addMilestone(obj)

            if (res.status == 200 || res.status == 304) {
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.message}`,
                });
                getSkill();
            }

        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.error(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });
            }
        }
    }



    const handleMilestoneDelete = async () => {

        try {
            let res = await deleteMilestones(MilestoneIdforDeletion)
            if (res.status === 200 || res.status === 304 || res.status === 202) {
                Swal.fire({
                    title: 'Success',
                    type: 'success',
                    text: `${res?.data?.message}`,
                });
                getSkill()
            }
        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.log(err)
                alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });

            }
        }

    }



    useEffect(() => {
        getSkill()
    }, [])

    return (
        <div>
            <div className={`${styles.header}`}>
                <div onClick={() => handleBackNavigation()} className={styles.btn}>
                    <img src={back} alt="" />
                </div>


                <div className={`${styles.headerHeading}`}>
                    Milestones for {skillObj.name}
                </div>
                <div className={styles.btn} onClick={() => setEditModalShow(true)}>
                    <img src={edit} alt="" />
                </div>
            </div>
            <div className={`${styles.containerdiv} container`}>

                <div className="row d-flex justify-content-center">
                    {
                        loading ?
                            <div className="row mt-5 justify-content-center d-flex">
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Loader
                                        visible={true}
                                        type="TailSpin"
                                        color="#FF6F96"
                                        height={100}
                                        width={100}
                                    // timeout={18000} //3 secs
                                    />
                                </div>
                            </div>
                            :
                            <>
                                <div className={`${styles.milestoneCard} col-lg-7 col-11`} >
                                    <div className={styles.milestonesHeading}>
                                        {skillObj.name}
                                    </div>
                                    {
                                        mileStonesArr.length ?
                                            mileStonesArr.map((el, index) => {
                                                return (
                                                    <div key={el.id} className={styles.milestonesCardItem} onClick={() => handleRedirect(el.id)}>
                                                        <div className={styles.milestonesCardItemTxt}><b> Month {el.age_group_id - 1}</b> - {index + 1}.  {el?.name}  </div>
                                                        <div>
                                                            <img onClick={(e) => { e.stopPropagation(); setDeleteMilestoneModaShow(true); setMilestoneIdforDeletion(el.id) }} style={{ height: 25, width: 25, marginRight: 15 }} className={styles.milestonesCardItemIcon} src={deleteIcon} alt="" />
                                                            <img className={styles.milestonesCardItemIcon} src={chevronRightBlack} alt="" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className={styles.milestonesCardItem}>
                                                <div className={styles.milestonesCardItemTxt}>No Milestones found for this skill</div>
                                            </div>
                                    }


                                </div>

                                <div className="col-12 d-flex justify-content-center mt-5">

                                    <button onClick={() => { setModalShow(true) }} className={styles.addBtn}>
                                        Add Milestone
                                    </button>
                                </div>
                            </>
                    }
                </div>

            </div>

            <MyVerticallyCenteredModal
                handleadd={handleMilestoneAdd}
                show={modalShow}
                skillObj={skillObj}
                onHide={() => setModalShow(false)}
            />


            <MyVerticallyCenteredModal2
                handleEdit={handleSkillEdit}
                show={editModalShow}
                skillObj={skillObj}
                onHide={() => setEditModalShow(false)}
            />


            <MyVerticallyCenteredModal3
                handleadd={handleMilestoneAdd}
                show={editMilestoneModalShow}
                onHide={() => setEditMilestoneModalShow(false)}
            />



            <MyVerticallyCenteredModal4
                handledelete={handleMilestoneDelete}
                show={deleteMilestoneModaShow}
                onHide={() => setDeleteMilestoneModaShow(false)}
            />







            <Adminhomepagefooter />
        </div>
    )
}
