import React from 'react'
import { Link, useHistory } from "react-router-dom";

export default function NotFound() {
    const history = useHistory()

    return (
        <>
            <div className="container-fluid">
                <div className="row d-flex justify-content-center">
                    <div className="col-4 " style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "10%" }}>


                        <div style={{ color: "#FF6F96", fontSize: 105, fontFamily: 'Open Sans' }}>
                            404
                        </div>
                        <div style={{ color: "#000000", fontSize: 75, fontFamily: 'Open Sans' }}>Not Found</div>
                        <button onClick={() => history.push("/")} style={{ backgroundColor: "#FF6F96", color: "white", outline: "none", border: "none", fontSize: 35, marginTop: 25, paddingLeft: "17%", paddingRight: "17%", borderRadius: 5, fontFamily: 'Open Sans' }}>Go to Home</button>
                    </div>
                </div>
            </div>
        </>
    )
}
