import React, { useEffect, useState } from 'react'
import styles from './CSMFooter.module.css'
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import userUnselected from "../../../assets/img/userUnselected.png";
import userSelected from "../../../assets/img/userSelected.png";
import childProfileActive from "../../../assets/img/childProfileActive.png";
import childProfileInActive from "../../../assets/img/childProfileInActive.png";

export default function CSMFooter() {
    const history = useHistory();

    const location = useLocation();


    const [activeRoute, setActiveRoute] = useState(0);

    const handleRouteChange = (val) => {
        setActiveRoute(val)
        if (val === 0) {
            history.push("/CSM-Manage-Children");
        }
        if (val === 1) {
            history.push("/CSM-Profile");
        }
    }


    const checkLocation = () => {
        if (location.pathname.includes('CSM-Manage-Children')) {
            setActiveRoute(0)
        }

        else if (location.pathname.includes('CSM-Profile') || location.pathname.includes('CSM-Customers') || location.pathname.includes('Add-CSM') || location.pathname.includes('Admin-CSM-child-details')) {
            setActiveRoute(1)
            console.log(location.pathname)
        }
    }



    useEffect(() => {
        checkLocation()
    }, [activeRoute])

    return (
        <>
            {/* //////// footer ////////// */}



            <div className={styles.footer}>

                <div className={styles.circleContainer} onClick={() => { setActiveRoute(0); handleRouteChange(0) }}>
                    <div className={styles.circle}>
                        <div className={`${activeRoute === 0 ? styles.innerCircleActive : styles.innerCircleInActive}`}>
                            {
                                activeRoute === 0 ?

                                    <img src={childProfileActive} alt="" />

                                    :

                                    <img src={childProfileInActive} alt="" />
                            }

                        </div>
                    </div>
                    <div className={`${activeRoute === 0 ? styles.activeItem : styles.inactiveItem} ${styles.MenuItem1Name}`}>Child Profile</div>
                </div>

                <div className={styles.circleContainer1} onClick={() => { handleRouteChange(1); setActiveRoute(1) }}>
                    <div className={styles.circle}>
                        <div className={`${activeRoute === 1 ? styles.innerCircleActive : styles.innerCircleInActive}`}>
                            {
                                activeRoute === 1 ?

                                    <img src={userSelected} alt="" />

                                    :

                                    <img src={userUnselected} alt="" />
                            }
                        </div>
                    </div>
                    <div className={`${activeRoute === 1 ? styles.activeItem : styles.inactiveItem} ${styles.MenuItem1Name}`}>My Profile</div>
                </div>


            </div>
        </>
    )
}
