import React, { useRef, useEffect, useState, useContext } from 'react'
import { AuthContext, jwtContext } from '../../App';

import { useHistory, useLocation, useParams } from 'react-router'
import queryString from 'query-string';
import Swal from 'sweetalert2';
import { orderSuccessFull } from '../../Services/User';
import styles from './payments.module.css';
import helicopterLeft from "../../assets/img/helicopter_cloud_modal_left.png";
import cloudRight from "../../assets/img/cloud_modal_right.png";
import greenCheck from "../../assets/img/greenCheck.png";
import cancel from "../../assets/img/cancel.png";

export default function PaymentError() {
    let params = useParams()
    let location = useLocation()
    let history = useHistory()
    const [orderDetails, setOrderDetails] = useState({});
    const [locationvalueData, setLocationvalueData] = useState("");
    // console.log("params", params)
    // console.log("params", location)



    const [isAuthorized, setIsAuthorized] = useContext(AuthContext);
    const [decodedObj, setDecodedObj] = useContext(jwtContext);

    const handleNavigationToDashboard = () => {
        setIsAuthorized(false)
        localStorage.removeItem("role")
        localStorage.removeItem("FAVCY_AUTH_TOKEN")
        localStorage.removeItem("ADMIN_AUTH_TOKEN")
        let path = `/`;
        history.push(path);
        // history.push("/Dashboard")
    }


    const handleOrderSuccess = async () => {
        try {
            let locationValue = queryString.parse(location.search)
            console.log(locationValue)
            if (locationValue) {
                setLocationvalueData(locationValue)
            }
            // let obj = {locationValue,}
            let formData = new FormData()



            formData.append("favcy_transaction_id", locationValue.favcy_transaction_id);
            formData.append("order_id", locationValue.order_id);
            formData.append("status", "success");
            formData.append("payment_method", "razorpay");

            let res = await orderSuccessFull(locationValue.order_id, formData)
            if (res.data.data) {
                setOrderDetails(res.data.data)
                console.log(res.data.data)
            }

        }
        catch (err) {
            if (err?.response?.data?.message) {
                console.log(err?.response?.data?.message);
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.response?.data?.message}`,
                });
            }
            else {
                console.log(err)
                // alert()
                Swal.fire({
                    title: 'Error',
                    type: 'error',
                    text: `${err?.message}`,
                });

            }
        }

    }



    useEffect(() => {

        handleOrderSuccess()


    }, [])

    return (
        <div className={`${styles.bg} container-fluid`}>
            <img src={cloudRight} alt="helicopter" className={styles.cloudImage} />
            <img src={cloudRight} alt="helicopter" className={styles.cloudImagebottomRight} />
            <img src={helicopterLeft} alt="helicopter" className={styles.helicopterLeftImage} />

            <div className={`${styles.container}`}>
                <div className={`${styles.heading}`}>
                    Payment Unsuccessfull
                </div>
                <img src={cancel} alt="check" className={styles.checkImage} />

                <div className={`${styles.flexRowJustifyBetween} mt-5`}>
                    <div className={`${styles.keyName}`}>
                        Transaction Id
                    </div>
                    <div className={`${styles.data}`}>
                        {locationvalueData.favcy_transaction_id}
                    </div>
                </div>
                <div className={`${styles.flexRowJustifyBetween}`}>
                    <div className={`${styles.keyName}`}>
                        Order Id
                    </div>
                    <div className={`${styles.data}`}>
                        {locationvalueData.order_id}
                    </div>
                </div>
                <div className={`${styles.flexRowJustifyBetween}`}>
                    <div className={`${styles.keyName}`}>
                        Discount
                    </div>
                    <div className={`${styles.data}`}>
                        {orderDetails.discount}
                    </div>
                </div>
                <div className={`${styles.flexRowJustifyBetween}`}>
                    <div className={`${styles.keyName}`}>
                        Sub Total
                    </div>
                    <div className={`${styles.data}`}>
                        ₹ {orderDetails.sub_total}
                    </div>
                </div>
                <div className={`${styles.flexRowJustifyBetween}`}>
                    <div className={`${styles.keyName}`}>
                        Total
                    </div>
                    <div className={`${styles.data}`}>
                        ₹ {orderDetails.total}
                    </div>
                </div>

                <button onClick={() => handleNavigationToDashboard()} className={`${styles.Bluebtn}`}>
                    Go to Dashboard
                </button>


            </div>
        </div >
    )
}
